import React from 'react'
import cx from 'classnames'

import s from './Talk.module.css'
import gameStyles from '../../game.module.css'

export default function Talk({
  whoImgSrc,
  speech,
  className,
}: {
  whoImgSrc: string
  speech: string
  className?: string
}) {
  return (
    <div className={cx('flex items-center', className)}>
      <img src={whoImgSrc} className={cx('w-12 mr-2', s.objectInLeftCorner)} alt="Speaker" />
      <p className={cx(gameStyles.regularTextNormalized)}>"{speech}"</p>
    </div>
  )
}
