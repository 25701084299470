import React, { useContext } from 'react'
import cx from 'classnames'

import s from './Boss.module.css'
import { GameFieldSettingsContext } from '../GameField/GameField'

export default function Boss({
  img = '',
  imgClassName = '',
  className = '',
  style = undefined,
  imgStyle = undefined,
  badGuySize = 1,
}) {
  const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }
  return (
    <div
      className={cx(
        s.badGuy,
        s.boss,
        `flex justify-center items-center w-1/5 h-1/5 flip`,
        className,
        s[`badGuySize-${badGuySize}`],
        !isGridHidden && s.targetHighlight
      )}
      style={style}
    >
      <img src={img} alt="" className={`${imgClassName}`} style={imgStyle} />
    </div>
  )
}
