import React from 'react'
import cx from 'classnames'

import s from '../game.module.css'
import Hero from './Hero'

const knightPng = '/gamesAssets/flexbox/knight.png'
const roguePng = '/gamesAssets/flexbox/rogue.png'
const magePng = '/gamesAssets/flexbox/mage.png'

import HeartSVG from 'public/images/svg/heart.svg'

export default function Stats({ heroHealth = '', mageHealth = '', rogueHealth = '' }) {
  return (
    <>
      <p className={cx(s.regularText)}>Heroes' health:</p>
      <div className="flex items-center justify-start w-full mb-6 -mt-6">
        <div className="flex items-center justify-center flex-col mr-4">
          <Hero img={knightPng} className="w-12" />
          <div className="flex items-center">
            <p className="text-red-600 mr-2">{heroHealth}</p>
            <HeartSVG className="w-4 h-4" />
          </div>
        </div>
        {mageHealth && (
          <div className="flex items-center justify-center flex-col mr-4">
            <Hero img={magePng} className="w-12" />
            <div className="flex items-center">
              <p className="text-red-600 mr-2">{mageHealth}</p>
              <HeartSVG className="w-4 h-4" />
            </div>
          </div>
        )}
        {rogueHealth && (
          <div className="flex items-center justify-center flex-col">
            <Hero img={roguePng} className="w-12" />
            <div className="flex items-center">
              <p className="text-red-600 mr-2">{rogueHealth}</p>
              <HeartSVG className="w-4 h-4" />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
