import React from 'react'

import Modal from 'components/games/Modal'

import s from './LevelCompletedModal.module.css'

const reyImg = '/gamesAssets/gridattack/rey.png'

function LevelCompletedModal({
  color,
  isOpen,
  handleClose,
  CompleteLevelModalDescription,
  goNextLevel,
  currentLevel,
}) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} viewOnSmallScreenAsOnLarge>
      <div className="bg-white px-4 pt-6 pb-4 sm:p-6 sm:pb-4">
        <div className="flex flex-col items-center">
          <div className="text-center mt-4">
            <h3 className="text-2xl font-medium text-gray-900" id="modal-headline">
              Level {currentLevel} completed!
            </h3>
            <p className="text-gray-500">
              {!!CompleteLevelModalDescription() && 'You just beat this bad guy'}
            </p>
            <div className="mt-2">
              <div className="text-base text-gray-500">{CompleteLevelModalDescription()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 pb-6 flex items-center justify-center">
        <span className="flex rounded-md shadow-sm w-auto">
          <button
            onClick={goNextLevel}
            type="button"
            className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${color}-600 text-base font-medium text-white shadow-sm hover:bg-${color}-500 focus:border-${color}-700 focus:shadow-outline-${color} transition ease-in-out duration-150`}
          >
            Next Level →
          </button>
        </span>
      </div>
    </Modal>
  )
}

export default React.memo(LevelCompletedModal)
