import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Inspector from '../components/Inspector'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/demon.gif'

const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#redLandBack', '#redLandChar'],
  ],
  styles: [
    [
      `grid-template-columns: repeat(4, 1fr);`,
      `grid-template-rows: repeat(4, 1fr);`,
      `gap: 15px;`,
      `grid-template-areas: "land land land ."
      "land land land ."
      "land land land ."
      ". . . .";`,
    ],
    [`grid-area: land;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#redLand']],
  styles: [
    [
      `grid-template-columns: repeat(4, 1fr);`,
      `grid-template-rows: repeat(4, 1fr);`,
      `gap: 15px;`,
      `grid-template-areas: "land land land ."
      "land land land ."
      "land land land ."
      ". . . .";`,
    ],
    [`grid-area: land;`],
  ],
})

const HTML = `<div id="field">
  <div id="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 30,
  level: 30,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#redLand']],
      styles: [
        [
          `grid-template-columns: repeat(4, 1fr);`,
          `grid-template-rows: repeat(4, 1fr);`,
          `gap: 15px;`,
          `/* type here */`,
        ],
        [`/* type here */`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr', '1fr'],
                rows: ['1fr', '1fr', '1fr', '1fr'],
                columnGap: '15px',
                rowGap: '15px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer id="redLand" />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand id="redLandBack" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={evilGuyPath} id="redLandChar" />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        {mode === 'hard' && (
          <div className={s.regularText}>
            Congratulations on defeating your second boss! Now let's show these monsters how well
            you know <Property property="grid-template-areas" /> (if you didn't use it already).
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              Congratulations on defeating your second boss! Now let's show these monsters how well
              you know other properties of CSS Grid.
            </div>
            <div className={s.regularText}>
              Before this level, we created grid areas by placing items using line-based placement.
              Like this:
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100px 100px;
}

#item1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

#item2 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}`}
            </Highlight>
            <div
              className="grid mb-8"
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: '100px 100px',
              }}
            >
              <GridChildExample
                color="blue"
                className="bg-opacity-75"
                style={{ gridColumn: '1 / 3', gridRow: '1 / 3' }}
              >
                #item1
              </GridChildExample>
              <GridChildExample
                color="pink"
                className="bg-opacity-75"
                style={{ gridColumn: '3 / 4', gridRow: '1 / 3' }}
              >
                #item2
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              But there is another way of defining areas, by using named grid areas.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: 100px 100px;
  grid-template-areas: "a a b"
    "a a b";
}

#item1 {
  grid-area: a;
}

#item2 {
  grid-area: b;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className="grid mb-8"
                  style={{
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: '100px 100px',
                    gridTemplateAreas: `"a a b" "a a b"`,
                  }}
                >
                  <GridChildExample
                    color="blue"
                    className="bg-opacity-75"
                    style={{ gridArea: 'a' }}
                  >
                    #item1
                  </GridChildExample>
                  <GridChildExample
                    color="pink"
                    className="bg-opacity-75"
                    style={{ gridArea: 'b' }}
                  >
                    #item2
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              As you can see it is possible with two new properties{' '}
              <Property property="grid-template-areas" /> and <Property property="grid-area" />.
            </div>
            <div className={s.regularText}>
              <Property property="grid-template-areas" /> defines a grid template by referencing the
              names of the grid areas which are specified with the <Property property="grid-area" />{' '}
              property. Repeating the name of a grid area causes the content to span those cells.
              The syntax itself provides a visualization of the structure of the grid.
            </div>
            <div className={s.regularText}>Common values:</div>
            <div className={s.regularText}>
              <Property code="<grid-area-name>" /> – the name of a grid area specified with
              grid-area
            </div>
            <div className={s.regularText}>
              <Property code="." /> – a period signifies an empty grid cell
            </div>
            <div className={s.regularText}>
              <Property code="none" /> – no grid areas are defined
            </div>
            <div className={s.regularText}>
              And <Property property="grid-area" /> is a property that gives an item a name so that
              it can be referenced by a template created with the grid-template-areas property.
              Alternatively, this property can be used as an even shorter shorthand for
              <Property property="grid-row-start" /> + <Property property="grid-column-start" /> +{' '}
              <Property property="grid-row-end" /> + <Property property="grid-column-end" />.
            </div>
            <div className={s.regularText}>Common values:</div>
            <div className={s.regularText}>
              <Property code="<name>" /> – a name of your choosing
            </div>
            <div className={s.regularText}>
              <Property code="<row-start> / <column-start> / <row-end> / <column-end>" /> – can be
              numbers or named lines
            </div>
            <div className={s.regularText}>Alright, let's look at some real-world examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 50px);
  grid-template-areas: "header header header"
    "nav content content"
    "nav content content"
    "footer footer footer";
}

#header {
  grid-area: header;
}

#nav {
  grid-area: nav;
}

#content {
  grid-area: content;
}

#footer {
  grid-area: footer;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className="grid mb-8"
                  style={{
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'repeat(4, 50px)',
                    gridTemplateAreas: `"header header header"
                "nav content content"
                "nav content content"
                "footer footer footer"`,
                  }}
                >
                  <GridChildExample
                    color="blue"
                    className="bg-opacity-75"
                    style={{ gridArea: 'header' }}
                  >
                    #header
                  </GridChildExample>
                  <GridChildExample
                    color="pink"
                    className="bg-opacity-75"
                    style={{ gridArea: 'nav' }}
                  >
                    #nav
                  </GridChildExample>
                  <GridChildExample
                    color="red"
                    className="bg-opacity-75"
                    style={{ gridArea: 'content' }}
                  >
                    #content
                  </GridChildExample>
                  <GridChildExample
                    color="purple"
                    className="bg-opacity-75"
                    style={{ gridArea: 'footer' }}
                  >
                    #footer
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 50px);
}

#item {
  grid-area: 1 / 1 / 5 / 3;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className="grid mb-8"
                  style={{
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'repeat(4, 50px)',
                  }}
                >
                  <GridChildExample
                    color="blue"
                    className="bg-opacity-75"
                    style={{ gridArea: '1 / 1 / 5 / 3' }}
                  >
                    #item
                  </GridChildExample>
                  <GridChildExample color="pink" className="bg-opacity-75">
                    2
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              ❗️ Note: Grid areas must be rectangular in nature; it is not possible to create, for
              example, a T- or L-shaped grid area.
            </div>
            <div className={s.regularText}>
              Alright, now is your turn to combine <Property property="grid-template-areas" /> and{' '}
              <Property property="grid-area" /> to defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
