import React from 'react'
import cx from 'classnames'

import SpinnerSVG from 'public/images/svg/spinner.svg'

export default function Spinner({
  className,
  color = 'text-white',
}: {
  className?: string
  color?: string
}) {
  return <SpinnerSVG className={cx('spinner animate-spin h-5 w-5', color, className)} />
}
