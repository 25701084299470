import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/skelet-king2.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template: 100px 1fr 100px / 100px 1fr 100px;`, `gap: 20px;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template: 100px 1fr 100px / 100px 1fr 100px;`, `gap: 20px;`]],
})

const HTML = `<div id="field">
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 43,
  level: 43,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`gap: 20px;`, `/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['100px', '1fr', '100px'],
                rows: ['100px', '1fr', '100px'],
                columnGap: '20px',
                rowGap: '20px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          Well done! Soon we will meet the Evilins. They are two evil twin brothers who are very
          loyal to their lord Valcorian. To defeat them you need to know how to use{' '}
          <Property property="grid-template" />.
        </div>
        {mode === 'hard' && (
          <div className={s.regularText}>
            You are a master of css grid, so show this monster what you know!
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              <Property property="grid-template" /> is a shorthand for setting{' '}
              <Property property="grid-template-rows" />,{' '}
              <Property property="grid-template-columns" />, and{' '}
              <Property property="grid-template-areas" /> in a single declaration.
            </div>
            <div className={s.regularText}>Syntax:</div>
            <Highlight language="css" className="mb-8">
              {`grid-template: <grid-template-rows> / <grid-template-columns>;`}
            </Highlight>
            <Highlight language="css" className="mb-8">
              {`grid-template: <grid-template-areas> <grid-template-rows> / <grid-template-columns>;`}
            </Highlight>
            <div className={s.regularText}>Here are some examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid-template: 100px 150px / repeat(3, 1fr);
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplate: '100px 150px / repeat(3, 1fr)',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid-template: "a a b" 50px
                 "a a b" 100px  / 1fr 2fr 1fr;
}

#item1 {
  grid-area: a;
}

#item2 {
  grid-area: b;
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplate: `"a a b" 50px
                "a a b" 100px  / 1fr 2fr 1fr`,
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75" style={{ gridArea: 'a' }}>
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75" style={{ gridArea: 'b' }}>
                #item2
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              Now, try to use <Property property="grid-template" /> to defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
