import React from 'react'
import Link from 'next/link'
import cx from 'classnames'

import { useUser } from 'lib/hooks'

import LogoSVG from 'public/images/svg/logo.svg'
import s from './header.module.css'

function HeaderWithUser({ onlyLogo }) {
  const { user, isLoaded } = useUser()

  return (
    <header
      className={cx(s.header, 'flex items-center justify-between h-20 px-4 md:px-8 bg-black')}
    >
      <div className="text-white relative">
        <Link href={`/`}>
          <a>
            <LogoSVG className="w-11 h-11 cursor-pointer" />
            {/* <span className="absolute -top-1 -right-6 text-yellow-500 text-xs">alpha</span> */}
          </a>
        </Link>
      </div>
      {!onlyLogo && (
        <nav className="sm:space-x-8 space-x-4">
          <Link href={`/games`}>
            <a className="font-medium text-gray-200  hover:text-yellow-500">Games</a>
          </Link>
          {user?.profile ? (
            <Link href={`/profile`}>
              <a className="font-medium text-gray-200 hover:text-yellow-500">
                {user.profile.userName}
              </a>
            </Link>
          ) : isLoaded && !user ? (
            <>
              <Link href="/login">
                <a className="font-medium text-gray-200 hover:text-yellow-500">Log In</a>
              </Link>
              <Link href="/signup">
                <a className="font-medium text-gray-200  hover:text-yellow-500">Sign up</a>
              </Link>
            </>
          ) : null}
        </nav>
      )}
    </header>
  )
}

function Header({ withUser = true, onlyLogo = false }: { withUser?: boolean; onlyLogo?: boolean }) {
  if (withUser) {
    return <HeaderWithUser onlyLogo={onlyLogo} />
  }

  return (
    <header
      className={cx(s.header, 'flex items-center justify-between h-20 px-4 md:px-8 bg-black')}
    >
      <div className="text-white relative">
        <Link href={`/`}>
          <a>
            <LogoSVG className="w-11 h-11 cursor-pointer" />
            {/* <span className="absolute -top-1 -right-6 text-yellow-500 text-xs">alpha</span> */}
          </a>
        </Link>
      </div>
      <nav className="sm:space-x-8 space-x-4">
        <Link href={`/games`}>
          <a className="font-medium text-gray-200  hover:text-yellow-500">Games</a>
        </Link>
        <Link href="/login">
          <a className="font-medium text-gray-200  hover:text-yellow-500">Login</a>
        </Link>
        <Link href="/signup">
          <a className="font-medium text-gray-200  hover:text-yellow-500">Sign up</a>
        </Link>
      </nav>
    </header>
  )
}

export default React.memo(Header)
