import React, { useState } from 'react'

import Modal from './Modal'
import s from './CatchEmailModal.module.css'
import axios from 'axios'

export default function CatchEmailModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean
  handleClose: () => void
}): JSX.Element {
  const [email, setEmail] = useState('')

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const email = e.target.email.value

    axios.post('/api/catchuser', {
      email,
    })

    handleClose()
  }

  return (
    <Modal
      widthClassName={s.modal}
      isOpen={isOpen}
      handleClose={handleClose}
      // handleEnterPress={() => handleChoose({ mode })}
    >
      <div className="bg-white px-6 pt-6 pb-4 sm:p-6 sm:pb-4">
        <div className="flex flex-col">
          <p className="text-2xl mt-4 mb-1 text-center">
            Want to know when we will release a New Game?
          </p>
          <p className="mb-4 text-gray-500 text-center">
            Add your email below and we will notify you.
          </p>
        </div>
      </div>
      <div className="px-6 pb-6 flex items-center justify-center">
        <form onSubmit={handleFormSubmit} className="w-full flex flex-col items-center">
          <input
            className="w-full px-2 py-2 mb-2 border rounded-md"
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            required
            placeholder="you@email.com"
          />
          <button
            type="submit"
            className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-purple-600 text-base font-medium text-white shadow-sm hover:bg-purple-500  focus:border-purple-700 focus:shadow-outline-yellow transition ease-in-out duration-150`}
          >
            Notify Me!
          </button>
        </form>
      </div>
    </Modal>
  )
}
