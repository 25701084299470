import React from 'react'
import cx from 'classnames'

const Button = React.forwardRef(
  (
    {
      onClick,
      children,
      tag = 'button',
      btnColor = 'bg-black',
      btnHoverColor = 'bg-black',
      textColor = 'text-white',
      disabled = false,
      href,
      className,
    }: {
      onClick?: () => void
      children: React.ReactNode
      tag?: 'button' | 'a'
      textColor?: string
      btnColor?: string
      btnHoverColor?: string
      href?: string
      className?: string

      disabled?: boolean
    },
    ref
  ) => {
    let props: any = {}

    if (onClick) {
      props = { ...props, onClick }
    }

    return React.createElement(
      tag,
      {
        // forwardRef: ref || forwardRef,
        // ref: ref || forwardRef,
        href,
        ref,
        disabled,
        className: cx(
          'flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md',
          disabled ? 'disabled:opacity-50 disabled:cursor-default' : 'cursor-pointer',
          btnColor,
          `hover:${btnHoverColor}`,
          textColor,
          className
        ),

        ...props,
      },
      [children]
    )
  }
)

export default Button
