import React from 'react'

import Header from 'components/Header'
import Footer from 'components/Footer'

interface LayoutProp {
  children: JSX.Element[] | JSX.Element | null
  onlyLogo?: boolean
  withoutFooter?: boolean
  withUser?: boolean
}

function Layout({
  children,
  onlyLogo = false,
  withoutFooter = false,
  withUser = true,
}: LayoutProp) {
  return (
    <div className="min-h-screen flex-col h-full flex">
      <Header onlyLogo={onlyLogo} withUser={withUser} />
      {children}
      {!withoutFooter && <Footer />}
    </div>
  )
}

export default React.memo(Layout)
