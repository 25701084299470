import React, { useContext } from 'react'
import cx from 'classnames'

import s from './HumanLandAnswer.module.css'
// import { GameFieldSettingsContext } from '../GameField/GameField'

const HumanLandAnswer = React.forwardRef(
  (
    { id, children, className }: { id?: string; children?: React.ReactNod; className?: string },
    ref
  ) => {
    // const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }
    return (
      <div
        ref={ref}
        id={id}
        className={cx(
          s.land,
          className
          // !isGridHidden && s.targetHighlight
        )}
      >
        {children}
      </div>
    )
  }
)

export default HumanLandAnswer
