import { RevalidateOptionInterface, revalidateType } from 'swr/dist/types'

import { USER_GAME_STATS } from 'lib/constants'

import { FLEX_BOX_GAME } from 'games/flexbox/game-config'
import { GRID_ATTACK_GAME } from 'games/gridattack/game-config'
import { REGEX_GAME } from 'games/elonregex/game-config'
import { User } from 'components/AuthProvider'
import React from 'react'

export const defaultSwrOptions = {
  onErrorRetry: (revalidate: revalidateType, revalidateOpts: RevalidateOptionInterface) => {
    // Never retry on 401
    // console.log(error.response)
    // if (error?.response?.status === 401) return

    // Only retry up to 10 times.
    if (revalidateOpts?.retryCount >= 3) return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount: revalidateOpts.retryCount + 1 }), 5000)
  },
}

export type GAME_NAME_IDS = 'flexbox' | 'gridattack'

export type USER_GAME_STATS = {
  maxPlayedLevel: number
  mode: string
  gameNameId: GAME_NAME_IDS
}

export type GAME_LEVEL = {
  id: number
  level: number
  checkUserAnswer: ({
    answers,
    userAnswer,
  }: {
    answers: RegExp[] | RegExp[][]
    userAnswer: string
  }) => boolean
  descriptionBlock: React.ReactNode
  answers: RegExp[] | RegExp[][]
  editor: {
    codePre: string
    codePost: string
    lines: number
    answerLines: number
  }
  board: ({ answer }: { answer: any }) => JSX.Element
  completeLevelModalDescriptionBlock: () => JSX.Element
}

export type GAME_TYPE = {
  gameNameId: GAME_NAME_IDS
  name: string
  color: string
  seoDescription: string
  seoTitle: string
  seoPlayDescription: string
  seoPlayTitle: string
  url: string
  description: string
  landingImageSrc: string
  tags: string[]
  transferUnauthUserDataToAuthUser(user: User): Promise<{ [x: string]: PromiseSettledResult<any> }>
  useGameStats(): {
    userGameStats: USER_GAME_STATS[] | []
    loading: boolean
    isLoaded: boolean
  }
  updateGameLevel({
    level,
    mode,
    user,
  }: {
    level: number
    mode: string
    user: User
  }): Promise<number>
  levels: GAME_LEVEL[]
}

export type GRID_ATTACK_GAME_LEVEL = {
  id: number
  level: number
  checkUserAnswer: ({ answer }: { answer: string }) => boolean
  answers?: RegExp[][]
  editor: {
    nextGenCode: string
  }
  CompleteLevelModalDescription: () => React.ReactNode
  Description: React.ReactNode
  GameLayout: () => JSX.Element
}

export type GRID_ATTACK_GAME_TYPE = {
  showPaywallAfterLevel: number
  gameNameId: GAME_NAME_IDS
  name: string
  color: string
  seoDescription: string
  seoTitle: string
  seoPlayDescription: string
  seoPlayTitle: string
  url: string
  description: string
  landingImageSrc: string
  tags: string[]
  transferUnauthUserDataToAuthUser(user: User): Promise<{ [x: string]: PromiseSettledResult<any> }>
  useGameStats(): {
    userGameStats: USER_GAME_STATS[] | []
    loading: boolean
    isLoaded: boolean
  }
  updateGameLevel({
    level,
    mode,
    user,
  }: {
    level: number
    mode: string
    user: User
  }): Promise<number>
  levels: GRID_ATTACK_GAME_LEVEL[]
}

export const GAMES = [FLEX_BOX_GAME, GRID_ATTACK_GAME, REGEX_GAME]
