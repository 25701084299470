import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/mage.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[2].join(',')} {
  ${styles[2].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#greenLandBack', '#greenLandChar'],
    ['#redLandBack', '#redLandChar'],
  ],
  styles: [
    [
      `grid-template-columns: repeat(3, 1fr);`,
      `grid-template-rows: repeat(3, 1fr);`,
      `gap: 15px 10px;`,
      `grid-template-areas: "greenLand greenLand ."
      "greenLand greenLand ."
      "redLand redLand redLand";`,
    ],
    [`grid-area: greenLand;`],
    [`grid-area: redLand;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#greenLand'], ['#redLand']],
  styles: [
    [
      `grid-template-columns: repeat(3, 1fr);`,
      `grid-template-rows: repeat(3, 1fr);`,
      `gap: 15px 10px;`,
      `grid-template-areas: "greenLand greenLand ."
      "greenLand greenLand ."
      "redLand redLand redLand";`,
    ],
    [`grid-area: greenLand;`],
    [`grid-area: redLand;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div id="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 32,
  level: 32,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#greenLand'], ['#redLand']],
      styles: [[`/* type here */`], [`/* type here */`], [`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    const humanAnswerStyle = { gridArea: 'greenLand' }
    const evilAnswerStyle = { gridArea: 'redLand' }

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr'],
                rows: ['1fr', '1fr', '1fr'],
                columnGap: '10px',
                rowGap: '15px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand" />
                  <EvilLandAnswer id="redLand" />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand id="redLandBack" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} id="greenLandChar" />
                  <LandCharacters charactersImgPaths={evilGuyPath} id="redLandChar" />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>How about this one?</div>
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
