import React, { useContext, useRef, useEffect } from 'react'
import cx from 'classnames'

import s from './Hero.module.css'
import { GameFieldSettingsContext } from '../GameField/GameField'

export default function Hero({
  img = '',
  className = s.hero,
  style = '',
  id = undefined,
  additionalClassName = '',
}) {
  const heroRef = useRef(null)
  const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }

  useEffect(() => {
    if (style) {
      heroRef?.current?.setAttribute('style', style.trim())
    }
  }, [style])

  return (
    <div
      className={cx(
        `flex justify-center items-center`,
        className,
        additionalClassName,
        !isGridHidden && s.heroHighlight
      )}
      ref={heroRef}
      id={id}
    >
      <img src={img} alt="" className={s.objectInLeftCorner} />
    </div>
  )
}
