import React, { useContext } from 'react'
import cx from 'classnames'

import s from './Land.module.css'

export default function Land({
  type = 'rightAnswer',
  color,
  style,
  id,
  children,
  className,
}: {
  type: 'userAnswer' | 'rightAnswer'
  color: 'red' | 'blue' | 'green'
  style?: React.CSSProperties
  id?: string
  className?: string
  children?: React.ReactNode
}) {
  return (
    <div id={id} style={style} className={cx(s.land, s[type], s[color], className)}>
      {type === 'rightAnswer' && <div></div>}
      {children}
    </div>
  )
}
