import React from 'react'
import cx from 'classnames'

export function GridChildExample({
  children,
  className,
  style,
  color = 'red',
  textColor = 'text-black',
}: {
  children: React.ReactNode
  className?: string
  color?: string
  textColor?: string
  style?: React.CSSProperties
}) {
  return (
    <div
      className={cx(
        `flex justify-center items-center ${
          color === 'white' ? 'bg-white' : `bg-${color}-500`
        } ${textColor}`,
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export function checkUserAnswer({
  answers,
  userAnswer,
}: {
  answers: RegExp[][]
  userAnswer: string
}): boolean {
  return (answers as RegExp[][]).some((possibleAnswersArray) => {
    return possibleAnswersArray.every((answer) => {
      return answer.test(userAnswer.toLowerCase().trim())
    })
  })
}

export function isUserAnswerCorrect({
  answer,
  userAnswer,
}: {
  answer: HTMLElement[] | HTMLCollection
  userAnswer: HTMLElement[] | HTMLCollection
}): boolean {
  const normalizedAnswer: HTMLElement[] = !answer.every
    ? Array.from(answer as HTMLCollection)
    : answer

  const normalizedUserAnswer: HTMLElement[] = !userAnswer.every
    ? Array.from(userAnswer as HTMLCollection)
    : userAnswer

  return normalizedAnswer.every((el, index) => {
    const { top, left, right, bottom } = normalizedUserAnswer[index].getBoundingClientRect()
    const {
      top: topCorrect,
      left: leftCorrect,
      right: rightCorrect,
      bottom: bottomCorrect,
    } = el.getBoundingClientRect()

    if (
      top === topCorrect &&
      left === leftCorrect &&
      right === rightCorrect &&
      bottom === bottomCorrect
    ) {
      return true
    }

    return false
  })
}

export const MONSTERS_COUNT = 30
