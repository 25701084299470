import React, { useContext, useState } from 'react'
import cx from 'classnames'

import s from './LevelDropdown.module.css'

import { GameContext } from '../../Game'
import { GAME_LEVEL } from 'lib/constants'
import { IoPlay } from 'react-icons/io5'

function Dropdown({ title, list, onChoose, level }) {
  const [listOpen, setOpen] = useState(false)
  return (
    <div className="flex items-center relative whitespace-nowrap">
      <button
        className={cx('transform rotate-90  cursor-pointer p-2 select-none', s.flexboxColor)}
        onClick={() => {
          if (level >= 2) {
            onChoose({ item: { level: level - 1 } })
          }
        }}
      >
        ▼
      </button>
      <div>
        <button
          className={cx(
            s.ddHeader,
            'flex select-none items-center py-1 px-3 rounded-md cursor-pointer text-white'
          )}
          onClick={() => {
            setOpen((bool) => !bool)
          }}
        >
          <div>{title}</div>
          <span className={cx(s.ddArrow, 'ml-1')}>
            {listOpen ? (
              <IoPlay size="14" className="transform -rotate-90" />
            ) : (
              <IoPlay size="14" className="transform rotate-90" />
            )}
          </span>
        </button>
        {listOpen && (
          <div className={cx(s.ddList, 'flex flex-col p-3 absolute top-0 left-0 rounded-md')}>
            {list.map((item, index) => (
              <div
                className={cx(
                  s.ddListItem,
                  'mb-2',
                  item.isUnlocked ? 'cursor-pointer' : s.ddListItemLocked
                )}
                key={index}
                onClick={() => {
                  if (item.isUnlocked) {
                    onChoose({ item })
                    setOpen(false)
                  }
                }}
              >
                {item.title} {level === item.level ? '(current)' : ''}{' '}
                {item.isUnlocked ? '' : '(locked)'}
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        className={cx(
          'transform rotate-90 text-red-600 cursor-pointer p-2 select-none',
          s.flexboxColor
        )}
        onClick={() => {
          if (level < list[list.length - 1].level) {
            onChoose({ item: { level: level + 1 } })
          }
        }}
      >
        ▲
      </button>
    </div>
  )
}

export default function LevelDropdown({ level, levels }: { level: number; levels: GAME_LEVEL[] }) {
  const { maxPlayedLevel, handleLevelChoose } = useContext(GameContext)

  return (
    <div className="flex items-center justify-between mb-6">
      <p className="sm:text-3xl text-2xl font-bold">Level {level}</p>
      <Dropdown
        onChoose={handleLevelChoose}
        title={`Level ${level} of ${levels.length}`}
        list={levels.map(({ level }) => {
          return {
            title: `Level ${level}`,
            level,
            isUnlocked: level <= maxPlayedLevel,
          }
        })}
        level={level}
      />
    </div>
  )
}
