import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Inspector from '../components/Inspector'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/shaman1.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#greenLandBack', '#greenLandChar'],
  ],
  styles: [
    [`grid-template-columns: repeat(4, 1fr);`, `grid-template-rows: repeat(2, 1fr);`],
    [`grid-column-start: 2;`, `grid-column-end: 4;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#greenLand']],
  styles: [
    [`grid-template-columns: repeat(4, 1fr);`, `grid-template-rows: repeat(2, 1fr);`],
    [`grid-column-start: 2;`, `grid-column-end: 4;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 20,
  level: 20,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#greenLand']],
      styles: [
        [`grid-template-columns: repeat(4, 1fr);`, `grid-template-rows: repeat(2, 1fr);`],
        [`/* type here */`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr', '1fr'],
                rows: ['1fr', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand" />
                  <EvilLandAnswer />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} id="greenLandChar" />
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        {mode === 'hard' && (
          <div className={s.regularText}>
            Great job! If you defeated the previous monster, this one won't be a problem for you!
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>Great job! Let's try to learn a new property.</div>
            <div className={s.regularText}>
              <Property property="grid-column-start" /> span exactly one column by default. And if
              we want to extend it across multiple columns we need to use{' '}
              <Property property="grid-column-end" /> property. It specifies a grid item’s end
              position within the grid column. And if we will combine both properties, the item will
              span across the provided number of grid tracks. All merged grid tracks will be called
              <strong>grid area</strong> (one or more grid cells that make up a rectangular area on
              the grid).
            </div>
            <div className={s.regularText}>Let's look at some examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100px;
  gap: 10px;
}

#item {
  grid-column-start: 3;
  grid-column-end: 5;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className="grid mb-8"
                  style={{
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridTemplateRows: '100px',
                    gap: '10px',
                  }}
                >
                  <GridChildExample color="purple" className="bg-opacity-75">
                    1
                  </GridChildExample>
                  <GridChildExample
                    color="blue"
                    className="bg-opacity-75"
                    style={{ gridColumnStart: 3, gridColumnEnd: 5 }}
                  >
                    #item
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              If there's no <Property property="grid-column-start" /> specified, the element starts
              before the set end column.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 100px;
  gap: 10px;
}

#item {
  grid-column-end: 5;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className="grid mb-8"
                  style={{
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridTemplateRows: '100px',
                    gap: '10px',
                  }}
                >
                  <GridChildExample color="purple" className="bg-opacity-75">
                    1
                  </GridChildExample>
                  <GridChildExample
                    color="blue"
                    className="bg-opacity-75"
                    style={{ gridColumnEnd: 5 }}
                  >
                    #item
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              Alright, try to combine <Property property="grid-column-start" /> and{' '}
              <Property property="grid-column-end" /> to defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
