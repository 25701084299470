import { debounce } from 'lodash'
import React, { useLayoutEffect, useMemo } from 'react'
import { useCallback } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'

import s from './Inspector.module.css'

function parseGridTemplate(templateStr, gap = '0px') {
  const gapSize = parseFloat(gap.replace('px', ''))

  // splits at and space that isn't between two [ ] brackets
  const parsedArray = templateStr.split(/\s(?![^[]*])/)
  let lines = []
  let currentPosition = 0

  // add in any missing names as ""
  // "200px [center-start] 300px [center-end] 400px" becomes:
  // "'' 200px [center-start] 300px [center-end] 400px ''"

  let allItems = []

  parsedArray.forEach((item, index) => {
    if (item.includes('px')) {
      // add a null name before it if missing
      if (!parsedArray[index - 1] || parsedArray[index - 1].includes('px')) {
        allItems.push('')
      }
    }

    allItems.push(item)
  })

  // handle last item
  if (parsedArray[parsedArray.length - 1].includes('px')) {
    allItems.push('')
  }

  allItems.forEach((item, index) => {
    if (item.includes('px')) {
      const trackSize = parseFloat(item.replace('px', ''))

      currentPosition += trackSize
    } else {
      let newLine = {
        start: currentPosition - 1,
        end: currentPosition + 1,
        name: getName(item),
      }
      // add gaps on just the inner lines
      // and count it for positioning
      if (index !== 0 && index !== allItems.length - 1 && gapSize !== 0) {
        newLine.gap = gapSize
        currentPosition += gapSize
      }

      lines.push(newLine)
    }
  })

  return lines
}

function getName(item) {
  return item.includes('[') ? item.match(/\[(.*)\]/)[1].trim() : null
}

const extendLines = 15

function getMainStyleByColumnAndRow({ type, container, item }) {
  if (type === 'row') {
    return `position: absolute;
    width: ${container.offsetWidth + extendLines * 2}px;
    height: ${item.end - item.start + (item.gap || 0)}px;
    top: ${item.start}px;
    left: ${-extendLines}px;`
  } else {
    return `position: absolute;
    height: ${container.offsetHeight + extendLines * 2}px;
    width: ${item.end - item.start + (item.gap || 0)}px;
    left: ${item.start}px;
    top: ${-extendLines}px;`
  }
}

function setStyles({ data, type, inspectorType, container, inspector, settings }) {
  data.forEach((item, index) => {
    const line = document.createElement('div')

    const lineMainColor =
      index === 0 || index === data.length - 1
        ? `rgba(254, 255, 213, 0.7)`
        : `rgba(254, 255, 213, 0.5)`

    const lineMainColorUser =
      index === 0 || index === data.length - 1 ? `rgba(255, 238, 0, 0.9)` : `rgba(255, 238, 0, 0.7)`

    if (index === 0 || index === data.length - 1) {
      line.setAttribute(
        'style',
        `
        ${
          inspectorType === 'user'
            ? `border: 1px solid ${lineMainColor};`
            : `border: 1px dashed ${lineMainColorUser};`
        }
        ${getMainStyleByColumnAndRow({ type, container, item })}
        `
      )
    } else {
      if (!!item.gap) {
        // const additionalColor = `rgba(254, 255, 213, 0.5)`
        const additionalColor = `rgba(255, 238, 0, 0.5)`

        line.setAttribute(
          'style',
          `
            ${
              inspectorType === 'user'
                ? `border: 1px solid ${lineMainColor};`
                : `background: repeating-linear-gradient(45deg,  transparent,  transparent 6px, ${additionalColor} 7px, ${lineMainColorUser} 7px);`
            }
            ${getMainStyleByColumnAndRow({ type, container, item })}
            `
        )

        if (settings && type === 'column' && settings.columnGap) {
          const settingWrapper = document.createElement('div')

          const setting = document.createElement('div')
          setting.innerHTML = `${settings.columnGap}`

          settingWrapper.setAttribute(
            'style',
            `
              position: absolute;
              width: ${item.end - item.start + (item.gap || 0)}px;
              left: ${item.start}px;
              top: ${-extendLines}px;
              `
          )

          settingWrapper.appendChild(setting)
          setting.classList.add(s.columnGapSignal)

          inspector.appendChild(settingWrapper)
        }

        if (settings && type === 'row' && index !== data.length - 1 && settings.rowGap) {
          const settingWrapper = document.createElement('div')

          const setting = document.createElement('div')
          setting.innerHTML = `${settings.rowGap}`

          settingWrapper.setAttribute(
            'style',
            `
              position: absolute;
            height: ${item.end - item.start + (item.gap || 0)}px;
            top: ${item.start}px;
            left: ${-extendLines}px;
              `
          )

          settingWrapper.appendChild(setting)
          setting.classList.add(s.rowGapSignal)

          inspector.appendChild(settingWrapper)
        }
      } else {
        line.setAttribute(
          'style',
          `
            ${getMainStyleByColumnAndRow({ type, container, item })}
            ${
              inspectorType === 'user'
                ? `border: 1px solid ${lineMainColor};`
                : `border: 1px dashed ${lineMainColorUser};`
            }
        `
        )
      }
    }

    if (inspectorType === 'field' && settings) {
      if (settings.withLines) {
        const lineName = document.createElement('div')
        lineName.innerHTML = `${index + 1}`

        lineName.classList.add(type === 'row' ? s.lineNameRow : s.lineNameColumn)
        line.appendChild(lineName)
      }

      if (type === 'column' && index !== data.length - 1 && settings.columns) {
        const setting = document.createElement('div')
        setting.innerHTML = `${settings.columns[index]}`

        setting.setAttribute(
          'style',
          `
              left: ${(item.start + data[index + 1].start) / 2 - 24}px;
          `
        )

        setting.classList.add(s.columnSignal)

        inspector.appendChild(setting)
      }

      if (type === 'row' && index !== data.length - 1 && settings.rows) {
        const setting = document.createElement('div')
        setting.innerHTML = `${settings.rows[index]}`

        setting.setAttribute(
          'style',
          `
            top: ${(item.start + data[index + 1].start) / 2 - 12}px;
          `
        )

        setting.classList.add(s.rowSignal)

        inspector.appendChild(setting)
      }
    }

    inspector.appendChild(line)
  })
}

function Inspector(props) {
  const { children, type: inspectorType = 'user', isHidden = false, settings } = props

  const containerRef = useRef(null)
  const inspectorRef = useRef(null)
  const isCreated = useRef(false)
  const previousValuesRef = useRef(null)
  const idRef = useRef(`${Math.random().toString(36).substring(7)}`)

  useLayoutEffect(() => {
    if (inspectorRef.current) {
      if (isHidden) {
        inspectorRef.current.style.display = 'none'
      } else {
        inspectorRef.current.style.display = 'block'
      }
    }
  }, [isHidden])

  const buildInspector = useCallback(
    ({ container }) => {
      const inspector = inspectorRef.current || document.createElement('div')

      if (container && !inspectorRef.current) {
        container.appendChild(inspector)
        inspectorRef.current = inspector
      }

      if (container) {
        setTimeout(() => {
          inspector.setAttribute(
            'style',
            `
            ${isHidden ? 'display: none;' : 'display: block;'}
            position: absolute;
            height: ${container.offsetHeight}px;
            width: ${container.offsetWidth}px;
            top: ${container.offsetTop}px;
            left: ${container.offsetLeft}px;
          `
          )

          // get the rows/columns/gaps
          const styles = window.getComputedStyle(container)

          const rows = styles.getPropertyValue('grid-template-rows')
          const columns = styles.getPropertyValue('grid-template-columns')

          let rowGap = styles.getPropertyValue('grid-row-gap')
          let columnGap = styles.getPropertyValue('grid-column-gap')
          const height = styles.getPropertyValue('height')
          const width = styles.getPropertyValue('width')

          const sumValuesHash = `${rows + columns + rowGap + columnGap + height + height}`

          if (sumValuesHash !== previousValuesRef.current) {
            previousValuesRef.current = sumValuesHash

            if (!columnGap.includes('px') && !columnGap.includes('%')) {
              columnGap = '0px'
            }

            if (!rowGap.includes('px') && !rowGap.includes('%')) {
              rowGap = '0px'
            }

            if (rowGap.includes('%')) {
              rowGap = `${
                (parseFloat(height.replace('px', '')) / 100) * parseFloat(rowGap.replace('%', ''))
              }px`
            }

            if (columnGap.includes('%')) {
              columnGap = `${
                (parseFloat(width.replace('px', '')) / 100) * parseFloat(columnGap.replace('%', ''))
              }px`
            }

            // convert the rows and columns into a format we can work with
            const columnData = parseGridTemplate(columns, columnGap)
            const rowData = parseGridTemplate(rows, rowGap)

            inspector.innerHTML = ''

            // render a line for each vertical grid line
            setStyles({
              data: columnData,
              type: 'column',
              container,
              inspectorType,
              inspector,
              settings,
            })

            setStyles({
              data: rowData,
              type: 'row',
              container,
              inspectorType,
              inspector,
              settings,
            })

            isCreated.current = true
          }
        }, 0)
      }
    },
    [inspectorType, isHidden, settings]
  )

  const debouncedBuildInspector = useMemo(() => {
    return debounce(buildInspector, 300)
  }, [buildInspector])

  useLayoutEffect(() => {
    if ((inspectorType === 'field' && !isCreated.current) || inspectorType === 'user') {
      debouncedBuildInspector({ container: containerRef.current })
    }

    return () => {
      if (containerRef.current && document.getElementById(idRef.current)) {
        containerRef.current.removeChild(document.getElementById(idRef.current))
      }
    }
  })

  const resizeCount = useRef(0)

  const debouncedShow = useMemo(() => {
    return debounce(() => {
      buildInspector({ container: containerRef.current })

      setTimeout(() => {
        inspectorRef.current.style.display = 'block'
      }, 50)
    }, 1000)
  }, [buildInspector])

  useLayoutEffect(() => {
    function outputsize() {
      resizeCount.current = resizeCount.current + 1

      if (resizeCount.current > 3) {
        if (inspectorRef.current) {
          inspectorRef.current.style.display = 'none'
          debouncedShow()
        }
      }
    }

    const resizeObserver = new ResizeObserver(outputsize)
    resizeCount.current = 0

    setTimeout(() => {
      resizeObserver.observe(document.getElementById('fieldContainer'))
    }, 0)

    return () => {
      resizeObserver.unobserve(document.getElementById('fieldContainer'))
    }
  }, [debouncedShow])

  return React.cloneElement(children, {
    ref: containerRef,
  })
}

export default React.memo(Inspector)
