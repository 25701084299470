import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from 'games/gridattack/components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/skelet4.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: 1fr auto 1fr;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: 1fr auto 1fr;`]],
})

const HTML = `<div id="field">
  <div class="greenLand"></div>
  <div class="greenLand">
    <p>"Malago Borno!"</p>
  </div>
  <div class="redLand"></div>
</div>`

const Text = () => <p className="text-xl text-white p-4">"Malago Borno!"</p>

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 10,
  level: 10,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: false,
                columns: ['1fr', 'auto', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer />
                  <HumanLandAnswer>
                    <Text />
                  </HumanLandAnswer>
                  <EvilLandAnswer />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand></HumanLand>
                  <HumanLand>
                    <Text />
                  </HumanLand>
                  <EvilLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <Text />
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        {mode === 'hard' && (
          <div className={s.regularText}>
            Seems like this monster is different. To defeat him you need to help Rey cast the spell
            "Malago Borno!" by using <Property property="grid-template-columns" /> with the right
            values.
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              Seems like this monster is different. To defeat him you need to help Rey cast the
              spell "Malago Borno!" by using <Property property="grid-template-columns" /> with the
              right values.
            </div>
            <div className={s.regularText}>
              It's time for me to tell you about another value that{' '}
              <Property property="grid-template-columns" /> can take. The value{' '}
              <Property code="auto" />.
            </div>
            <div className={s.regularText}>
              <Property code="auto" /> automatically sets the size of the columns to the size of the
              container or the size of the contents of the elements in the column.
            </div>
            <div className={s.regularText}>
              For example if our grid is one column grid with <Property code="auto" /> value, this
              column will take the whole grid space. Similar to <Property code="1fr" />.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: auto;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: 'auto',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                auto
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              If it's two columns grid with two <Property code="auto" /> values, they will divide
              the space equally.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: auto auto;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: 'auto auto',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                auto
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                auto
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              When there are some exact values, <Property code="auto" /> will always take up the
              remaining free space. Also similar to <Property code="1fr" />.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 200px auto;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '200px auto',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                200px
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                auto
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              As you may notice, <Property code="auto" /> behaves the same as{' '}
              <Property code="1fr" />. And a good question to ask, WHY we should use it instead of
              fractional unit? But they have one difference.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr auto;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '1fr auto',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                1fr
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                auto
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                1fr
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                1fr
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              Fractional unit will always allocates all the free space, when{' '}
              <Property code="auto" /> will automatically sets the size of the columns to the size
              of the container or the size of the contents of the elements in the column, depending
              on the situation. So above you can see how <Property code="auto" /> sets the size of
              the column to the size of the content inside it. In other words, to the word "auto"
              length.
            </div>
            <div className={s.regularText}>
              Okay, try to use this knowledge to defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}
export default Level
