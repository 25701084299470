import React, { useContext, useState, useMemo } from 'react'
import cx from 'classnames'
import { IoPlay } from 'react-icons/io5'

import s from './LevelDropdown.module.css'

import { GameContext } from '../../Game'
import { GRID_ATTACK_GAME_LEVEL } from 'lib/constants'

function Dropdown({ title, list, onChoose, level }) {
  const [listOpen, setOpen] = useState(false)

  return (
    <div className="flex items-center relative whitespace-nowrap">
      <button
        className="transform rotate-90 text-red-600 cursor-pointer p-2 select-none"
        onClick={() => {
          if (level >= 2) {
            onChoose({ item: { level: level - 1 } })
          }
        }}
      >
        ▼
      </button>
      <div>
        <button
          className={cx(
            'flex select-none items-center py-1 px-3 rounded-md cursor-pointer text-white bg-red-600'
          )}
          onClick={() => {
            setOpen((bool) => !bool)
          }}
        >
          <div>{title}</div>
          <span className={cx(s.ddArrow, 'ml-1')}>
            {listOpen ? (
              <IoPlay size="14" className="transform -rotate-90" />
            ) : (
              <IoPlay size="14" className="transform rotate-90" />
            )}
          </span>
        </button>
        {listOpen && (
          <div
            className={cx(
              s.ddList,
              'flex flex-col p-3 absolute top-0 left-0 rounded-md bg-red-600'
            )}
          >
            {list.map((item, index) => (
              <div
                className={cx(
                  s.ddListItem,
                  'mb-2',
                  item.isUnlocked ? 'cursor-pointer' : s.ddListItemLocked
                )}
                key={index}
                onClick={() => {
                  if (item.isUnlocked) {
                    onChoose({ item })
                    setOpen(false)
                  }
                }}
              >
                {item.title} {level === item.level ? '(current)' : ''}{' '}
                {item.isUnlocked ? '' : '(locked)'}
              </div>
            ))}
          </div>
        )}
      </div>
      <button
        className="transform rotate-90 text-red-600 cursor-pointer p-2 select-none"
        onClick={() => {
          if (level < list[list.length - 1].level) {
            onChoose({ item: { level: level + 1 } })
          }
        }}
      >
        ▲
      </button>
    </div>
  )
}

const MemoDropdown = React.memo(Dropdown)

function LevelDropdown({ levels }: { levels: GRID_ATTACK_GAME_LEVEL[] }) {
  const { maxPlayedLevel, handleLevelChoose, currentLevel } = useContext(GameContext)

  const list = useMemo(
    () =>
      levels.map(({ level }) => {
        return {
          title: `Level ${level}`,
          level,
          isUnlocked: level <= maxPlayedLevel,
        }
      }),
    [levels, maxPlayedLevel]
  )

  const title = `Level ${currentLevel} of ${levels.length}`

  return useMemo(
    () => (
      <div className="flex items-center justify-between mb-6">
        <p className="sm:text-3xl text-2xl font-bold">Level {currentLevel}</p>
        <MemoDropdown onChoose={handleLevelChoose} title={title} list={list} level={currentLevel} />
      </div>
    ),
    [currentLevel, handleLevelChoose, list, title]
  )
}

export default React.memo(LevelDropdown)
