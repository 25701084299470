import cx from 'classnames'

import PropertyCommon from 'components/games/Property'
import Highlight from 'components/games/CodeHighlight'

import s from './PropertyCSS.module.css'

const propertiesValues = {
  'justify-content': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Defines how the browser distributes space between and around content items along the
        main-axis of a flex container.
      </p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">justify-content: center;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>center</p>
        <p>flex-start</p>
        <p>flex-end</p>
        <p>space-between</p>
        <p>space-around</p>
        <p>space-evenly</p>
      </div>
    </div>
  ),
  'align-content': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Sets the distribution of space between and around content items along a flexbox's
        cross-axis.
      </p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">align-content: space-between;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>center</p>
        <p>flex-start</p>
        <p>flex-end</p>
        <p>space-between</p>
        <p>space-around</p>
      </div>
    </div>
  ),
  'align-items': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Defines how the browser distributes space between and around content items along the
        cross-axis of a flex container.
      </p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">align-items: center;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>center</p>
        <p>flex-start</p>
        <p>flex-end</p>
        <p>baseline</p>
        <p>stretch</p>
      </div>
    </div>
  ),
  'align-self': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">Overrides flex item's align-items value.</p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">align-self: center;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>center</p>
        <p>flex-start</p>
        <p>flex-end</p>
        <p>stretch</p>
      </div>
    </div>
  ),
  'flex-direction': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Sets how flex items are placed in the flex container defining the main axis and the
        direction (normal or reversed).
      </p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">flex-direction: row;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>row</p>
        <p>row-reverse</p>
        <p>column</p> <p>column-reverse</p>
      </div>
    </div>
  ),
  order: () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Sets the order to lay out an item in a flexsets the order to lay out an item in a flex
        container.
      </p>
      <p className="mb-2 ">Usage example:</p>
      <div className="mb-1">
        <Highlight language="css">order: 2;</Highlight>
      </div>
      <Highlight language="css">order: -1;</Highlight>
    </div>
  ),
  'flex-wrap': () => (
    <div className={cx(s.propertyDescription, 'p-2')}>
      <p className="mb-2">
        Sets whether flex items are forced onto one line or can wrap onto multiple lines.
      </p>
      <p className="mb-2 ">Usage example:</p>
      <Highlight language="css">flex-wrap: wrap;</Highlight>
      <p className="mb-2 mt-2 ">Common values:</p>
      <div className="pl-2">
        <p>wrap</p>
        <p>no-wrap</p>
        <p>wrap-reverse</p>
      </div>
    </div>
  ),
}

export default function Property({
  children,
  isExpandable = true,
}: {
  children: string
  isExpandable?: boolean
}): JSX.Element {
  const Description = propertiesValues[String(children)]

  return (
    <PropertyCommon
      title={children}
      popover={() => <Description />}
      isExpandable={isExpandable}
      style={s}
    />
  )
}
