import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Talk from '../components/Talk/Talk'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/zarax.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const zaraxImg = '/gamesAssets/gridattack/m-zarax.png'
const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[2].join(',')} {
  ${styles[2].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[3].join(',')} {
  ${styles[3].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#gameFieldBack > div', '#gameFieldBackCharacters > div'],
    ['#redLandBack', '#redLandChar'],
    ['#greenLandBack', '#greenLandChar'],
  ],
  styles: [
    [`grid-template: 1fr 1fr 1fr / 2fr 1fr;`, `place-items: start;`],
    [`height: 50%;`, `width: 50%;`],
    [`place-self: center end;`],
    [`place-self: start end;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#field > div'], ['#redLand'], ['#greenLand']],
  styles: [
    [`grid-template: 1fr 1fr 1fr / 2fr 1fr;`, `place-items: start;`],
    [`height: 50%;`, `width: 50%;`],
    [`place-self: center end;`],
    [`place-self: start end;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 67,
  level: 67,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#field > div'], ['#redLand'], ['#greenLand']],
      styles: [
        [`/* type here */`],
        [`height: 50%;`, `width: 50%;`],
        [`/* type here */`],
        [`/* type here */`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['2fr', '1fr'],
                rows: ['1fr', '1fr', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand"></HumanLandAnswer>
                  <EvilLandAnswer className="redLand"></EvilLandAnswer>
                  <EvilLandAnswer className="redLand"></EvilLandAnswer>
                  <EvilLandAnswer className="redLand"></EvilLandAnswer>
                  <EvilLandAnswer className="redLand"></EvilLandAnswer>
                  <EvilLandAnswer className="redLand"></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand className="redLandBack" />
                  <EvilLand className="redLandBack" />
                  <EvilLand className="redLandBack" />
                  <EvilLand className="redLandBack" />
                  <EvilLand className="redLandBack" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters id="greenLandChar" charactersImgPaths={reyGifPath} />
                  <LandCharacters className="redLandChar" charactersImgPaths={evilGuyPath} />
                  <LandCharacters className="redLandChar" charactersImgPaths={evilGuyPath} />
                  <LandCharacters className="redLandChar" charactersImgPaths={evilGuyPath} />
                  <LandCharacters className="redLandChar" charactersImgPaths={evilGuyPath} />
                  <LandCharacters className="redLandChar" charactersImgPaths={evilGuyPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <Talk className="mb-2" whoImgSrc={zaraxImg} speech="Surprised?" />
        <Talk
          className="mb-2"
          whoImgSrc={zaraxImg}
          speech="Did I forget to say I'm a master of clones? You defeated my clone, but not me."
        />
        <Talk
          className="mb-2"
          whoImgSrc={reyImg}
          speech="Did I forgot to tell you that I'm a master at killing clones?"
        />
        <Talk className="mb-6" whoImgSrc={zaraxImg} speech="Miserable elf, I will kill you!" />
        <div className={s.regularText}>
          I was sure you had beaten him. Alright then, let's show him your full power!
        </div>
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              But before, let's quickly learn a new css grid property that sets both the{' '}
              <Property property="align-self" /> and <Property property="justify-self" /> properties
              in a single declaration. It's called <Property property="place-self" /> and here's how
              it works:
            </div>
            <Highlight language="css" className="mb-8">
              {`place-self: <align-self> <justify-self>;`}
            </Highlight>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100px;
}

#item1 {
  place-self: start end;
}`}
            </Highlight>
            <div
              className={cx('grid mb-8 border-2 border-white')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridAutoRows: '100px',
              }}
            >
              <GridChildExample
                color="blue"
                className="bg-opacity-75"
                style={{ placeSelf: 'start end' }}
              >
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #item2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #item3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #item4
              </GridChildExample>
            </div>
            <div className={s.regularText}>Alright, let's use it to defeat Zarax!</div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
