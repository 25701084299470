import _get from 'lodash/get'
import axios from 'axios'
import { nameByRace } from 'fantasy-name-generator'

import { GAMES } from './constants'

const RACES = ['demon', 'goblin', 'ogre', 'orc']

const getRandomElementFromArray = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

export const jsonFetcher = (selector?: any) => (url: string) =>
  axios.get(url).then((data) => (selector ? _get(data, selector, null) : data ?? null))

export const getGameByUrl = (url: string) => {
  return GAMES.find((game) => game.gameNameId === url)
}

export const generateFantasyName = (): string => {
  return 'Hero'
  // try {
  //   const name = nameByRace(getRandomElementFromArray(RACES))

  //   return 'Hero'
  // } catch (error) {
  //   return 'Traveler'
  // }
}
