export const REGEX_GAME = {
  isReady: false,
  gameNameId: 'elonregex',
  color: 'yellow',
  name: 'Elon RegEx',
  description:
    "There's something hidden in Elon's tweets. Start a risky investigation to find out the truth using regular expressions.",
  // url: 'css-grid-attack',
  landingImageSrc: '/gamesAssets/elonregex/games-preview.png',
  tags: ['javascript', 'regex'],

  // seoTitle: 'Play Grid Attack – CSS Game to learn CSS Grid',
  // seoDescription:
  //   'Learn css grid in a fun and interesting way by playing css coding game where you have to defeat elves from evil monsters!',
  // seoPlayTitle: 'Play Grid Attack Game',
  // seoPlayDescription:
  //   'Learn css grid in a fun and interesting way that works better than boring courses, by playing CSS game!'
}
