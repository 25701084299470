import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/element-king.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#gameFieldBack > div', '#gameFieldBackCharacters > div'],
  ],
  styles: [
    [`gap: 15px;`, `grid-template: repeat(3, 1fr) / repeat(3, 1fr);`, `align-items: start;`],
    [`height: 50%;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#field > div']],
  styles: [
    [`gap: 15px;`, `grid-template: repeat(3, 1fr) / repeat(3, 1fr);`, `align-items: start;`],
    [`height: 50%;`],
  ],
})

const HTML = `<div id="field">
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 56,
  level: 56,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#field > div']],
      styles: [
        [
          `gap: 15px;`,
          `grid-template: repeat(3, 1fr) / repeat(3, 1fr);`,
          `/* change something below */`,
          `align-items: center;`,
        ],
        [`height: 50%;`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr'],
                rows: ['1fr', '1fr', '1fr'],
                columnGap: '15px',
                rowGap: '15px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand />
                  <EvilLand />
                  <HumanLand />
                  <EvilLand />
                  <HumanLand />
                  <EvilLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <div></div>
                  <div></div>
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <div></div>
                  <div></div>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>That was hard, but you did it! Alright, let's move on.</div>
        {mode === 'hard' && (
          <div className={s.regularText}>
            To defeat this monster you need to align grid items along the block (column) axis. I
            hope you know how to do it!
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              To defeat this monster you need to align grid items along the block (column) axis. And
              to do it, we will use <Property property="align-items" /> property. So here's a little
              intro.
            </div>
            <div className={s.regularText}>Values:</div>
            <div className={s.regularText}>
              <Property code="start" codeLikeInEditor /> – aligns items to be flush with the start
              edge of their cell.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100px;
  align-items: start;
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridAutoRows: '100px',
                alignItems: 'start',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #item2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #item3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #item4
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              <Property code="end" codeLikeInEditor /> – aligns items to be flush with the end edge
              of their cell.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100px;
  align-items: end;
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridAutoRows: '100px',
                alignItems: 'end',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #item2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #item3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #item4
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              <Property code="center" codeLikeInEditor /> – aligns items in the center of their
              cell.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100px;
  align-items: center;
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridAutoRows: '100px',
                alignItems: 'center',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #item2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #item3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #item4
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              <Property code="stretch" codeLikeInEditor /> – fills the whole height of the cell
              (this is the default).
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 100px;
  align-items: stretch;
  /* without align-items is the same as with align-items: stretch; */
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridAutoRows: '100px',
                alignItems: 'stretch',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #item1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #item2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #item3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #item4
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              Alright, try to use right <Property property="align-items" /> property values to
              defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
