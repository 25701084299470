import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/shaman1.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: max-content 1fr;`, `grid-template-rows: 1fr 1fr;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: max-content 1fr;`, `grid-template-rows: 1fr 1fr;`]],
})

const Text = ({ color = 'white' }) => (
  <p className={`text-xl text-${color} p-4`}>"And soon I will..."</p>
)

const HTML = `<div id="field">
  <div class="redLand">
    <p>"And soon I will..."</p>
  </div>
  <div class="greenLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 35,
  level: 35,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['max..', '1fr'],
                rows: ['1fr', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer>
                    <Text color="black"></Text>
                  </EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand>
                    <Text></Text>
                  </EvilLand>
                  <HumanLand></HumanLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          This guy likes to leave too many messages on the ground. Okay, let's use our CSS Grid
          weapon to read the first part of the new message.
        </div>
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              And to do it we will use a new value of <Property property="grid-template-columns" />{' '}
              and <Property property="grid-template-rows" /> called <Property code="max-content" />.
            </div>
            <div className={s.regularText}>
              <Property code="max-content" /> represents the intrinsic maximum width of the content.
              For text content this means that the content will not wrap at all even if it causes
              overflows. Imagine a line of text like “The very long hotdog.”,
              <Property code="max-content" /> is the width of the entire line "The very long
              hotdog.", and <Property code="min-content" />, as you remember, is the width of the
              shortest word is "hotdog".
            </div>
            <div className={s.regularText}>Let's look at some examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  grid-template-rows: 100px;
}`}
            </Highlight>
            <div
              className="grid mb-8"
              style={{
                gridTemplateColumns: '1fr 1fr max-content',
                gridTemplateRows: '100px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                I'm very very
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                very very very
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                very very long text.
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              Alright, show this monster how well you know <Property code="max-content" />!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => null,
}

export default Level
