import React from 'react'
import Modal from 'react-modal'
import cx from 'classnames'

import CloseImg from 'public/images/svg/close.svg'
import { useKeyPress } from 'lib/hooks'

Modal.setAppElement('#__next')

const style = {
  content: {
    position: 'initial',
    top: 'initial',
    left: 'initial',
    right: 'initial',
    bottom: 'initial',
    border: 'none',
    background: 'none',
    overflow: 'auto',
    borderRadius: 'none',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
  },
  overlay: {
    background: 'rgba(0,0,0,0.8)',
    zIndex: 999,
  },
}

type Props = {
  isOpen: boolean
  canCloseModal?: boolean
  viewOnSmallScreenAsOnLarge?: boolean
  widthClassName?: string
  handleClose?: () => void
  handleEnterPress?: () => void
  children: React.ReactNode
}

export default function MyModal(props: Props) {
  const {
    isOpen,
    handleClose,
    children,
    handleEnterPress,
    widthClassName = 'max-w-sm',
    canCloseModal = true,
    viewOnSmallScreenAsOnLarge = false,
  } = props

  const enterPress = useKeyPress('Enter')
  const escapePress = useKeyPress('Escape')

  if (enterPress && isOpen) {
    handleEnterPress && handleEnterPress()
  }

  if (escapePress && isOpen && handleClose) {
    handleClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        if (handleClose) {
          handleClose()
        }
      }}
      style={style}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'relative'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'auto'
        document.body.style.position = 'inherit'
      }}
      className={`h-screen h-screen-ios sm:px-4 ${viewOnSmallScreenAsOnLarge ? 'px-4' : 'px-0'}`}
    >
      <div
        className={cx(
          widthClassName,
          `relative inline-block align-bottom bg-white sm:rounded-lg ${' '}
          ${viewOnSmallScreenAsOnLarge ? 'rounded-lg' : 'rounded-none'}
          text-left overflow-y-scroll sm:overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle w-full
           ${viewOnSmallScreenAsOnLarge ? 'h-auto' : 'h-screen-ios sm:h-auto'}`
        )}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {canCloseModal && (
          <button
            onClick={handleClose}
            className="absolute top-0 right-0 mt-2 mr-2 hover:bg-gray-200 flex items-center justify-center h-10 w-10 rounded-full z-50"
          >
            <CloseImg style={{ width: '16px', height: '16px' }} />
          </button>
        )}
        {children}
      </div>
    </Modal>
  )
}
