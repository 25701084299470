import React, { useContext } from 'react'
import cx from 'classnames'

import s from './EvilLand.module.css'
// import { GameFieldSettingsContext } from '../GameField/GameField'

export default function Land({
  style,
  id,
  children,
  className,
}: {
  style?: React.CSSProperties
  id?: string
  className?: string
  children?: React.ReactNode
}) {
  // const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }
  return (
    <div
      id={id}
      style={style}
      className={cx(
        s.land,
        className
        // !isGridHidden && s.targetHighlight
      )}
    >
      <div></div>
      {children}
    </div>
  )
}
