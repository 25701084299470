import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Inspector from '../components/Inspector'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/shaman1.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#redLandBack', '#redLandChar'],
  ],
  styles: [[`grid-template-columns: 1fr 1fr;`, `grid-auto-columns: 1fr;`], [`grid-column: 3;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#redLand']],
  styles: [[`grid-template-columns: 1fr 1fr;`, `grid-auto-columns: 1fr;`], [`grid-column: 3;`]],
})

const HTML = `<div id="field">
  <div class="greenLand"></div>
  <div id="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 49,
  level: 49,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#redLand']],
      styles: [[`grid-template-columns: 1fr 1fr;`, `/* type here */`], [`grid-column: 3;`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr'],
                rows: ['1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer id="redLand"></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand />
                  <EvilLand id="redLandBack" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <LandCharacters id="redLandChar" charactersImgPaths={evilGuyPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          I must make a confession. Up to this level we have only worked with one of the two types
          of grid. Explicit grid. But we never talked about the second type of grid, the implicit
          grid.
        </div>
        <div className={s.regularText}>So what's the difference?</div>
        <div className={s.regularText}>
          When we define our grid using <Property property="grid-template-columns" /> or{' '}
          <Property property="grid-template-rows" /> (or any other property that defines columns or
          rows), we create an explicit grid.
        </div>
        <Highlight language="css" className="mb-8">
          {`.container {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}`}
        </Highlight>
        <div className="relative">
          <Inspector
            type="field"
            settings={{
              withLines: true,
            }}
          >
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
            </div>
          </Inspector>
        </div>
        <div className={s.regularText}>
          We know in advance how many items we will need. And we explicitly define them.
        </div>
        <div className={s.regularText}>
          But if there are more grid items than cells in the grid or when a grid item is placed
          outside of the explicit grid, the grid container automatically generates grid tracks by
          adding grid lines to the grid. The explicit grid together with these additional implicit
          tracks and lines form the so called <strong>implicit grid</strong>.
        </div>
        <Highlight language="css" className="mb-8">
          {`.container {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 100px;
}`}
        </Highlight>
        <div className="relative">
          <Inspector
            type="field"
            settings={{
              withLines: true,
            }}
          >
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '100px 100px',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="yellow" className="bg-opacity-75">
                #6
              </GridChildExample>
              <GridChildExample color="indigo" className="bg-opacity-75">
                #7
              </GridChildExample>
            </div>
          </Inspector>
        </div>
        <div className={s.regularText}>
          Items #5, #6, and #7 are placed outside of the explicit grid.
        </div>
        <div className={s.regularText}>
          The widths and heights of the implicit tracks are set automatically. They are only big
          enough to fit the placed grid items, but it’s possible to change this default behavior.{' '}
          {mode === 'hard' && (
            <>
              And you can do it by using...wait, didn't you choose the "hard" mode? You should know
              it yourself. So show this monster what you know about defining columns and rows for
              implicit grid!
            </>
          )}
        </div>
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              The <Property property="grid-auto-rows" /> and{' '}
              <Property property="grid-auto-columns" /> properties give us control over the size of
              implicit tracks. They accept the same values as{' '}
              <Property property="grid-template-rows" /> and{' '}
              <Property property="grid-template-columns" /> except the function{' '}
              <Property code="repeat()" />.
            </div>
            <div className={s.regularText}>Let's look at some examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 100px;
  grid-auto-columns: 200px;
  grid-auto-rows: 150px;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className={cx('grid mb-8')}
                  style={{
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '100px 100px',
                    gridAutoColumns: '200px',
                    gridAutoRows: '150px',
                    gap: '15px',
                  }}
                >
                  <GridChildExample color="blue" className="bg-opacity-75">
                    #1
                  </GridChildExample>
                  <GridChildExample color="pink" className="bg-opacity-75">
                    #2
                  </GridChildExample>
                  <GridChildExample color="red" className="bg-opacity-75">
                    #3
                  </GridChildExample>
                  <GridChildExample color="purple" className="bg-opacity-75">
                    #4
                  </GridChildExample>
                  <GridChildExample color="green" className="bg-opacity-75">
                    #5
                  </GridChildExample>
                  <GridChildExample color="yellow" className="bg-opacity-75">
                    #6
                  </GridChildExample>
                  <GridChildExample color="indigo" className="bg-opacity-75">
                    #7
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 100px;
  grid-auto-columns: 200px;
  grid-auto-rows: 150px;
}

#item5 {
  grid-column-start: 3;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className={cx('grid mb-8')}
                  style={{
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '100px 100px',
                    gridAutoColumns: '200px',
                    gridAutoRows: '150px',
                    gap: '15px',
                  }}
                >
                  <GridChildExample color="blue" className="bg-opacity-75">
                    #1
                  </GridChildExample>
                  <GridChildExample color="pink" className="bg-opacity-75">
                    #2
                  </GridChildExample>
                  <GridChildExample color="red" className="bg-opacity-75">
                    #3
                  </GridChildExample>
                  <GridChildExample color="purple" className="bg-opacity-75">
                    #4
                  </GridChildExample>
                  <GridChildExample
                    color="green"
                    className="bg-opacity-75"
                    style={{ gridColumnStart: '3' }}
                  >
                    #item5
                  </GridChildExample>
                  <GridChildExample color="yellow" className="bg-opacity-75">
                    #6
                  </GridChildExample>
                  <GridChildExample color="indigo" className="bg-opacity-75">
                    #7
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              And the last thing about implicit grid. If a grid item is positioned into a row that
              is not explicitly sized by <Property property="grid-template-rows" />, implicit grid
              tracks are created to hold it.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 100px;
}`}
            </Highlight>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div
                  className={cx('grid mb-8')}
                  style={{
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridAutoRows: '100px',
                    gap: '15px',
                  }}
                >
                  <GridChildExample color="blue" className="bg-opacity-75">
                    #1
                  </GridChildExample>
                  <GridChildExample color="pink" className="bg-opacity-75">
                    #2
                  </GridChildExample>
                  <GridChildExample color="red" className="bg-opacity-75">
                    #3
                  </GridChildExample>
                  <GridChildExample color="purple" className="bg-opacity-75">
                    #4
                  </GridChildExample>
                  <GridChildExample color="green" className="bg-opacity-75">
                    #5
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              Alright, now try to use <Property property="grid-auto-columns" /> to defeat the
              monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
