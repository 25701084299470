import React from 'react'

import cx from 'classnames'
import Highlight from 'components/games/CodeHighlight'

import s from './Editor.module.css'

const CODE_LINE_HEIGHT = 24

export default function Editor({ game, level, answer, handleEditorCodeChange, checkAnswer }) {
  const { color: gameColor } = game

  const {
    editor: { codePre, codePost, lines, answerLines },
  } = level

  return (
    <div className="flex justify-center items-center w-full">
      <div className={cx(s.code, 'relative rounded-2xl')}>
        <div className={s.codeLine}>
          {new Array(lines).fill(1).map((_, index) => (
            <div className={s.codeLineNumber} key={index}>
              {index + 1}
            </div>
          ))}
        </div>
        <div className="flex flex-col mt-3 w-full">
          <Highlight language="css" usingForGameEditor>
            {codePre}
          </Highlight>
          <textarea
            className={s.codeTextarea}
            autoCapitalize="none"
            spellCheck="false"
            value={answer}
            onChange={(e) => {
              handleEditorCodeChange(e.target.value)
            }}
            onKeyPress={(event) => {
              const key = event.key || event.keyCode

              if (key === 13 || (key === 'Enter' && event.shiftKey)) {
                event.preventDefault()
                checkAnswer()
              }
            }}
            placeholder="Type your answer here..."
            style={{
              height: `${CODE_LINE_HEIGHT * answerLines}px`,
            }}
          ></textarea>
          <Highlight language="css" usingForGameEditor>
            {codePost}
          </Highlight>
        </div>
        <button
          className={cx(
            `inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-${gameColor}-600 text-base font-medium text-white shadow-sm hover:bg-${gameColor}-500  focus:border-${gameColor}-700 focus:shadow-outline-${gameColor} transition ease-in-out duration-150 absolute bottom-0 right-0 mb-8 mr-8`,
            s.nextLevelBtn
          )}
          onClick={checkAnswer}
        >
          Check Answer
        </button>
      </div>
    </div>
  )
}
