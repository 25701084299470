import React from 'react'

import Modal from 'components/games/Modal'

import s from './YouDidItModal.module.css'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'

function YouDidItModal({ mode, color, isOpen, handleClose }) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <div className="bg-white px-4 pt-6 pb-4 sm:p-6 sm:pb-4">
        <div className="flex flex-col items-center">
          <div className={`flex justify-center items-center`}>
            <img src={reyGif} alt="" className="w-24" />
          </div>
          <div className="text-center mt-4">
            <h3 className="text-xl font-medium text-gray-900" id="modal-headline">
              Congratulations, you made it! Just wow!
            </h3>
            <div className="mt-2">
              <div className="text-base  text-gray-500">Now, you are the master of CSS Grid.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 pb-6 flex items-center justify-center">
        <span className="flex rounded-md shadow-sm w-auto">
          <a
            href={`https://twitter.com/intent/tweet?text=I%20did%20it!%20Just%20finished%20all%2080%20levels%20of%20the%20CSS%20Grid%20Attack%20game${
              mode === 'hard' || mode === 'medium' ? `%20on%20${mode}%20mode` : ''
            }%20%F0%9F%A4%A9%EF%B8%8F%20Can%20you%20do%20it?%20https%3A//codingfantasy.com`}
          >
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${color}-600 text-base font-medium text-white shadow-sm hover:bg-${color}-500 focus:border-${color}-700 focus:shadow-outline-red transition ease-in-out duration-150`}
            >
              Tweet About It!
            </button>
          </a>
        </span>
      </div>
    </Modal>
  )
}

export default React.memo(YouDidItModal)
