import React from 'react'

import Modal from 'components/games/Modal'

import s from './LevelCompletedModal.module.css'

const knightGif = '/gamesAssets/flexbox/knight.gif'

export default function LevelCompletedModal({
  game,
  isOpen,
  handleClose,
  completeLevelModalDescriptionBlock,
  goNextLevel,
  currentLevel,
}) {
  const { color: gameColor } = game

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} viewOnSmallScreenAsOnLarge>
      <div className="bg-white px-4 pt-6 pb-4 sm:p-6 sm:pb-4">
        <div className="flex flex-col items-center">
          <div className={`flex justify-center items-center`}>
            <img src={knightGif} alt="" className={s.objectInLeftCorner} />
          </div>
          <div className="text-center mt-4">
            <h3 className="text-lg font-medium text-gray-900" id="modal-headline">
              Level {currentLevel} completed!
            </h3>
            <div className="mt-2">
              <div className="text-base text-gray-500">{completeLevelModalDescriptionBlock()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 pb-6 flex items-center justify-center">
        <span className="flex rounded-md shadow-sm w-auto">
          <button
            onClick={goNextLevel}
            type="button"
            className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-${gameColor}-600 text-base font-medium text-white shadow-sm hover:bg-${gameColor}-500  focus:border-${gameColor}-700 focus:shadow-outline-${gameColor} transition ease-in-out duration-150`}
          >
            Next Level →
          </button>
        </span>
      </div>
    </Modal>
  )
}
