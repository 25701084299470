import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from 'games/gridattack/components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Inspector from '../components/Inspector'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/goblin.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: 50% 50%;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: 50% 50%;`]],
})

const HTML = `<div id="field">
  <div class="redLand"></div>
  <div class="greenLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 1,
  level: 1,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: false,
                columns: ['50%', '50%'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer />
                  <HumanLandAnswer />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand />
                  <HumanLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                  <LandCharacters charactersImgPaths={reyGifPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          Hey Hero! I hope you're ready to start this dangerous adventure and help Rey fight her way
          through an army of monsters and save her brother from Valcorian.
        </div>
        <div className={s.regularText}>
          To defeat monsters and get to the next level, you need to put them on the poisoned land
          (red land), and Rey on the healthy land (green land) using css grid properties.{' '}
          <span className="font-medium">
            The dotted line indicates where you want to put the land.
          </span>
        </div>
        <div className="mb-6">
          <div className="flex items-center mb-2">
            <img
              className="inline-block mr-2"
              src={greenLandImg}
              style={{ width: '50px', height: 'auto ' }}
              alt=""
            />
            →{' '}
            <div
              className="ml-2"
              style={{ width: '50px', height: '50px', border: '5px dashed #6ca743' }}
            ></div>
          </div>
          <div className="flex items-center">
            <img
              className="inline-block mr-2"
              src={redLandImg}
              style={{ width: '50px', height: 'auto ' }}
              alt=""
            />
            →{' '}
            <div
              className="ml-2"
              style={{ width: '50px', height: '50px', border: '5px dashed #a74343' }}
            ></div>
          </div>
        </div>
        <div className={s.regularText}>
          To better understand how to create "correct" grids, you can also use "helpers" lines.
        </div>
        <div className={s.regularText}>
          Dashed yellow lines show the position of the CORRECT grid lines that you should create
          using css grid:
        </div>
        <div
          className="mb-6"
          style={{ width: '350px', height: '3px', borderTop: '2px solid rgba(254, 255, 213, 0.7)' }}
        ></div>
        <div className={s.regularText}>Solid white lines show the position of YOUR grid lines:</div>
        <div
          className="mb-6"
          style={{ width: '350px', height: '3px', borderTop: '2px dashed rgba(251, 255, 25, 0.7' }}
        ></div>
        <div className={s.regularText}>
          Or you can hide them by checking the checkbox under the grid.
        </div>
        {mode === 'hard' && (
          <>
            <div className={s.regularText}>
              So you've chosen a hard mode, so I hope you know how to use CSS Grid and you don't
              need any theory or hints. Then let's start our adventure!
            </div>
            <div className={s.regularText}>Use the right property to defeat the monster!</div>
          </>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              Alright, before we start, I have to give you an instruction on how to use the CSS Grid
              weapon. Otherwise, you might hurt yourself. And who's going to save Luke? Certainly
              not me.
            </div>
            <div className={s.regularText}>So what is CSS Grid?</div>
            <div className={s.regularText}>
              Simply said, it is a table on steroids. It can also align elements by columns and
              rows. However, using css grid you can create way more complex layouts than with
              tables. For example, you can create something like this:
            </div>
            <div
              className="grid mb-8"
              style={{
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridAutoRows: 'minmax(100px, auto)',
                gap: '10px',
              }}
            >
              <div
                className="text-xl flex justify-center items-center bg-red-500 bg-opacity-75 text-black"
                style={{ gridColumn: '1 / 3', gridRow: '1' }}
              >
                This
              </div>
              <div
                className="text-xl flex justify-center items-center bg-blue-500 bg-opacity-75 text-black"
                style={{ gridColumn: '2 / 4', gridRow: '1 / 3' }}
              >
                is
              </div>
              <div
                className="text-xl flex justify-center items-center bg-green-500 bg-opacity-75 text-black"
                style={{ gridColumn: '1', gridRow: '2 / 5' }}
              >
                what
              </div>
              <div
                className="text-xl flex justify-center items-center bg-yellow-500 bg-opacity-75 text-black"
                style={{ gridColumn: '3', gridRow: '3' }}
              >
                you
              </div>
              <div
                className="text-xl flex justify-center items-center bg-purple-500 bg-opacity-75 text-black"
                style={{ gridColumn: '2', gridRow: '4' }}
              >
                can
              </div>
              <div
                className="text-xl flex justify-center items-center bg-pink-500 bg-opacity-75 text-black"
                style={{ gridColumn: '3', gridRow: '4' }}
              >
                make
              </div>
            </div>
            <div className={s.regularText}>
              CSS Grid is a powerful weapon in the right hands. Especially when combined with other
              parts of CSS such as flexbox. It can help you create layouts that were previously
              impossible to build in CSS. And it all starts by creating a grid in your grid
              container.
            </div>
            <div className={s.regularText}>
              We create a grid container by declaring <Property code="display: grid" /> or{' '}
              <Property code="display: inline-grid" /> on an element. As soon as we do this, all
              direct <i>children</i> of that element become <i>grid items</i>.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
}`}
            </Highlight>
            <div className="grid h-12 mb-8">
              <GridChildExample color="yellow" className="bg-opacity-75">
                grid item
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                grid item
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              You won’t see any difference to how these items are displayed before turning them into
              a grid, until we declare columns and/or rows. We can do this by using{' '}
              <Property property="grid-template-columns" /> and{' '}
              <Property property="grid-template-rows" /> properties. They define grid tracks. A{' '}
              <i>grid track</i> is a space between any two lines on the grid. Think of these spaces
              as tables cells. And about <i>grid lines</i> as invisible boundaries separating table
              cells.
            </div>
            <div className={s.regularText}>
              Below you can see highlighted areas which are called <i>tracks</i>. And highlighted
              white grid lines with their names.
            </div>
            <div className="relative">
              <Inspector
                type="field"
                settings={{
                  withLines: true,
                }}
              >
                <div className="grid mb-8" style={{ gridAutoRows: '150px' }}>
                  <GridChildExample
                    color="purple"
                    className="bg-opacity-75"
                    style={{ gridColumn: '1 / 4', gridRow: 1 }}
                  >
                    track
                  </GridChildExample>
                  <GridChildExample
                    color="yellow"
                    className="bg-opacity-75"
                    style={{ gridColumn: 1, gridRow: 2 }}
                  >
                    track
                  </GridChildExample>
                  <GridChildExample
                    color="pink"
                    className="bg-opacity-75"
                    style={{ gridColumn: 2, gridRow: 2 }}
                  >
                    track
                  </GridChildExample>
                  <GridChildExample
                    color="red"
                    className="bg-opacity-75"
                    style={{ gridColumn: 3, gridRow: 2 }}
                  >
                    track
                  </GridChildExample>
                </div>
              </Inspector>
            </div>
            <div className={s.regularText}>
              That's all you need to know so far. Now it's time to use css grid weapon and help Rey
              rescue her brother. Let's get started!
            </div>
            <div className={s.regularText}>
              The first CSS Grid property that we will use is{' '}
              <Property property="grid-template-columns" />. It defines the columns of the grid and
              accepts a space-separated list of values. They can take on different values:{' '}
              <Property code="%" />, <Property code="px" />, <Property code="fr" />, and others. But
              let's start with <Property code="%" />.
            </div>
            <div className={s.regularText}>Here are examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 70% 30%;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '70% 30%',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                70%
              </GridChildExample>
              <GridChildExample color="yellow" className="bg-opacity-75">
                30%
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 20% 30% 50%;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '20% 30% 50%',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                20%
              </GridChildExample>
              <GridChildExample color="yellow" className="bg-opacity-75">
                30%
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                50%
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              As you can see, every value defines one column. So <Property code="70% 30%" /> define
              a two column grid and <Property code="20% 30% 50%" /> define a three column.
            </div>
            <div className={s.regularText}>
              Now try to use the right <Property property="grid-template-columns" /> values to
              defeat the monster!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}
export default Level
