/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import highlight from 'highlight.js'
import * as Sentry from '@sentry/browser'

import { ATTRIBUTES } from './attributes'

try {
  highlight.getLanguage('css').contains.find(({ className }) => className === 'attribute').begin =
    '\\b(' + ATTRIBUTES.join('|') + ')\\b'
} catch (error) {
  console.error(error)
  Sentry.captureException(error)
}

const CodeHighlight = React.forwardRef(
  (
    {
      usingForGameEditor,
      children,
      className,
      language,
      style,
      onClick,
      codeRef,
      codeClassName,
      codeStyle,
    }: {
      usingForGameEditor?: boolean
      children: any
      className?: string
      language: string
      style?: any
      onClick?: () => void
      codeRef?: React.Ref<HTMLElement>
      codeClassName?: string
      codeStyle?: any
    },
    ref: React.Ref<HTMLPreElement>
  ) => {
    const code = useRef(null)

    const refreshHighlight = () => {
      highlight.highlightBlock(code.current)
    }

    useEffect(() => {
      refreshHighlight()
    }, [children])

    return (
      <pre
        ref={ref}
        className={className}
        onClick={() => {
          if (onClick) onClick()
        }}
        style={style}
      >
        <code
          ref={(ref) => {
            code.current = ref
            if (codeRef) {
              codeRef.current = ref
            }
          }}
          className={cx(language, usingForGameEditor && 'usingForGameEditor', codeClassName)}
          style={codeStyle}
        >
          {children}
        </code>
      </pre>
    )
  }
)

export default CodeHighlight
