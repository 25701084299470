import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { useToasts } from 'react-toast-notifications'
import cx from 'classnames'

import ModeModal from 'games/gridattack/components/ModeModal'
import { GAME_LEVEL, GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE } from 'lib/constants'
import { USER_GAME_STATS } from 'lib/constants'
import { useIsMounted } from 'lib/hooks'
import { useUser } from 'lib/hooks'
import Layout from 'components/Layout'
import Paywall from './components/Paywall'
import CatchEmailModal from 'components/games/CatchEmailModal'

import YouDidItModal from './components/YouDidItModal'
import LevelCompletedModal from './components/LevelCompletedModal'

import s from './game.module.css'
import { useCallback } from 'react'
import { GAME_ATTACK_STRIPE_PRODUCT_ID } from 'components/Payment'
import { useRouter } from 'next/router'

const DEFAULT_MODE = 'easy'
const MODES = ['easy', 'medium', 'hard']
const LOCAL_STORAGE_ITEM_NAME = 'gridattack'

export type Difficulty = 'easy' | 'medium' | 'hard'

export const GameContext = React.createContext<{
  mode: string
  answer: string
  currentLevel: number
  maxPlayedLevel: number
  handleLevelChoose({ item }: { item: any }): void
  checkAnswer(): void
  handleEditorCodeChange(code: string): void
  setInspectorHidden(code: string): void
  isInspectorHidden: boolean
}>({
  mode: DEFAULT_MODE,
  answer: '',
  currentLevel: 0,
  maxPlayedLevel: 0,
  handleLevelChoose: () => ({}),
  checkAnswer: () => ({}),
  handleEditorCodeChange: () => ({}),
  setInspectorHidden: () => ({}),
  isInspectorHidden: false,
})

export default function Game({ game }: { game: GRID_ATTACK_GAME_TYPE }) {
  // Load confetti library
  const [confettiLib, setConfettiLib] = useState(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const module = require('canvas-confetti')

    setConfettiLib(module)
  }, [])

  // Toast library
  const { addToast } = useToasts()

  // Router
  const router = useRouter()

  const { mode: defaultMode, love: showPaywallOnLoad } = router?.query

  // Hooks
  const { user, isLoaded: isUserLoaded } = useUser()
  const isMounted = useIsMounted()

  // Game setup
  const { levels, useGameStats, updateGameLevel, color: gameColor, showPaywallAfterLevel } = game
  const { userGameStats } = useGameStats()

  const initialMode = !!defaultMode && MODES.includes(defaultMode) ? defaultMode : DEFAULT_MODE
  const [mode, setMode] = useState<Difficulty>(initialMode)

  const [isGameLoaded, setGameLoaded] = useState(false)
  const [currentLevel, setCurrentLevel] = useState(1)
  const [maxPlayedLevel, setMaxPlayedLevel] = useState(1)
  const [isInspectorHidden, setInspectorHidden] = useState(false)

  const level: GRID_ATTACK_GAME_LEVEL = levels.find(({ level }) => currentLevel === level)!

  const {
    GameLayout,
    CompleteLevelModalDescription,
    checkUserAnswer,
    editor: { nextGenCode },
  } = level

  const [answer, setAnswer] = useState(nextGenCode || '')

  // Set new code into editor when level has changed
  useEffect(() => {
    setAnswer(nextGenCode || '')
  }, [level, nextGenCode])

  // Modals setup
  const [isLevelPassedModalOpen, setLevelPassedModalOpen] = useState(false)
  const [isFinishModalOpen, setFinishModalOpen] = useState(false)
  const [isModeModalOpen, setModeModalOpen] = useState(
    showPaywallOnLoad || defaultMode ? false : true
  )
  const [isCatchEmailModalOpen, setCatchEmailModalOpen] = useState(false)
  const [isPaywallShown, setPaywallShown] = useState(showPaywallOnLoad ? true : false)

  const saveGameStats = useCallback(
    ({ level, mode }: { level: number; mode: string }) => {
      // Un auth
      const gameStatsJSON = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
      const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

      const gameModeMaxPlayedLevel =
        gameStats.find((gameStats: USER_GAME_STATS) => gameStats.mode === mode)?.maxPlayedLevel || 0

      if (level > gameModeMaxPlayedLevel && !user) {
        let newGameStats = []

        if (gameStats.length > 0) {
          if (gameStats.find((stats: USER_GAME_STATS) => stats.mode === mode)) {
            newGameStats = gameStats?.map((stats: USER_GAME_STATS) => {
              if (stats.mode === mode) {
                return {
                  ...stats,
                  maxPlayedLevel: level,
                }
              }

              return stats
            })
          } else {
            newGameStats = [
              ...gameStats,
              {
                mode,
                maxPlayedLevel: level,
                gameNameId: game.gameNameId,
              },
            ]
          }
        } else {
          newGameStats = [
            {
              mode,
              maxPlayedLevel: level,
              gameNameId: game.gameNameId,
            },
          ]
        }

        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, JSON.stringify(newGameStats))

        setMaxPlayedLevel(level > gameModeMaxPlayedLevel ? level : gameModeMaxPlayedLevel)
      }

      // Auth
      if (user) {
        updateGameLevel({ level, mode, user })
        setMaxPlayedLevel(level > maxPlayedLevel ? level : maxPlayedLevel)
      }
    },
    [game.gameNameId, maxPlayedLevel, updateGameLevel, user]
  )

  // Initialization functions
  const loadAndSetAuthUserMaxLevel = useCallback(
    ({ mode: gameMode } = { mode }) => {
      const { maxPlayedLevel: level } = userGameStats?.find(({ mode }) => mode === gameMode) || {
        maxPlayedLevel: 1,
      }

      setCurrentLevel(level)
      setMaxPlayedLevel(level)

      return level
    },
    [mode, userGameStats]
  )

  const loadAndSetUnauthUserMaxLevel = useCallback(
    ({ mode: gameMode } = { mode }) => {
      const gameStatsJSON = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
      const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

      const gameModeMaxPlayedLevel =
        gameStats.find((gameStats: USER_GAME_STATS) => gameStats.mode === gameMode)
          ?.maxPlayedLevel || 0

      const level = gameModeMaxPlayedLevel || 1

      setCurrentLevel(level)
      setMaxPlayedLevel(level)

      return level
    },
    [mode]
  )

  // Initialization game
  useEffect(() => {
    if (!isGameLoaded && isUserLoaded) {
      if (user) {
        const level = loadAndSetAuthUserMaxLevel({ mode })

        saveGameStats({ level, mode })
        setGameLoaded(true)
      } else {
        const level = loadAndSetUnauthUserMaxLevel({ mode })

        saveGameStats({ level, mode })
        setGameLoaded(true)
      }
    }
  }, [
    user,
    isUserLoaded,
    isGameLoaded,
    mode,
    loadAndSetAuthUserMaxLevel,
    saveGameStats,
    loadAndSetUnauthUserMaxLevel,
  ])

  // Game functions

  const isNeedToShowPaywall = useCallback(
    (level: number) => {
      if (user?.profile?.betaTestCode) {
        return false
      }

      // if (
      //   (user &&
      //     level > showPaywallAfterLevel &&
      //     !(user.subscription || user.productsPaidIds.includes(GAME_ATTACK_STRIPE_PRODUCT_ID))) ||
      //   (!user && level > showPaywallAfterLevel)
      // ) {
      //   return true
      // }
      if (!user && level > showPaywallAfterLevel) {
        return true
      }

      return false
    },
    [showPaywallAfterLevel, user]
  )

  const showPaywall = () => {
    setPaywallShown(true)
  }

  const handlePaywallClose = useCallback(() => {
    setPaywallShown(false)
  }, [])

  const handleLevelChoose = useCallback(
    ({ item }: { item: Partial<GAME_LEVEL> }) => {
      if (item.level !== currentLevel && item.level <= maxPlayedLevel) {
        if (isNeedToShowPaywall(item.level!)) {
          showPaywall()
        } else {
          setCurrentLevel(item.level!)
          clearBoard()
        }
      }
    },
    [currentLevel, isNeedToShowPaywall, maxPlayedLevel]
  )

  const handleModeModalChoose = useCallback(
    ({ mode }) => {
      setModeModalOpen(false)
      setMode(mode)

      let level = 1

      if (user) {
        level = loadAndSetAuthUserMaxLevel({ mode })
      } else {
        level = loadAndSetUnauthUserMaxLevel({ mode })
      }

      saveGameStats({ level, mode })
    },
    [loadAndSetAuthUserMaxLevel, loadAndSetUnauthUserMaxLevel, saveGameStats, user]
  )

  const handleCatchEmailClose = useCallback(() => {
    setCatchEmailModalOpen(false)

    localStorage.setItem('isCatchEmail', 'true')
  }, [])

  // Game functions
  const clearBoard = () => {
    setAnswer('')
    setLevelPassedModalOpen(false)
  }

  const checkAnswer = useCallback(() => {
    if (isNeedToShowPaywall(currentLevel + 1)) {
      showPaywall()
      return
    }

    const isLastLevel = levels.length === currentLevel
    const isUserEnteredRightAnswer = checkUserAnswer({ answer })

    if (!isUserEnteredRightAnswer) {
      addToast('Oops, wrong answer. Try again!', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (isUserEnteredRightAnswer) {
      if (confettiLib) {
        confettiLib.default({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        })
      }

      if (isLastLevel) {
        setFinishModalOpen(true)
      } else {
        saveGameStats({ level: currentLevel + 1, mode })

        setTimeout(() => {
          setLevelPassedModalOpen(true)
        }, 500)
      }
    }
  }, [
    addToast,
    answer,
    checkUserAnswer,
    confettiLib,
    currentLevel,
    isNeedToShowPaywall,
    levels.length,
    mode,
    saveGameStats,
  ])

  const goNextLevel = () => {
    setLevelPassedModalOpen(false)

    const nextLevel = currentLevel + 1

    if (isNeedToShowPaywall(nextLevel)) {
      showPaywall()
    } else {
      setCurrentLevel(nextLevel)
      clearBoard()
    }
  }

  // Loading state while loading
  if (!isMounted() || !isGameLoaded) {
    return (
      <div className={cx(s.gameLayout, 'flex justify-center items-center flex-col h-screen-ios')}>
        <div className="font-semibold text-5xl animate-spin mb-4">
          <span role="img" aria-label="">
            🛸
          </span>
        </div>
        <p className={cx('font-medium text-5xl text-white')}>Loading</p>
      </div>
    )
  }

  // Modals functions
  const handleCloseFinishModal = () => {
    setFinishModalOpen(false)
  }

  const handleEditorCodeChange = (value: string) => {
    setAnswer(value)
  }

  const handleCloseModalClick = () => {
    setLevelPassedModalOpen(false)
  }

  return (
    <GameContext.Provider
      value={{
        answer,
        mode,
        currentLevel,
        maxPlayedLevel,
        handleLevelChoose,
        checkAnswer,
        handleEditorCodeChange,
        setInspectorHidden,
        isInspectorHidden,
      }}
    >
      <Head>
        <link
          rel="stylesheet"
          href="https://highlightjs.org/static/demo/styles/atom-one-dark.css"
        />
      </Head>

      <ModeModal
        color={gameColor}
        isOpen={isModeModalOpen}
        handleChoose={handleModeModalChoose}
        defaultDifficulty={mode}
      />

      <Paywall
        isOpen={isPaywallShown}
        onClose={handlePaywallClose}
        redirectAfterSignupUrl={`/games/css-grid-attack/play?mode=${mode}&love=true`}
        redirectAfterSetupSuccessPageUrl={`/games/css-grid-attack/play?mode=${mode}`}
        cancelPaymentUrl={`/games/css-grid-attack/play?mode=${mode}`}
      />

      <CatchEmailModal isOpen={isCatchEmailModalOpen} handleClose={handleCatchEmailClose} />

      <LevelCompletedModal
        color={gameColor}
        isOpen={isLevelPassedModalOpen}
        handleClose={handleCloseModalClick}
        CompleteLevelModalDescription={CompleteLevelModalDescription}
        goNextLevel={goNextLevel}
        currentLevel={currentLevel}
      />

      <YouDidItModal
        mode={mode}
        color={gameColor}
        isOpen={isFinishModalOpen}
        handleClose={handleCloseFinishModal}
      />

      <Layout withoutFooter>
        <GameLayout />
      </Layout>
    </GameContext.Provider>
  )
}
