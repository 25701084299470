import React from 'react'
import Image from 'next/image'

import Modal from 'components/games/Modal'

import s from './Paywall.module.css'
import { useUser } from 'lib/hooks'
import { useRouter } from 'next/router'
import Button from 'components/Button'
import Payment, { GAME_ATTACK_STRIPE_PRODUCT_ID } from 'components/Payment'
import Link from 'next/link'

function Paywall({
  isOpen,
  onClose,
  redirectAfterSignupUrl,
  redirectAfterSetupSuccessPageUrl,
  cancelPaymentUrl,
}) {
  const { user, isLoaded } = useUser()

  const showToSignup = isLoaded && !user

  const showBuySubscription =
    isLoaded &&
    user &&
    !(user.subscription || user.productsPaidIds.includes(GAME_ATTACK_STRIPE_PRODUCT_ID))

  if (!isLoaded) return null

  if (showToSignup)
    return (
      <Modal isOpen={isOpen} handleClose={onClose} viewOnSmallScreenAsOnLarge>
        <div className="bg-white px-4 pt-6 pb-4 sm:p-6 sm:pb-4">
          <div className="flex flex-col items-center">
            <div className={`flex justify-center items-center w-full`}>
              <div className="relative md:h-36 h-28 w-full">
                <Image
                  src="/gamesAssets/flexbox/knight-top.png"
                  layout="fill"
                  quality={100}
                  objectFit="contain"
                  alt=""
                />
              </div>
            </div>
            <div className="text-center mt-4">
              <h3 className="lg:text-2xl text-xl font-medium text-gray-900" id="modal-headline">
                Sign up to unlock the game
              </h3>
              <div className="mt-2">
                <div className="text-base text-gray-500">
                  Create an account in 2 minutes and let's get on with our adventure!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 px-6 pb-6 flex items-center justify-center">
          <Link
            href={`/signup${
              redirectAfterSignupUrl
                ? `?redirectAfterTo=${encodeURIComponent(redirectAfterSignupUrl)}`
                : ''
            }`}
            passHref
          >
            <Button tag="a" btnColor="bg-yellow-600" btnHoverColor="bg-yellow-700">
              Create an account →
            </Button>
          </Link>
        </div>
      </Modal>
    )

  // if (showBuySubscription)
  //   return (
  //     <Modal isOpen={isOpen} handleClose={onClose} widthClassName="max-w-xl">
  //       <div className="w-full p-4 sm:pt-6 sm:pb-8 pb-16 sm:px-2">
  //         <Payment
  //           payOneTimeForGameId="gridattack"
  //           redirectAfterSetupSuccessPageUrl={redirectAfterSetupSuccessPageUrl}
  //           cancelPaymentUrl={cancelPaymentUrl}
  //         />
  //       </div>
  //     </Modal>
  //   )

  return null
}

export default React.memo(Paywall)
