import React, { useContext, useMemo, useLayoutEffect, useRef } from 'react'
import cx from 'classnames'
import throttle from 'lodash/throttle'

import s from './LandCharacters.module.css'
import { debounce } from 'lodash'
// import { GameFieldSettingsContext } from '../GameField/GameField'

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function drawChars(container, character) {
  if (container.getClientRects()[0]) {
    const { width, height } = container.getClientRects()[0]
    const { width: cw, height: ch } = character.getClientRects()[0]

    const top = height - cw > 0 ? height - cw : 0
    const left = width - cw > 0 ? width - cw : 0

    const style = `
      bottom: ${randomIntFromInterval(0, top)}px;
      left: ${randomIntFromInterval(0, left)}px;
    `

    character.setAttribute('style', style)
    container.style.visibility = 'visible'
  }
}

function LandCharacters({
  charactersImgPaths = [],
  style,
  className,
  id,
}: {
  charactersImgPaths: { path: string }[] | []
  style?: React.CSSProperties
  id?: string
  className?: string
}) {
  // const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }
  const containerRef = useRef(null)
  const characterRef = useRef(null)
  const resizeCount = useRef(0)

  const throttledDrawChars = useMemo(() => {
    return debounce(drawChars, 1000)
  }, [])

  useLayoutEffect(() => {
    const container = containerRef?.current
    const character = characterRef?.current

    if (container && character) {
      setTimeout(() => {
        drawChars(container, character)
      }, 0)
    }

    function outputsize() {
      resizeCount.current = resizeCount.current + 1

      if (resizeCount.current > 3) {
        containerRef.current.style.visibility = 'hidden'
        throttledDrawChars(container, character)
      }
    }

    const resizeObserver = new ResizeObserver(outputsize)
    resizeCount.current = 0

    setTimeout(() => {
      resizeObserver.observe(document.getElementById('fieldContainer'))
    }, 0)

    return () => {
      resizeObserver.unobserve(document.getElementById('fieldContainer'))
    }
  }, [throttledDrawChars])

  return (
    <div
      ref={containerRef}
      id={id}
      style={style}
      className={cx(
        s.land,
        className
        // !isGridHidden && s.targetHighlight
      )}
    >
      {charactersImgPaths.map(({ path }, index: number) => (
        <img ref={characterRef} key={index} src={path} alt="" className={cx(s.character)} />
      ))}
    </div>
  )
}

export default React.memo(LandCharacters)
