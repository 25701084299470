import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/element.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: repeat(auto-fill, 150px);`, `gap: 15px;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: repeat(auto-fill, 150px);`, `gap: 15px;`]],
})

const HTML = `<div id="field">
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 40,
  level: 40,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [
        [`/* change something below */`, `grid-template-columns: repeat(6, 150px);`, `gap: 15px;`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
    // const arr = ['700px', '400px', '250px']

    // for (let i = 0; i < arr.length - 1; i++) {
    //   document.getElementById('fieldContainer').style.width = '400px'

    //   isCorrect = isUserAnswerCorrect({
    //     answer: document.getElementById('gameFieldBack').children,
    //     userAnswer: document.getElementById('field').children,
    //   })
    // }
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columnGap: '15px',
                rowGap: '15px',
              }}
              canResize
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                  <HumanLand></HumanLand>
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <div></div>
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <div></div>
                  <div></div>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                  <div></div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          Valcorian is trying to scare us? He doesn't know yet how strong you've become. But to beat
          him, we must become even stronger.
        </div>
        <div className={s.regularText}>
          Imagine a situation where we know that the width of each item in our grid is{' '}
          <Property code="150px" />, but we don't know how many items we will have. So we can't
          define <Property property="grid-template-rows" />, because we also don't know how many
          rows we will need. So we need somehow tell the browser to handle the column sizing and
          element wrapping for us, so that the elements will wrap into rows when the width is not
          large enough to fit them in without any overflow.
        </div>
        {mode === 'hard' && (
          <div className={s.regularText}>
            You said you are a master of css grid, so you must know how to do it. Defeat the monster
            by changing something in <Property property="grid-template-columns" /> value, so that
            the browser will fit the columns into the grid regardless of their quantity or screen
            size.
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>Any ideas on how to do this?</div>
            <div className={s.regularText}>
              I have two: <Property code="auto-fit" /> and <Property code="auto-fill" />. These
              keywords tell the browser to handle the column sizing and element wrapping for us.
            </div>
            <div className={s.regularText}>
              The auto-fill-columns are defined using <Property code="repeat()" /> function with{' '}
              <Property property="grid-template-columns" /> property. Here are some examples:
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
}`}
            </Highlight>
            <div
              className={cx('grid mb-8', s.resizable)}
              style={{
                gridTemplateColumns: 'repeat(auto-fill, 100px)',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #7
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #8
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}`}
            </Highlight>
            <div
              className={cx('grid mb-8', s.resizable)}
              style={{
                gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #7
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #8
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}`}
            </Highlight>
            <div
              className={cx('grid mb-8', s.resizable)}
              style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #7
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #8
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              At first glance, it might seem like there is not difference between{' '}
              <Property code="auto-fit" /> and <Property code="auto-fill" />. But in fact, they have
              one.
            </div>
            <div className={s.regularText}>
              <Property code="auto-fill" /> FILLS the row with as many columns as it can fit.
            </div>
            <div className={s.regularText}>
              <Property code="auto-fit" /> FITS the CURRENTLY AVAILABLE columns into the space by
              expanding them so that they take up any available space.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}`}
            </Highlight>
            <div
              className={cx('grid mb-8', s.resizable)}
              style={{
                gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}`}
            </Highlight>
            <div
              className={cx('grid mb-8', s.resizable)}
              style={{
                gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              As you can see <Property code="auto-fill" /> uses min value of{' '}
              <Property code="minmax(100px, 1fr)" /> function to FILL the row with as many columns
              as it can fit. While <Property code="auto-fit" /> uses max value FITS the CURRENTLY
              AVAILABLE columns into the space by expanding them so that they take up any available
              space. That's the difference between them.
            </div>
            <div className={s.regularText}>
              Let's try to defeat the monster by changing something in{' '}
              <Property property="grid-template-columns" /> so that the browser will fit the columns
              into the grid regardless of their quantity or screen size.
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
