import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from 'games/gridattack/components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/shaman2.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: 100px 30%;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: 100px 30%;`]],
})

const HTML = `<div id="field">
  <div class="redLand"></div>
  <div class="greenLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 5,
  level: 5,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: false,
                columns: ['100px', '30%'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer />
                  <HumanLandAnswer />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand />
                  <HumanLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                  <LandCharacters charactersImgPaths={reyGifPath} />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        {mode === 'hard' && (
          <div className={s.regularText}>
            To defeat this monster you should use other{' '}
            <Property property="grid-template-columns" /> property values and not just{' '}
            <Property code="%" />.
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              Do you remember that I told you that <Property property="grid-template-columns" /> can
              take different values, not just <Property code="%" />? For example pixels:
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 100px 100px;
}`}
            </Highlight>
            <div
              className="grid h-12 mb-8"
              style={{
                gridTemplateColumns: '100px 100px',
              }}
            >
              <GridChildExample color="purple" className="bg-opacity-75">
                100px
              </GridChildExample>
              <GridChildExample color="yellow" className="bg-opacity-75">
                100px
              </GridChildExample>
            </div>
            <div className={s.regularText}>Try to use this knowledge to defeat the monster!</div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}
export default Level
