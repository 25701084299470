import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Land from '../components/Land'
import Talk from '../components/Talk/Talk'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/zarax.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const zaraxImg = '/gamesAssets/gridattack/m-zarax.png'
const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  gap: 15px;
  grid-template: 1fr 1fr / 2fr 1fr;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[2].join(',')} {
  ${styles[2].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[3].join(',')} {
  ${styles[3].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#gameFieldBack > div', '#gameFieldBackCharacters > div'],
    ['#redLandBack', '#redLandChar'],
    ['#greenLandBack', '#greenLandChar'],
  ],
  styles: [
    [`justify-items: center;`, `align-items: center;`],
    [`height: 50%;`, `width: 50%;`],
    [`justify-self: end;`, `align-self: end;`],
    [`justify-self: start;`, `align-self: end;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#field > div'], ['#redLand'], ['#greenLand']],
  styles: [
    [`justify-items: center;`, `align-items: center;`],
    [`height: 50%;`, `width: 50%;`],
    [`justify-self: end;`, `align-self: end;`],
    [`justify-self: start;`, `align-self: end;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div id="redLand"></div>
  <div class="blueLand"></div>
  <div class="blueLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 66,
  level: 66,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#field > div'], ['#redLand'], ['#greenLand']],
      styles: [
        [`/* type here */`],
        [`height: 50%;`, `width: 50%;`],
        [`/* type here */`],
        [`/* type here */`],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['2fr', '1fr'],
                rows: ['1fr', '1fr'],
                columnGap: '15px',
                rowGap: '15px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand"></HumanLandAnswer>
                  <EvilLandAnswer id="redLand"></EvilLandAnswer>
                  <Land type="userAnswer" color="blue" />
                  <Land type="userAnswer" color="blue" />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand id="redLandBack" />
                  <Land type="rightAnswer" color="blue" />
                  <Land type="rightAnswer" color="blue" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters id="greenLandChar" charactersImgPaths={reyGifPath} />
                  <LandCharacters id="redLandChar" charactersImgPaths={evilGuyPath} />
                  <div></div>
                  <div></div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <Talk
          className="mb-2"
          whoImgSrc={zaraxImg}
          speech="And this is the legendary sister of Luke?"
        />
        <Talk
          className="mb-2"
          whoImgSrc={zaraxImg}
          speech="I can't believe such a small elf could reach me."
        />
        <Talk
          className="mb-2"
          whoImgSrc={reyImg}
          speech="Legendary? So sweet! Unfortunately my compliment will be a punch in your face."
        />
        <Talk
          className="mb-2"
          whoImgSrc={zaraxImg}
          speech="Ha ha, I guess you don't know who I am yet. Too bad for you."
        />
        <Talk className="mb-6" whoImgSrc={reyImg} speech="Let me introduce myself first then." />
        <div className={s.regularText}>
          Why are all these monsters so arrogant? Anyway, this is Zarax and we need to help Rey beat
          him! I hope you are ready for this fight!
        </div>
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center relative">
        <div
          style={{
            position: 'absolute',
            top: '42%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            fontSize: '200px',
            fontWeight: '100',
            color: '#dc2625bf',
          }}
        >
          ×
        </div>
        <div className="w-36 h-36">
          <img src={evilGuyGif} alt="Rey" className="w-full h-full" />
        </div>
      </div>
    </div>
  ),
}

export default Level
