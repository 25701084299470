import React, { useEffect, useState } from 'react'
import Spinner from 'components/Spinner'
import ReactDOM from 'react-dom'

const Loader = () => (
  <div
    className="top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-60 absolute"
    style={{ zIndex: 9999 }}
  >
    <Spinner />
  </div>
)

export default function GlobalLoading({ loading = false }) {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  if (isBrowser && loading) {
    return ReactDOM.createPortal(<Loader />, document.querySelector('body'))
  } else {
    return null
  }
}
