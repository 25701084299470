/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { USER_GAME_STATS } from 'lib/constants'
import { jsonFetcher } from 'lib/utils'

import { defaultSwrOptions, GAME_TYPE } from 'lib/constants'

import s from 'games/flexbox/game.module.css'

const knightImg = '/gamesAssets/flexbox/knight.png'
const rogueImg = '/gamesAssets/flexbox/rogue.png'
const mageImg = '/gamesAssets/flexbox/mage.png'

const knightGif = '/gamesAssets/flexbox/knight.gif'
const knightAttackGif = '/gamesAssets/flexbox/knight-attack.gif'
const knightPng = '/gamesAssets/flexbox/knight.png'
const rogueGif = '/gamesAssets/flexbox/rogue.gif'
const rogueAttackGif = '/gamesAssets/flexbox/rogue-attack.gif'
const roguePng = '/gamesAssets/flexbox/rogue.png'
const mageGif = '/gamesAssets/flexbox/mage.gif'
const mageAttackGif = '/gamesAssets/flexbox/mage-attack.gif'
const magePng = '/gamesAssets/flexbox/mage.png'
const applePng = '/gamesAssets/flexbox/apple.png'
const carrotPng = '/gamesAssets/flexbox/carrot.png'
const cheeseImg = '/gamesAssets/flexbox/cheese.png'
const fishImg = '/gamesAssets/flexbox/fish.png'
const chickenImg = '/gamesAssets/flexbox/chicken.png'
const grapeImg = '/gamesAssets/flexbox/grape.png'
const hamImg = '/gamesAssets/flexbox/ham.png'
const meatImg = '/gamesAssets/flexbox/meat.png'
const blueStoneImg = '/gamesAssets/flexbox/blue_stone.png'
const coinGif = '/gamesAssets/flexbox/coin.gif'
const chestPng = '/gamesAssets/flexbox/chest.png'

const zzzGif = '/gamesAssets/flexbox/bad/zzz.gif'
const snakeGif = '/gamesAssets/flexbox/bad/snake.gif'
const spiderGif = '/gamesAssets/flexbox/bad/spider.gif'
const goblinGif = '/gamesAssets/flexbox/bad/goblin.gif'
const lilDragonGif = '/gamesAssets/flexbox/bad/lil-dragon.gif'
const salamanderGif = '/gamesAssets/flexbox/bad/salamander.gif'
const salamander2Gif = '/gamesAssets/flexbox/bad/salamnder2.gif'
const boss1Gif = '/gamesAssets/flexbox/bad/boss1.gif'
const skeletonGif = '/gamesAssets/flexbox/bad/skeleton.gif'
const ghostGif = '/gamesAssets/flexbox/bad/ghost.gif'
const vampireGif = '/gamesAssets/flexbox/bad/vampire.gif'
const lilVampireGif = '/gamesAssets/flexbox/bad/lil-vampire.gif'
const boss3Gif = '/gamesAssets/flexbox/bad/boss3.gif'
const boss2Gif = '/gamesAssets/flexbox/bad/boss2.gif'
const meduzaGif = '/gamesAssets/flexbox/bad/meduza.gif'
const treeGif = '/gamesAssets/flexbox/bad/tree.gif'
const demon2Gif = '/gamesAssets/flexbox/bad/demon.gif'
const demonBigGif = '/gamesAssets/flexbox/bad/demon-big.gif'
const pesGif = '/gamesAssets/flexbox/bad/pes.gif'

const zzzImg = '/gamesAssets/flexbox/bad/zzz.png'
const snakeImg = '/gamesAssets/flexbox/bad/snake.png'
const spiderImg = '/gamesAssets/flexbox/bad/spider.png'
const goblinImg = '/gamesAssets/flexbox/bad/goblin.png'
const lilDragonImg = '/gamesAssets/flexbox/bad/lil-dragon.png'
const salamanderImg = '/gamesAssets/flexbox/bad/salamander.png'
const salamander2Img = '/gamesAssets/flexbox/bad/salamnder2.png'
const boss1Img = '/gamesAssets/flexbox/bad/boss1.png'
const skeletonImg = '/gamesAssets/flexbox/bad/skeleton.png'
const ghostImg = '/gamesAssets/flexbox/bad/ghost.png'
const vampireImg = '/gamesAssets/flexbox/bad/vampire.png'
const lilVampireImg = '/gamesAssets/flexbox/bad/lil-vampire.png'
const boss3Img = '/gamesAssets/flexbox/bad/boss3.png'
const boss2Img = '/gamesAssets/flexbox/bad/boss2.png'
const meduzaImg = '/gamesAssets/flexbox/bad/meduza.png'
const treeImg = '/gamesAssets/flexbox/bad/tree.png'
const demon2Img = '/gamesAssets/flexbox/bad/demon.png'
const demonBigImg = '/gamesAssets/flexbox/bad/demon-big.png'
const pesImg = '/gamesAssets/flexbox/bad/pes.png'

import ShowAnswer from 'games/flexbox/components/ShowAnswer'
import BadGuy from 'games/flexbox/components/BadGuy'
import Boss from 'games/flexbox/components/Boss'
import Food from 'games/flexbox/components/Food'
import Hero from 'games/flexbox/components/Hero'
import LevelDropdown from 'games/flexbox/components/LevelDropdown'
import Stats from 'games/flexbox/components/Stats'
import VS from 'games/flexbox/components/VS'
import GameField from 'games/flexbox/components/GameField/GameField'

import Property from 'games/flexbox/components/PropertyCSS'

import HeartSVG from 'public/images/svg/heart.svg'
import { useUser } from 'lib/hooks'

import { GameContext } from 'games/flexbox/Game'
import Highlight from 'components/games/CodeHighlight'
import { firebaseClient } from 'lib/firebaseClient'
import { User } from 'components/AuthProvider'
import Talk from 'games/flexbox/components/Talk/Talk'
import StatsPath from './components/StatsPath/StatsPath'

export const ExampleBox = ({
  children,
  className = 'justify-center items-center w-12 h-12',
  additionClassName = '',
}) => (
  <div className={cx(`flex rounded-md`, s.exampleChild, additionClassName, className)}>
    {children}
  </div>
)

export const FLEX_BOX_GAME: GAME_TYPE = {
  gameNameId: 'flexbox',
  color: 'purple',
  name: 'Flex Box Adventure',
  description:
    'Start a mysterious adventure and help King Arthur defeat three evil brothers using CSS Flex Box.',
  url: 'flexboxadventure',
  landingImageSrc: '/gamesAssets/flexbox/landing/gamePreview.png',
  tags: ['css', 'free'],

  seoTitle: 'Play Flex Box Adventure – CSS Game to Learn Flexbox',
  seoDescription: 'Learn css flexbox in a fun and easy way by playing css coding game!',
  seoPlayTitle: 'Play Flex Box Adventure CSS Game',
  seoPlayDescription:
    'Learn css flexbox in a fun and interesting way that works better than boring courses, by playing a CSS game!',

  useGameStats() {
    const { user, loading, isLoaded } = useUser()

    let userGameStats: USER_GAME_STATS[] | [] = []

    const createGameStat = async () => {
      firebaseClient
        .firestore()
        .collection('users')
        .doc(user.uid)
        .update({
          games: [
            ...user.profile.games,
            { maxPlayedLevel: 1, gameNameId: 'flexbox', mode: 'easy' },
          ],
        })
    }

    if (user) {
      userGameStats = user.profile.games.filter(({ gameNameId }) => gameNameId === 'flexbox')

      if (userGameStats.length === 0) {
        createGameStat()
      }
    }

    return { userGameStats, loading, isLoaded }
  },
  async transferUnauthUserDataToAuthUser(user) {
    const gameStatsJSON = localStorage.getItem('flexbox')
    const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

    return await Promise.allSettled(
      gameStats.map(({ maxPlayedLevel, mode }) => {
        return FLEX_BOX_GAME.updateGameLevel({ level: maxPlayedLevel, mode, user })
      })
    )
  },
  async updateGameLevel({ level, mode, user }) {
    const gameStats = user.profile.games.find(
      (game) => game.gameNameId === 'flexbox' && mode === game.mode
    )

    let games: USER_GAME_STATS[] = []

    if (gameStats) {
      if (gameStats.maxPlayedLevel < level) {
        games = user.profile.games.map((game) => {
          if (game.gameNameId === 'flexbox' && mode === game.mode) {
            return {
              ...game,
              maxPlayedLevel: level,
            }
          }

          return game
        })

        await firebaseClient.firestore().collection('users').doc(user.uid).update({
          games,
        })

        mutate(
          '/api/user',
          () => ({
            ...user,
            profile: {
              ...user.profile,
              games,
            },
          }),
          false
        )
      }
    } else {
      const game = {
        maxPlayedLevel: level,
        mode,
        gameNameId: 'flexbox',
      }

      await firebaseClient
        .firestore()
        .collection('users')
        .doc(user.uid)
        .update({
          games: firebaseClient.firestore.FieldValue.arrayUnion(game),
        })

      mutate('/api/user')
    }

    return level
  },
  levels: [
    {
      id: 1,
      level: 1,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={1} levels={FLEX_BOX_GAME.levels} />
            {/* <StatsPath level={1} endLevel={24} /> */}
            <Stats heroHealth="50/100" />
            <div className={s.regularText}>
              Hello, Hero! As you know, we must help Arthur defeat the three evil brothers and
              return stolen gold! Are you ready for the adventure?
            </div>
            <div className={s.regularText}>
              Seems like Arthur lost some health after going through a shock with Bit Coins. Let's
              help him! On the right you can see Arthur. On the top you can see Arthur's health. And
              your goal is to restore this health. Sounds easy? Great, let's get started then!
            </div>
            <div className={s.regularText}>
              Use the <Property isExpandable={mode !== 'hard'}>justify-content</Property> property
              and place Arthur on the apple. Probably it'll help to restore some health.
            </div>
            {mode === 'easy' && (
              <div>
                <div className={s.regularText}>
                  <Property>justify-content</Property> defines how the browser distributes space
                  between and around items horizontally (also called main-axis). It has many values
                  but only 6 are widely used:
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span> align items at the center of the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: center;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 justify-center border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start (default)</span> align items at the
                      start of the container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: flex-start;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 justify-start border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span> align items at the end of the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: flex-end;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 border-2 border-solid p-4 rounded-md justify-end'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-between</span> align the first and last
                      item will be flush with the ends of the container and all of the space shared
                      equally between the items.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: space-between;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 justify-between border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-around</span> align items evenly. Items
                      have a half-size space on either end.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: space-around;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 justify-around border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-evenly</span> align items evenly. Items
                      have equal space around them.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  justify-content: space-evenly;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 justify-evenly border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                </ul>
              </div>
            )}
            {mode === 'easy' && <ShowAnswer>justify-content: center;</ShowAnswer>}
          </>
        )
      }),
      answers: [/justify-content *: *center/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="justify-content: center;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => <Hero img={knightGif} id="knight" />}
          gameFieldBack={() => <Food img={applePng} />}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Hero.</p>
        </div>
      ),
    },
    {
      id: 2,
      level: 2,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={2} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="60/100" />
            <div className={s.regularText}>
              Food seems to help. Great! Let's restore Arthur's health to 100 ❤️. Use the{' '}
              {mode === 'hard' ? 'right' : <Property>justify-content</Property>} property and place
              Arthur on the carrot!
            </div>
            <div className="mb-6">
              <VS isFood opponents={[{ good: knightImg, bad: carrotPng }]} />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>justify-content</Property> defines how the browser distributes
                  space between and around items horizontally.
                </div>
                <div className={s.regularText}>Values:</div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-around</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-between</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-evenly</span>
                    </p>
                  </li>
                </ul>
                <ShowAnswer>justify-content: flex-end;</ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/justify-content *: *flex-end/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="justify-content: flex-end;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => <Hero img={knightGif} id="knight" />}
          gameFieldBack={() => <Food img={carrotPng} />}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Hero.</p>
        </div>
      ),
    },
    {
      id: 3,
      level: 3,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={3} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="70/100" mageHealth="70/100" />
            <Talk className="mb-2" whoImgSrc={knightImg} speech="Marilyn!" />
            <Talk
              className="mb-2"
              whoImgSrc={mageImg}
              speech="Hey, Arthur! I have come to help you defeat these brothers."
            />
            <Talk className="mb-6" whoImgSrc={knightImg} speech="That's awesome, then let's go!" />

            <div className={s.regularText}>
              Oh, who have you found!? It's a Marilyn, Arthur's friend. And it looks like the
              Marilyn needs some help, too. Lets help them to restore their health.{' '}
              {mode === 'hard' && <>Use the right property and place them on the food.</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>justify-content</Property> property and place them on the food.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                isFood
                opponents={[
                  { good: knightImg, bad: cheeseImg },
                  { good: mageImg, bad: fishImg },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>justify-content</Property> defines how the browser distributes
                  space between and around items horizontally.
                </div>
                <ShowAnswer>justify-content: space-between;</ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/justify-content *: *space-between/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="justify-content: space-between;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightGif} id="arthur" />
              <Hero img={mageGif} id="marilyn" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={cheeseImg} />
              <Food img={fishImg} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p className="mr-3">to Hero.</p>
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Marilyn.</p>
        </div>
      ),
    },
    {
      id: 4,
      level: 4,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <div className={s.mainTextColor}>
            <LevelDropdown level={4} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="80/100" mageHealth="80/100" />
            <div className={s.regularText}>
              We're just a little bit away from 100!{' '}
              {mode === 'hard' && <>Use the the right property and place the heroes on the food.</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>justify-content</Property> property and place the heroes on the
                  food.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                isFood
                opponents={[
                  { good: knightImg, bad: cheeseImg },
                  { good: mageImg, bad: fishImg },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>justify-content</Property> defines how the browser distributes
                  space between and around items horizontally.
                </div>
                <ShowAnswer>justify-content: space-around;</ShowAnswer>
              </>
            )}
          </div>
        )
      }),
      answers: [/justify-content *: *space-around/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="justify-content: space-around;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightGif} id="arthur" />
              <Hero img={mageGif} id="marilyn" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={cheeseImg} />
              <Food img={fishImg} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p className="mr-3">to Hero.</p>
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Marilyn.</p>
        </div>
      ),
    },
    {
      id: 5,
      level: 5,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <div className={s.mainTextColor}>
            <LevelDropdown level={5} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="90/100" mageHealth="90/100" />
            <div className={s.regularText}>
              These are the last treats for our heroes.{' '}
              {mode === 'hard' && (
                <>I hope you know the right property to help our heroes restore their health!</>
              )}
              {mode !== 'hard' && (
                <>
                  Use the <Property>justify-content</Property> property and place our heroes on the
                  food.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                isFood
                opponents={[
                  { good: knightImg, bad: cheeseImg },
                  { good: mageImg, bad: fishImg },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>justify-content</Property> defines how the browser distributes
                  space between and around items horizontally.
                </div>
                <ShowAnswer>justify-content: space-evenly;</ShowAnswer>
              </>
            )}
          </div>
        )
      }),
      answers: [/justify-content *: *space-evenly/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="justify-content: space-evenly;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightGif} id="arthur" />
              <Hero img={mageGif} id="marilyn" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={cheeseImg} />
              <Food img={fishImg} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p className="mr-3">to Hero.</p>
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Marilyn.</p>
        </div>
      ),
    },
    {
      id: 6,
      level: 6,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={6} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={knightImg}
              speech="Haymitch?! What are you doing here?"
            />
            <Talk
              className="mb-2"
              whoImgSrc={rogueImg}
              speech="I hear you need some help, old friend!"
            />
            <Talk
              className="mb-6"
              whoImgSrc={knightImg}
              speech="Alright, let's go with us! I can't refuse your help!"
            />
            <div className={s.regularText}>
              Who's this? It's Haymitch, an old friend of Arthur. Luckily, he has not been in a
              battle and has 100 health points (I'm personally tired of feeding Marilyn and Arthur)
            </div>
            <div className={s.regularText}>
              And look, what’s that in the middle of the field? Looks like your first enemies. Let
              the battle begin!{' '}
              {mode === 'hard' && (
                <>Use the right property and place our heroes on the mosquitoes to defeat them!</>
              )}
              {mode !== 'hard' && (
                <>
                  Use the <Property>align-items</Property> property to place our heroes on the
                  mosquitoes to defeat them.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  <Property>align-items</Property> defines how the browser distributes space between
                  and around items vertically. Think of it as <Property>justify-content</Property>{' '}
                  but for the vertical axis. Property values:
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span> align items at the center of the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  align-items: center;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 items-center border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start</span> align items at the start of the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  align-items: flex-start;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 items-start border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span> align items at the end of the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  align-items: flex-end;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 items-end border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>baseline</span> items are aligned such as their
                      baselines align.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  align-items: baseline;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 items-baseline border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox className="items-center justify-center h-36 w-12 flex-col">
                        <div>2</div>
                        <div>3</div>
                      </ExampleBox>
                      <ExampleBox className="items-center justify-center h-24 w-12 flex-col">
                        <div>4</div>
                        <div>5</div>
                      </ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>stretch</span> items are stretched to fill the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  align-items: stretch;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 items-stretch border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox className="items-center justify-center w-12">1</ExampleBox>
                      <ExampleBox className="items-center justify-center w-12">2</ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>align-items: center;</ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-items *: *center/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="align-items: center;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={zzzGif} />
              <BadGuy img={zzzGif} />
              <BadGuy img={zzzGif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={zzzGif} />
        </div>
      ),
    },
    {
      id: 7,
      level: 7,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={7} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              You have dealt with your first enemies. That wasn't so hard, was it? I think you can
              beat snakes just as easily.{' '}
              {mode === 'hard' && (
                <>Use the right property and place our heroes on the mosquitoes to defeat them!</>
              )}
              {mode !== 'hard' && (
                <>
                  Use the <Property>align-items</Property> property and place our heroes on the
                  snakes to defeat them.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>align-items</Property> defines how the browser distributes
                  space between and around items vertically.
                </div>
                <div className={s.regularText}>Values:</div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>baseline</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>stretch</span>
                    </p>
                  </li>
                </ul>
                <ShowAnswer>align-items: flex-end;</ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-items *: *flex-end/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="align-items: flex-end;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={snakeGif} badGuySize={2} />
              <BadGuy img={snakeGif} badGuySize={2} />
              <BadGuy img={snakeGif} badGuySize={2} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={snakeGif} />
        </div>
      ),
    },
    {
      id: 8,
      level: 8,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={8} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Alright, the further you go, the cooler it gets! This round won't be so easy. You need
              to use two properties this time.{' '}
              {mode === 'hard' && (
                <>
                  You chose the hard mode, you knew what you were doing! Show these monsters what
                  you can!
                </>
              )}
              {mode !== 'hard' && (
                <>
                  Use both the <Property>align-items</Property> and the{' '}
                  <Property>justify-content</Property> properties and place our heroes on the
                  spiders to defeat them.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Reminder: <Property>align-items</Property> defines how the browser distributes
                  space between and around items vertically and <Property>justify-content</Property>{' '}
                  defines how the browser distributes space between and around items horizontally.
                </div>
                <ShowAnswer>
                  <div>align-items: center;</div>
                  <div>justify-content: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-items *: *center/, /justify-content *: *center/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="align-items: center; justify-content: center;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={spiderGif} badGuySize={2} />
              <BadGuy img={spiderGif} badGuySize={2} />
              <BadGuy img={spiderGif} badGuySize={2} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={spiderGif} />
        </div>
      ),
    },
    {
      id: 9,
      level: 9,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={9} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Who's this now? Three goblins? This will be easy!{' '}
              {mode === 'hard' && <>Use the right properties to defeat them!</>}
              {mode !== 'hard' && (
                <>
                  Use both the <Property>align-items</Property> and the{' '}
                  <Property>justify-content</Property> to defeat them.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>align-items: flex-end;</div>
                  <div>justify-content: space-between;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-items *: *flex-end/, /justify-content *: *space-between/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="align-items: flex-end; justify-content: space-between;"
          gameFieldContainerClassName={s.back1}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={goblinGif} />
              <BadGuy img={goblinGif} />
              <BadGuy img={goblinGif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={goblinGif} />
        </div>
      ),
    },
    {
      id: 10,
      level: 10,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={10} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Something has changed...it looks like you have stepped into the first boss location.{' '}
              His name is Plutus. He’s a tough guy. You will probably meet him very soon. In the{' '}
              meantime, you have to get rid of his servants.{' '}
              {mode === 'hard' && <>Use the right property to defeat them!</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>flex-direction</Property> to defeat them.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, bad: lilDragonImg },
                  { good: mageImg, bad: goblinImg },
                  { good: rogueImg, bad: spiderImg },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  The <Property>flex-direction</Property> property specifies the direction of
                  flexible items. Property values:
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>row (default)</span> flexible items are displayed
                      horizontally, as a row.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-direction: row;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-row w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>row-reverse</span> same as row, but in reverse
                      order.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-direction: row-reverse;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-row-reverse w-64 h-64  border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>column</span> items are displayed vertically, as
                      a column.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-direction: column;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-col w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>column-reverse</span> items are displayed
                      vertically, as a column.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-direction: column-reverse;
}`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-col-reverse w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>
                  <div>flex-direction: column;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/flex-direction *: *column/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: column;"
          gameFieldContainerClassName={s.back2}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={lilDragonGif} />
              <BadGuy img={goblinGif} />
              <BadGuy img={spiderGif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={lilDragonGif} className="mr-2" />
          <BadGuy img={goblinGif} className="mr-2" />
          <BadGuy img={spiderGif} />
        </div>
      ),
    },
    {
      id: 11,
      level: 11,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={11} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Ah, Plutus has a big army. But that's not a problem for you, is it?{' '}
              {mode === 'hard' && <>Use the right property to defeat them!</>}
              {mode !== 'hard' && (
                <>
                  Use <Property>flex-direction</Property> to defeat your foes.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, bad: salamanderImg, badGuySize: 4 },
                  { good: mageImg, bad: snakeImg, badGuySize: 4 },
                  { good: rogueImg, bad: zzzImg, badGuySize: 2 },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>flex-direction: row-reverse;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/flex-direction *: *row-reverse/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: row-reverse;"
          gameFieldContainerClassName={s.back2}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={salamanderGif} badGuySize={4} />
              <BadGuy img={snakeGif} badGuySize={2} />
              <BadGuy img={zzzGif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={salamanderGif} className="mr-2" />
          <BadGuy img={snakeGif} className="mr-2" />
          <BadGuy img={zzzGif} />
        </div>
      ),
    },
    {
      id: 12,
      level: 12,
      checkUserAnswer: ({ answers, userAnswer }): boolean => {
        if (Array.isArray(answers[0])) {
          return (answers as RegExp[][]).some((possibleAnswersArray) => {
            return possibleAnswersArray.every((answer) => {
              return answer.test(userAnswer.toLowerCase().trim())
            })
          })
        } else {
          return (answers as RegExp[]).every((answer) => {
            return answer.test(userAnswer.toLowerCase().trim())
          })
        }
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={12} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              These are the last of Plutus’ servants. Seems like we will meet Plutus in the next
              round. {mode === 'hard' && <>Use the right property to defeat the monsters!</>}
              {mode !== 'hard' && (
                <>
                  Use both the <Property>flex-direction</Property> and the{' '}
                  <Property>justify-content</Property> to defeat them.
                </>
              )}
            </div>
            <div className={s.regularText}>
              Note: when you set the direction to a reversed row or column, the start and end are
              also reversed.
            </div>
            {mode === 'easy' && (
              <>
                <div className="mb-6">
                  <VS
                    opponents={[
                      { good: knightImg, bad: salamander2Img },
                      { good: mageImg, bad: goblinImg },
                      { good: rogueImg, bad: lilDragonImg },
                    ]}
                  />
                </div>
                <ShowAnswer>
                  <div>flex-direction: column;</div>
                  <div>justify-content: flex-end;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/flex-direction *: *column/, /justify-content *: *flex-end/],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: column; justify-content: flex-end;"
          gameFieldContainerClassName={s.back2}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={salamander2Gif} />
              <BadGuy img={goblinGif} />
              <BadGuy img={lilDragonGif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={salamander2Gif} className="mr-2" />
          <BadGuy img={goblinGif} className="mr-2" />
          <BadGuy img={lilDragonGif} />
        </div>
      ),
    },
    {
      id: 13,
      level: 13,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={13} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={boss1Img}
              speech="And this is your army, Arthur? A pathetic imitation of warriors."
            />
            <Talk
              className="mb-2"
              whoImgSrc={knightImg}
              speech="You will pay for those words and for the stolen gold."
            />
            <Talk className="mb-6" whoImgSrc={boss1Img} speech="Ha ha, good luck." />
            <div className={s.regularText}>
              Here's Plutus. I hope you're ready for this fight!{' '}
              {mode === 'hard' && (
                <>To defeat him you need to use right properties. I'm sure you know them.</>
              )}
              {mode !== 'hard' && (
                <>
                  To defeat him you need to use three properties:{' '}
                  <Property>flex-direction</Property>, <Property>justify-content</Property> and{' '}
                  <Property>align-items</Property>.
                </>
              )}{' '}
              Let's fight!
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  Note: when flex direction is a column, justify-content changes to the vertical and
                  align-items changes to horizontal.
                </div>
              </>
            )}
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, boss: boss1Img },
                  { good: mageImg, bad: salamanderImg, badGuySize: 4 },
                  { good: rogueImg, bad: salamander2Img },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>flex-direction: row-reverse;</div>
                  <div>justify-content: center;</div>
                  <div>align-items: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        /flex-direction *: *row-reverse/,
        /justify-content *: *center/,
        /align-items *: *center/,
      ],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 3,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: row-reverse; justify-content: center; align-items: center;"
          gameFieldContainerClassName={s.back2}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Boss img={boss1Gif} badGuySize={4} />
              <BadGuy img={salamanderGif} badGuySize={4} />
              <BadGuy img={salamander2Gif} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={boss1Gif} className="mr-2" />
          <BadGuy img={salamanderGif} className="mr-2" />
          <BadGuy img={salamander2Gif} />
        </div>
      ),
    },
    {
      id: 14,
      level: 14,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={14} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="90/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={rogueImg}
              speech="It was easy, but where is the gold?"
            />
            <Talk
              className="mb-2"
              whoImgSrc={knightImg}
              speech="I think the other brothers have it. They wouldn't trust it to such a moron."
            />
            <Talk className="mb-6" whoImgSrc={rogueImg} speech="I hope so." />
            <div className={s.regularText}>
              You're a hero! You defeated Plutus. But it looks like it was not without small losses.
              The Arthur lost some health points in the battle. You need to help him to restore
              them. {mode === 'hard' && <>Use the right property and place Arthur on the food!</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>order</Property> property and place Arthur on the food.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS isFood opponents={[{ good: knightImg, bad: meatImg }]} />
            </div>
            {mode === 'easy' && (
              <>
                {' '}
                <div className={s.regularText}>
                  The <Property>order</Property> property specifies the order of a flexible item
                  relative to the rest of the flexible items inside the same container. By default,
                  items have a value of 0. But we can set any positive or negative integer value
                  (-2, -1, 0, 1, 2).
                </div>
                <div className={s.regularText}>Examples:</div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      without <span className={s.property}>order</span> property
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-row w-64 h-24 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox className="justify-center items-center w-24 h-12 p-2">
                        <div>#arthur</div>
                      </ExampleBox>
                      <ExampleBox className="justify-center items-center w-24 h-12 p-2">
                        <div>#marilyn</div>
                      </ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      using <span className={s.property}>order</span> property
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}

#arthur {
  order: 2;
}

#marilyn {
  order: 1;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-row w-64 h-24 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox className="justify-center items-center w-24 h-12 order-2 p-2">
                        <div>#arthur</div>
                      </ExampleBox>
                      <ExampleBox className="justify-center items-center w-24 h-12 order-1 p-2">
                        <div>#marilyn</div>
                      </ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>
                  <div>order: 2;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/order *: *2/],
      editor: {
        codePre: `#field {
    display: flex;
}

#arthur {`,
        codePost: `}`,
        lines: 10,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldContainerClassName={s.back2}
          gameField={() => (
            <>
              <Hero
                style={answer}
                img={knightAttackGif}
                additionalClassName="order-1"
                id="arthur"
              />
              <Hero img={mageAttackGif} additionalClassName="order-1" id="marilyn" />
              <Hero img={rogueAttackGif} additionalClassName="order-2" id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <div className="flex w-1/5 order-1"> </div>
              <Food img={meatImg} style={{ order: 2 }} />
              <div className="flex w-1/5 order-3"> </div>
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <p className="text-red-600 mr-2">+10</p>
            <HeartSVG className="w-4 h-4" />
          </div>
          <p>to Hero.</p>
        </div>
      ),
    },
    {
      id: 15,
      level: 15,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={15} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              We have come to the land of a new boss. His name is Zelus. He is the brother of
              Plutus, but much stronger. We need to become stronger to beat him. Let's get started!
            </div>
            <div className={s.regularText}>
              {mode === 'hard' && <>Defeat the skeleton using the right property.</>}
              {mode !== 'hard' && (
                <>
                  {' '}
                  Defeat the skeleton using the <Property>align-self</Property> property.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS opponents={[{ good: mageImg, bad: skeletonImg }]} />
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  The <Property>align-self</Property> property specifies the alignment for the
                  selected item inside the flexible container. Property values (align-self in
                  examples applied to box number 1):
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span> element is positioned at the center
                      of the container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}

#box1 {
  align-self: center;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox additionClassName="self-center">1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start</span> element is positioned at the
                      beginning of the container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}

#box1 {
  align-self: flex-start;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox additionClassName="self-start">1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span> element is positioned at the end
                      of the container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}

#box1 {
  align-self: flex-end;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox additionClassName="self-end">1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>stretch</span> element is positioned to fit the
                      container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
}

#box1 {
  align-self: stretch;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-64 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox
                        className="items-center justify-center w-12"
                        additionClassName="self-stretch"
                      >
                        1
                      </ExampleBox>
                      <ExampleBox>2</ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>
                  <div>align-self: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-self *: *center/],
      editor: {
        codePre: `#field {
    display: flex;
}

#marilyn {`,
        codePost: `}`,
        lines: 10,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldContainerClassName={s.back3}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" style={answer} />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <div className="flex w-1/5"> </div>
              <BadGuy img={skeletonGif} className="self-center" />
              <div className="flex w-1/5"> </div>
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={skeletonGif} />
        </div>
      ),
    },
    {
      id: 16,
      level: 16,
      checkUserAnswer: ({ answers, userAnswer }): boolean => {
        if (Array.isArray(answers[0])) {
          return (answers as RegExp[][]).some((possibleAnswersArray) => {
            return possibleAnswersArray.every((answer) => {
              return answer.test(userAnswer.toLowerCase().trim())
            })
          })
        } else {
          return (answers as RegExp[]).every((answer) => {
            return answer.test(userAnswer.toLowerCase().trim())
          })
        }
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={16} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Hmm, strange. I thought Zelus would have an army of monsters defending him. It seems
              that he is not afraid of us and already waiting. We will battle him in the next level!{' '}
              {mode === 'hard' && <>But now, let's defeat the ghost using the right properties!</>}
              {mode !== 'hard' && (
                <>
                  But now, let's defeat the ghost using the <Property>align-self</Property> and{' '}
                  <Property>order</Property> properties.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS opponents={[{ good: rogueImg, bad: ghostImg }]} />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>align-self: flex-end;</div>
                  <div>order: 1;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        [/align-self *: *flex-end/, /order *: *1/],
        [/align-self *: *flex-end/, /order *: *-1/],
      ],
      editor: {
        codePre: `#field {
    display: flex;
}

#haymitch {`,
        codePost: `}`,
        lines: 10,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldContainerClassName={s.back3}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} additionalClassName="order-2" id="arthur" />
              <Hero img={mageAttackGif} additionalClassName="order-3" id="marilyn" />
              <Hero
                img={rogueAttackGif}
                style={answer}
                additionalClassName="order-4"
                id="haymitch"
              />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={ghostGif} className="self-end" />
              <div className="flex w-1/5"> </div>
              <div className="flex w-1/5"> </div>
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={ghostGif} />
        </div>
      ),
    },
    {
      id: 17,
      level: 17,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={17} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={boss3Img}
              speech="Have you come for your gold? Are you greedy?"
            />
            <Talk
              className="mb-2"
              whoImgSrc={knightImg}
              speech="I have come to take back what was stolen from me! You will regret for what you've done!"
            />
            <Talk
              className="mb-6"
              whoImgSrc={boss3Img}
              speech="And you are funny and pathetic. Go on, my army!"
            />
            <div className={s.regularText}>
              This is Zelus. Do you see his magic staff? It gives him two lives. It won't be that
              easy to defeat him, so be careful.{' '}
              {mode === 'hard' && (
                <>Place each hero against their enemy using the right properties.</>
              )}
              {mode !== 'hard' && (
                <>
                  Place each hero against their enemy using the <Property>flex-direction</Property>,{' '}
                  <Property>justify-content</Property> and <Property>align-items</Property>{' '}
                  properties.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, boss: boss3Img, bossClassName: 'relative top-3' },
                  { good: mageImg, bad: vampireImg },
                  { good: rogueImg, bad: skeletonImg },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                {' '}
                <ShowAnswer>
                  <div>flex-direction: column-reverse;</div>
                  <div>justify-content: flex-end;</div>
                  <div>align-items: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        /flex-direction *: *column-reverse/,
        /justify-content *: *flex-end/,
        /align-items *: *center/,
      ],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 3,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: column-reverse; justify-content: flex-end; align-items: center;"
          gameFieldStyle={answer.trim()}
          gameFieldContainerClassName={s.back3}
          gameField={() => (
            <>
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <BadGuy img={vampireGif} imgClassName="object-in-left-corner" />
              <Boss img={boss3Gif} badGuySize={4} />
              <BadGuy img={skeletonGif} imgClassName="object-in-left-corner" />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={vampireGif} className="mr-2" />
          <BadGuy img={skeletonGif} />
        </div>
      ),
    },
    {
      id: 18,
      level: 18,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={18} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={boss3Img}
              speech="I have many lives, you can't beat me!"
            />
            <Talk
              className="mb-2"
              whoImgSrc={knightImg}
              speech="Your brother said something like that too, do you know where he is now?"
            />
            <Talk className="mb-6" whoImgSrc={boss3Img} speech="Arrrgh!" />
            <div className={s.regularText}>
              We have killed his guards and taken one life from him, it's time to defeat Zelus for
              good. The arthur must be the one to do it. 1 VS 1.{' '}
              {mode === 'hard' && <>Use the right properties to strike the final blow!</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>align-self</Property> and <Property>order</Property> properties
                  to strike the final blow.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[{ good: knightImg, boss: boss3Img, bossClassName: 'relative top-3' }]}
              />
            </div>
            {mode === 'easy' && (
              <>
                {' '}
                <ShowAnswer>
                  <div>align-self: center;</div>
                  <div>order: 2;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-self *: *center/, /order *: *2/],
      editor: {
        codePre: `#field {
    display: flex;
    justify-content: center;
}

#arthur {`,
        codePost: `}`,
        lines: 12,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldContainerClassName={s.back3}
          gameField={() => (
            <>
              <Hero
                img={knightAttackGif}
                additionalClassName="order-0"
                style={answer}
                id="arthur"
              />
              <Hero img={mageAttackGif} additionalClassName="order-1" id="marilyn" />
              <Hero img={rogueAttackGif} additionalClassName="order-2" id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <div className="flex w-1/5"> </div>
              <Boss img={boss3Gif} badGuySize={4} className="self-center" />
              <div className="flex w-1/5"> </div>
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={boss3Gif} />
        </div>
      ),
    },
    {
      id: 19,
      level: 19,
      checkUserAnswer: ({ answers, userAnswer }): boolean => {
        if (Array.isArray(answers[0])) {
          return (answers as RegExp[][]).some((possibleAnswersArray) => {
            return possibleAnswersArray.every((answer) => {
              return answer.test(userAnswer.toLowerCase().trim())
            })
          })
        } else {
          return (answers as RegExp[]).every((answer) => {
            return answer.test(userAnswer.toLowerCase().trim())
          })
        }
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={19} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk className="mb-2" whoImgSrc={rogueImg} speech="We found it!" />
            <Talk className="mb-2" whoImgSrc={mageImg} speech="Finally!" />
            <Talk className="mb-6" whoImgSrc={knightImg} speech="Let's collect it." />
            <div className={s.regularText}>
              Wow! You have found Arthur's stolen gold! Let's collect all of the coins in our
              chests.{' '}
              {mode === 'hard' && (
                <>To do it, place each coin on a chest using the right property!</>
              )}
              {mode !== 'hard' && (
                <>
                  Place each coin on a chest using the <Property>flex-wrap</Property> property.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  The <Property>flex-wrap</Property> property specifies whether the flexible items
                  should wrap or not. Property values:
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>nowrap (default)</span> specifies that the
                      flexible items will not wrap.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: nowrap;
  // or the same behavior
  // without flex-wrap property
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex w-48 h-32 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>wrap</span> specifies that the flexible items
                      will wrap if necessary.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap w-48 h-32 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>wrap-reverse</span> same as wrap, but reverse.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap-reverse;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap-reverse w-48 h-32 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>
                  <div>flex-wrap: wrap;</div>
                  <div>or</div>
                  <div>flex-flow: wrap;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [[/flex-wrap *: *wrap/], [/flex-flow *: *wrap/]],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-wrap: wrap;"
          gameFieldContainerClassName={s.back3}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
        </div>
      ),
    },
    {
      id: 20,
      level: 20,
      checkUserAnswer: ({ answers, userAnswer }): boolean => {
        if (Array.isArray(answers[0])) {
          return (answers as RegExp[][]).some((possibleAnswersArray) => {
            return possibleAnswersArray.every((answer) => {
              return answer.test(userAnswer.toLowerCase().trim())
            })
          })
        } else {
          return (answers as RegExp[]).every((answer) => {
            return answer.test(userAnswer.toLowerCase().trim())
          })
        }
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={20} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Even more coins! What a rich guy Arthur is.{' '}
              {mode === 'hard' && <>Collect them all using the right property!</>}
              {mode !== 'hard' && (
                <>
                  Collect them all using the <Property>align-content</Property> property.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <div className={s.regularText}>
                  The <Property>align-content</Property> property modifies the behavior of the
                  flex-wrap property. It is similar to <Property>align-items</Property>, but instead
                  of aligning flex items, it aligns flex lines. Property values:
                </div>
                <ul className={s.regularText}>
                  <li>
                    <p>
                      <span className={s.property}>center</span> lines stretch to take up the
                      remaining space.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap content-center w-48 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-start</span> lines are packed toward the
                      start of the flex container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap content-start w-48 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>flex-end</span> lines are packed toward the end
                      of the flex container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap content-end w-48 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-between</span> lines are evenly distributed
                      in the flex container.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap content-between w-48 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                  <li>
                    <p>
                      <span className={s.property}>space-around</span> lines are evenly distributed
                      in the flex container, with half-size spaces on either end.
                    </p>
                    <div className="mt-4">
                      <Highlight language="css">
                        {`.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
`}
                      </Highlight>
                    </div>
                    <div
                      className={cx(
                        s.exampleContainer,
                        'mt-4 mb-8 flex flex-wrap content-around w-48 h-64 border-2 border-solid p-4 rounded-md'
                      )}
                    >
                      <ExampleBox>1</ExampleBox>
                      <ExampleBox>2</ExampleBox>
                      <ExampleBox>3</ExampleBox>
                      <ExampleBox>4</ExampleBox>
                    </div>
                  </li>
                </ul>
                <ShowAnswer>
                  <div>align-content: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        [/align-content *: *center/],
        [/place-content *: *center *start/],
        [/place-content *: *center *end/],
      ],
      editor: {
        codePre: `#field {
    display: flex;
    flex-wrap: wrap;`,
        codePost: `}`,
        lines: 8,
        answerLines: 1,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-wrap: wrap; align-content: center;"
          gameFieldClassName="flex-wrap"
          gameFieldContainerClassName={s.back3}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
        </div>
      ),
    },
    {
      id: 21,
      level: 21,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={21} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Do you see the color of the earth? It has changed. And I know whose land it is. This
              land belongs to the brother of Zelus...Cronos.
            </div>
            <div className={s.regularText}>
              He is the oldest brother of the three. He will be very angry when he finds out that
              you have killed his brothers. But they were the first to attack you and steal your
              gold, so we are angrier! If you're brave and think you can beat Cronos, collect all of
              the gold and find out what awaits you.{' '}
              {mode === 'hard' && <>Use the right properties and collect all the gold.</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>align-content</Property> and{' '}
                  <Property>justify-content</Property> properties.
                </>
              )}
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>align-content: flex-end;</div>
                  <div>justify-content: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-content *: *flex-end/, /justify-content *: *center/],
      editor: {
        codePre: `#field {
    display: flex;
    flex-wrap: wrap;`,
        codePost: `}`,
        lines: 8,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-wrap: wrap; align-content: flex-end; justify-content: center;"
          gameFieldClassName="flex-wrap"
          gameFieldContainerClassName={s.back4}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center mr-2">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
          <div className="flex items-center">
            <Food img={coinGif} style={{ width: '50px', height: '50px' }} />
          </div>
        </div>
      ),
    },
    {
      id: 22,
      level: 22,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={22} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={boss2Img}
              speech="Arthur, Haymitch and Marilyn, right? I had time to study you while you were killing my brothers."
            />
            <Talk
              className="mb-2"
              whoImgSrc={boss2Img}
              speech="All that awaits you here is death."
            />
            <Talk
              className="mb-6"
              whoImgSrc={knightImg}
              speech="You'll pay for your Bit Coins with your blood!"
            />
            <div className={s.regularText}>
              It's Cronos and his servants. He is not like your previous enemies. He has 3 lives.
              You have to use everything you have learned to beat beat him. Ready?{' '}
              {mode === 'hard' && (
                <>Place each hero against their enemy using the right properties!</>
              )}
              {mode !== 'hard' && (
                <>
                  Place each hero against their enemy using the <Property>flex-direction</Property>,{' '}
                  <Property>justify-content</Property> and <Property>align-items</Property>{' '}
                  properties.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, boss: boss2Img },
                  { good: mageImg, bad: meduzaImg, badGuySize: 2 },
                  { good: rogueImg, bad: treeImg, badGuySize: 3 },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>flex-direction: column-reverse;</div>
                  <div>justify-content: space-around;</div>
                  <div>align-items: flex-end;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        /flex-direction *: *column-reverse/,
        /justify-content *: *space-around/,
        /align-items *: *flex-end/,
      ],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 8,
        answerLines: 3,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: column-reverse; justify-content: space-around; align-items: flex-end;"
          gameFieldContainerClassName={s.back4}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Boss img={boss2Gif} badGuySize={4} />
              <BadGuy img={meduzaGif} badGuySize={2} />
              <BadGuy img={treeGif} badGuySize={3} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={meduzaGif} className="mr-2" />
          <BadGuy img={treeGif} />
        </div>
      ),
    },
    {
      id: 23,
      level: 23,
      checkUserAnswer: ({ answers, userAnswer }): boolean => {
        if (Array.isArray(answers[0])) {
          return (answers as RegExp[][]).some((possibleAnswersArray) => {
            return possibleAnswersArray.every((answer) => {
              return answer.test(userAnswer.toLowerCase().trim())
            })
          })
        } else {
          return (answers as RegExp[]).every((answer) => {
            return answer.test(userAnswer.toLowerCase().trim())
          })
        }
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={23} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <div className={s.regularText}>
              Minus one life, great! Only two more to go. And look, we also got our gold from his
              servants!{' '}
              {mode === 'hard' && (
                <>
                  Place each hero against their enemy and place the coins into chests using the
                  right properties!
                </>
              )}
              {mode !== 'hard' && (
                <>
                  Place each hero against their enemy and place the coins into chests using the{' '}
                  <Property>flex-direction</Property>, <Property>justify-content</Property>,{' '}
                  <Property>flex-wrap</Property> and <Property>align-content</Property> properties.
                </>
              )}
            </div>
            <div className="mb-6">
              <VS
                opponents={[
                  { good: knightImg, boss: boss2Img },
                  { good: mageImg, bad: demon2Img, badGuySize: 4 },
                  { good: rogueImg, bad: demonBigImg, badGuySize: 4 },
                ]}
              />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>flex-direction: column-reverse;</div>
                  <div>justify-content: center;</div>
                  <div>flex-wrap: wrap-reverse;</div>
                  <div>align-content: center;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [
        [
          /flex-direction *: *column-reverse/,
          /justify-content *: *center/,
          /flex-wrap *: *wrap-reverse/,
          /align-content *: *center/,
        ],
        [
          /flex-flow *: *column-reverse\s{1,}wrap-reverse/,
          /align-content *: *center/,
          /justify-content *: *center/,
        ],
      ],
      editor: {
        codePre: `#field {
    display: flex;`,
        codePost: `}`,
        lines: 10,
        answerLines: 4,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldBackStyle="flex-direction: column-reverse;  justify-content: center; flex-wrap: wrap-reverse; align-content: center;"
          gameFieldContainerClassName={s.back4}
          gameFieldStyle={answer.trim()}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} id="arthur" />
              <Hero img={mageAttackGif} id="marilyn" />
              <Hero img={rogueAttackGif} id="haymitch" />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
              <Food img={coinGif} />
            </>
          )}
          gameFieldBack={() => (
            <>
              <Boss img={boss2Gif} badGuySize={4} className="z-10" />
              <BadGuy img={demon2Gif} badGuySize={4} />
              <BadGuy img={demonBigGif} badGuySize={4} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
              <Food img={chestPng} />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <BadGuy img={demonBigGif} className="mr-2" />
          <BadGuy img={demon2Gif} />
        </div>
      ),
    },
    {
      id: 24,
      level: 24,
      checkUserAnswer: ({ answers, userAnswer }) => {
        return answers.every((answer) => {
          return answer.test(userAnswer.toLowerCase().trim())
        })
      },
      descriptionBlock: React.memo(({ mode }) => {
        return (
          <>
            <LevelDropdown level={24} levels={FLEX_BOX_GAME.levels} />
            <Stats heroHealth="100/100" mageHealth="100/100" rogueHealth="100/100" />
            <Talk
              className="mb-2"
              whoImgSrc={boss2Img}
              speech="You are nothing without your miserable friends."
            />
            <Talk className="mb-6" whoImgSrc={knightImg} speech="Let's fight one-on-one then!" />
            <div className={s.regularText}>
              This is the end of our adventure! I hope that one day we will fight together again!
              Alright, you just have to strike one last blow and take Cronos life. Let our main
              hero, Arthur, strike him down!{' '}
              {mode === 'hard' && <>Use the right properties to help him with it!</>}
              {mode !== 'hard' && (
                <>
                  Use the <Property>align-self</Property> and <Property>order</Property> properties
                  to help him with it!
                </>
              )}
            </div>
            <div className="mb-6">
              <VS opponents={[{ good: knightImg, boss: boss2Img }]} />
            </div>
            {mode === 'easy' && (
              <>
                <ShowAnswer>
                  <div>align-self: center;</div>
                  <div>order: 3;</div>
                </ShowAnswer>
              </>
            )}
          </>
        )
      }),
      answers: [/align-self *: *center/, /order *: *3/],
      editor: {
        codePre: `#field {
    display: flex;
}

#arthur {`,
        codePost: `}`,
        lines: 10,
        answerLines: 2,
      },
      board: ({ answer }) => (
        <GameField
          gameFieldContainerClassName={s.back4}
          gameField={() => (
            <>
              <Hero img={knightAttackGif} style={answer} id="arthur" />
              <Hero img={mageAttackGif} additionalClassName="order-1" id="marilyn" />
              <Hero img={rogueAttackGif} additionalClassName="order-2" id="haymitch" />
            </>
          )}
          gameFieldBack={() => (
            <>
              <div className="flex w-1/5"> </div>
              <div className="flex w-1/5"> </div>
              <Boss img={boss2Gif} badGuySize={4} className="self-center" />
            </>
          )}
        />
      ),
      completeLevelModalDescriptionBlock: () => (
        <div className="flex items-center justify-center text-base text-gray-500">
          <p className="mr-2">Defeated: </p>
          <Boss img={boss2Gif} badGuySize={4} />
        </div>
      ),
    },
  ],
}
