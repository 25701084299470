import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/zombie-king.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [
    [
      `grid-template-columns: minmax(max-content, 200px) minmax(min-content, auto);`,
      `grid-template-rows: 1fr 1fr;`,
    ],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [
    [
      `grid-template-columns: minmax(max-content, 200px) minmax(min-content, auto);`,
      `grid-template-rows: 1fr 1fr;`,
    ],
  ],
})

const Text = ({ color = 'white' }) => <p className={`text-xl text-${color} p-4`}>"You can't..."</p>

const Text2 = ({ color = 'white' }) => <p className={`text-xl text-${color} p-4`}>"stop me."</p>

const HTML = `<div id="field">
  <div class="redLand">
    <p>"You can't..."</p>
  </div>
  <div class="greenLand"></div>
  <div class="greenLand"></div>
  <div class="redLand">
    <p>"stop me."</p>
  </div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 39,
  level: 39,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['min..', 'min..'],
                rows: ['1fr', '1fr'],
              }}
              canResize
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer>
                    <Text color="black"></Text>
                  </EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer>
                    <Text2 color="black"></Text2>
                  </EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand>
                    <Text></Text>
                  </EvilLand>
                  <HumanLand></HumanLand>
                  <HumanLand></HumanLand>
                  <EvilLand>
                    <Text2></Text2>
                  </EvilLand>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          Well done! Now, let's glue two parts of the message together. Make the left column minimum
          width be <Property code="max-content" /> and the maximum column width be{' '}
          <Property code="200px" />. And the right column minimum width be{' '}
          <Property code="min-content" /> and the maximum column width be <Property code="auto" />
        </div>
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => null,
}

export default Level
