import React from 'react'
import Link from 'next/link'
import cx from 'classnames'
import { IoLogoTwitter, IoMailOutline } from 'react-icons/io5'

function Footer() {
  return (
    <footer className={cx('flex flex-col items-center justify-center py-8 px-4 md:px-8 bg-black')}>
      <div className="mb-4">
        <p className="text-gray-400">
          Found a bug or want to say hi?{' '}
          <a
            href="mailto:hi@codingfantasy.com"
            target="_blank"
            rel="noreferrer"
            className="underline inline-flex items-center"
          >
            Email us <IoMailOutline className="ml-2" size="16" color="rgba(156, 163, 175)" />
          </a>
        </p>
      </div>
      <div className="flex space-x-2 flex-wrap justify-center">
        <p className="text-gray-600">
          <Link href="/privacy">Privacy Policy</Link>
        </p>
        <p className="text-gray-600">
          <Link href="/terms">Terms of Use</Link>
        </p>
        <p className="text-gray-600">
          <Link href="/cookies">Cookies Policy</Link>
        </p>
        <p className="text-gray-600">
          <a
            href="https://blog.codingfantasy.com"
            target="_blank"
            className="inline-flex items-center"
          >
            Blog
          </a>
        </p>
        <p className="text-gray-600">
          <Link href="/media-kit">Media Kit</Link>
        </p>
        <p className="text-gray-600">
          <a
            href="https://twitter.com/codingfntsy"
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center"
          >
            Twitter <IoLogoTwitter size="16" color="rgba(75, 85, 99)" className="ml-2" />
          </a>
        </p>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
