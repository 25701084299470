import React, { useState } from 'react'
import cx from 'classnames'

import s from '../game.module.css'

type Props = {
  children: JSX.Element | JSX.Element[] | string
}

export default function ShowAnswer(props: Props) {
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsShown((bool) => !bool)}
        className="mb-4 w-max underline opacity-50"
      >
        Show Answer
      </button>
      {isShown && <div className={cx(s.mainTextColor)}>{props.children}</div>}
    </>
  )
}
