import React, { useLayoutEffect, useState } from 'react'
import cx from 'classnames'

import Modal from 'components/games/Modal'
import s from './ModeModal.module.css'
import Button from 'components/Button'

export type Difficulty = 'easy' | 'medium' | 'hard'

const knightPng = '/gamesAssets/flexbox/knight.png'
const roguePng = '/gamesAssets/flexbox/rogue.png'
const magePng = '/gamesAssets/flexbox/mage.png'

export default function ModeModal({
  isOpen,
  handleClose,
  handleChoose,
  defaultDifficulty,
  color = 'indigo',
}: {
  isOpen: boolean
  handleClose: () => void
  handleChoose: ({ mode }: { mode: Difficulty }) => void
  defaultDifficulty: Difficulty
  color: string
}): JSX.Element {
  const [mode, setMode] = useState(defaultDifficulty)

  useLayoutEffect(() => {
    // hack for IOS in modal
    setTimeout(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        try {
          document.getElementById('easyModeFlex')?.focus()
          document.getElementById('easyModeFlex')?.click()
        } catch (error) {}
      }
    }, 500)
  }, [])

  return (
    <Modal widthClassName={s.modal} isOpen={isOpen} canCloseModal={false}>
      <div className="bg-white p-4 sm:px-4 sm:py-8">
        <div className="flex flex-col items-center">
          <p className="lg:text-4xl md:text-3xl text-2xl sm:mb-8 mb-4 mt-4 font-medium">
            Choose your difficulty
          </p>
          <div className="flex items-stretch flex-col sm:flex-row">
            <div
              id="easyModeFlex"
              onClick={() => setMode('easy')}
              className={cx(
                'p-4 border-transparent border-solid overflow-hidden',
                s.choice,
                mode === 'easy' && `border-${color}-600`
              )}
            >
              <div
                style={{ width: '150px', height: '150px' }}
                className="transform translate-x-5 -mt-16 "
              >
                <img src={knightPng} className="w-full h-auto" />
              </div>
              <div className="font-bold mb-2">Easy</div>
              <div className="text-gray-600 sm:text-left text-center">
                If you're not familiar with flexbox and know nothing or little about it.
              </div>
            </div>
            <div
              onClick={() => setMode('medium')}
              className={cx(
                'p-4 border-transparent border-solid overflow-hidden',
                s.choice,
                mode === 'medium' && `border-${color}-600`
              )}
            >
              <div
                style={{ width: '150px', height: '150px' }}
                className="transform translate-x-5 -mt-16 "
              >
                <img src={magePng} className="w-full h-auto" />
              </div>
              <div className="font-bold mb-2">Medium 🔥</div>
              <div className="text-gray-600 sm:text-left text-center">
                If you have already used flexbox and know its properties well.
              </div>
            </div>
            <div
              onClick={() => setMode('hard')}
              className={cx(
                'p-4 border-transparent border-solid overflow-hidden',
                s.choice,
                mode === 'hard' && `border-${color}-600`
              )}
            >
              <div
                style={{ width: '150px', height: '150px' }}
                className="transform translate-x-5 -mt-16 "
              >
                <img src={roguePng} className=" w-full h-auto" />
              </div>
              <div className="font-bold mb-2">Hard 🔥🔥</div>
              <div className="text-gray-600 sm:text-left text-center">
                For the brave masters of flexbox.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 sm:pb-6 pb-12 flex items-center justify-center">
        <span className="flex rounded-md shadow-sm w-auto">
          <Button
            // className="w-full col-span-2 justify-self-center"
            btnColor={`bg-${color}-600`}
            btnHoverColor={`bg-${color}-700`}
            onClick={() => handleChoose({ mode })}
            // disabled={isSubmitting}
          >
            Play Game
          </Button>
        </span>
      </div>
    </Modal>
  )
}
