import React, { useContext } from 'react'
import cx from 'classnames'

import s from './Food.module.css'
import { GameFieldSettingsContext } from '../GameField'

export default function GameField({ img = '', style = undefined }) {
  const { isGridHidden } = useContext(GameFieldSettingsContext) || { isGridHidden: true }

  return (
    <div
      className={cx(
        'flex justify-center items-center w-1/5 h-1/5',
        s.animated,
        !isGridHidden ? s.targetHighlight : 'pulse'
      )}
      style={style}
    >
      <img src={img} alt="" className="w-2/5 h-2/5" />
    </div>
  )
}
