import React, { useLayoutEffect, useState } from 'react'
import cx from 'classnames'

import Modal from 'components/games/Modal'
import s from './ModeModal.module.css'
import { Difficulty } from '../../Game'
import { GAME_ATTACK_STRIPE_PRODUCT_ID } from 'components/Payment'
import { useUser } from 'lib/hooks'
import Button from 'components/Button'

const reyImg = '/gamesAssets/gridattack/rey.png'
const mageImg = '/gamesAssets/gridattack/m-azazel.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'

function ModeModal({
  isOpen,
  handleChoose,
  defaultDifficulty,
  color,
}: {
  isOpen: boolean
  handleChoose: ({ mode }: { mode: Difficulty }) => void
  defaultDifficulty: Difficulty
  color: string
}): JSX.Element {
  const [mode, setMode] = useState(defaultDifficulty)

  useLayoutEffect(() => {
    // hack for IOS in modal
    setTimeout(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        try {
          document.getElementById('easyModeGrid')?.focus()
          document.getElementById('easyModeGrid')?.click()
        } catch (error) {}
      }
    }, 500)
  }, [])

  return (
    <Modal widthClassName={s.modal} isOpen={isOpen} canCloseModal={false}>
      <div className="bg-white p-4 sm:px-4 sm:py-8">
        <div className="flex flex-col items-center">
          <p className="lg:text-4xl md:text-3xl text-2xl sm:mb-8 mb-4 mt-4 font-medium">
            Choose your difficulty
          </p>
          <div className="flex items-stretch flex-col sm:flex-row">
            <button
              id="easyModeGrid"
              onClick={() => setMode('easy')}
              className={cx(
                'p-4 border-transparent border-solid',
                s.choice,
                mode === 'easy' && `border-${color}-600`
              )}
            >
              <div style={{ width: '75px', height: '75px' }}>
                <img src={reyImg} className="w-full h-auto" alt="" />
              </div>
              <div className="font-bold mb-2">Easy</div>
              <div className="text-gray-600 sm:text-left text-center">
                If you're not familiar with css grid and know nothing or little about it.
              </div>
            </button>
            <button
              onClick={() => setMode('medium')}
              className={cx(
                'p-4 border-transparent border-solid',
                s.choice,
                mode === 'medium' && `border-${color}-600`
              )}
            >
              <div style={{ width: '75px', height: '75px' }}>
                <img src={mageImg} className="w-full h-auto" alt="" />
              </div>
              <div className="font-bold mb-2">
                Medium{' '}
                <span role="img" aria-label="">
                  🔥
                </span>
              </div>
              <div className="text-gray-600 sm:text-left text-center">
                If you've used the css grid before and know it's properties.
              </div>
            </button>
            <button
              onClick={() => {
                // if (isUserHaveFullAccessToTheGame) {
                setMode('hard')
                // }
              }}
              className={cx(
                'p-4 border-transparent border-solid relative overflow-hidden',
                s.choice,
                mode === 'hard' && `border-${color}-600`
              )}
            >
              {/* {!isUserHaveFullAccessToTheGame && (
                <div className="absolute top-0 left-0 w-full h-full bg-gray-200 z-50 bg-opacity-75 flex items-center justify-center">
                  <Button btnColor="bg-yellow-600" btnHoverColor="bg-yellow-700">
                    Unlock
                  </Button>
                </div>
              )} */}
              <div style={{ width: '75px', height: '75px' }}>
                <img src={valcorianImg} className="w-full h-auto" alt="" />
              </div>
              <div className="font-bold mb-2">
                Hard{' '}
                <span role="img" aria-label="">
                  🔥🔥
                </span>
              </div>
              <div className="text-gray-600 sm:text-left text-center">
                For the brave masters of css grid.
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="py-3 px-6 sm:pb-6 pb-12 flex items-center justify-center">
        <span className="flex rounded-md shadow-sm w-auto">
          <Button
            // className="w-full col-span-2 justify-self-center"
            btnColor={`bg-${color}-600`}
            btnHoverColor={`bg-${color}-700`}
            onClick={() => handleChoose({ mode })}
            // disabled={isSubmitting}
          >
            Play Game
          </Button>
        </span>
      </div>
    </Modal>
  )
}

export default React.memo(ModeModal)
