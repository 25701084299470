import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Talk from '../components/Talk/Talk'
import Land from '../components/Land'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const lukeGif = '/gamesAssets/gridattack/luke.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[2].join(',')} {
  ${styles[2].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[3].join(',')} {
  ${styles[3].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#greenLandBack', '#greenLandChar'],
    ['#redLandBack', '#redLandChar'],
    ['#blueLandBack', '#blueLandChar'],
  ],
  styles: [
    [`display: grid;`, `grid: repeat(3, 1fr);`],
    [`grid-column: 1 / 3;`, `grid-row: 1;`],
    [`grid-column: 2 / 4;`, `grid-row: 1 / 3;`],
    [`grid-column: 2;`, `grid-row: 1 / 5;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#greenLand'], ['#redLand'], ['#blueLand']],
  styles: [
    [`display: grid;`, `grid: repeat(3, 1fr);`],
    [`grid-column: 1 / 3;`, `grid-row: 1;`],
    [`grid-column: 2 / 4;`, `grid-row: 1 / 3;`],
    [`grid-column: 2;`, `grid-row: 1 / 5;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div id="redLand"></div>
  <div id="blueLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 80,
  level: 80,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#greenLand'], ['#redLand'], ['#blueLand']],
      styles: [[`/* type here */`], [`/* type here */`], [`/* type here */`], [`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand" />
                  <EvilLandAnswer id="redLand" />
                  <Land id="blueLand" type="userAnswer" color="blue" />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand id="redLandBack" />
                  <Land id="blueLandBack" type="rightAnswer" color="blue" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} id="greenLandChar" />
                  <div id="redLandChar"></div>
                  <LandCharacters charactersImgPaths={[{ path: lukeGif }]} id="blueLandChar" />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <Talk className="mb-2" whoImgSrc={reyImg} speech="Luke! I finally found you!" />
        <Talk
          className="mb-2"
          whoImgSrc={lukeImg}
          speech="Rey stop! Don't come to me. Valcorian has made a puzzle. If you solve it wrong, we will die."
        />
        <Talk className="mb-2" whoImgSrc={reyImg} speech="How to solve it?" />
        <Talk className="mb-2" whoImgSrc={lukeImg} speech="Use your CSS Grid weapon!" />
        <Talk className="mb-6" whoImgSrc={reyImg} speech="Alright, let's do it!" />
        <div className={s.regularText}>
          Are you ready for our last adventure? Let's help Rey save her brother! Let's make you a
          CSS Grid hero!
        </div>
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              And to do it, I'll show you the last property of grid – <Property property="grid" />.
              A shorthand for setting all of the following properties in a single declaration:{' '}
              <Property property="grid-template-rows" />,{' '}
              <Property property="grid-template-columns" />,{' '}
              <Property property="grid-template-areas" />, <Property property="grid-auto-rows" />,
              <Property property="grid-auto-columns" />, and <Property property="grid-auto-flow" />{' '}
              (Note: You can only specify the explicit or the implicit grid properties in a single
              grid declaration).
            </div>
            <div className={s.regularText}>Values:</div>
            <div className={s.regularText}>
              <Property code="<grid-template>" codeLikeInEditor /> – works the same as the{' '}
              <Property property="grid-template" /> shorthand.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid: 100px 150px / repeat(3, 1fr);
  /*
    Same as:
    grid-template: 100px 150px / repeat(3, 1fr);
  */
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                gridTemplate: '100px 150px / repeat(3, 1fr)',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              <Property
                code="<grid-template-rows> / [ auto-flow && dense? ] <grid-auto-columns>?"
                codeLikeInEditor
              />{' '}
              – sets <Property property="grid-template-rows" /> to the specified value. If the
              auto-flow keyword is to the right of the slash, it sets{' '}
              <Property property="grid-auto-flow" /> to column. If the dense keyword is specified
              additionally, the auto-placement algorithm uses a “dense” packing algorithm. If{' '}
              <Property property="grid-auto-columns" /> is omitted, it is set to auto.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid: 100px 150px / auto-flow 100px;
  /*
    Same as:
    grid-template-rows: 100px 150px;
    grid-auto-flow: column;
    grid-auto-columns: 100px;
  */
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                grid: '100px 150px / auto-flow 100px',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              <Property
                code="[ auto-flow && dense? ] <grid-auto-rows>? / <grid-template-columns>"
                codeLikeInEditor
              />{' '}
              – sets <Property property="grid-template-columns" /> to the specified value. If the
              auto-flow keyword is to the left of the slash, it sets{' '}
              <Property property="grid-auto-flow" /> to row. If the dense keyword is specified
              additionally, the auto-placement algorithm uses a “dense” packing algorithm. If{' '}
              <Property property="grid-auto-rows" /> is omitted, it is set to auto.
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  gap: 15px;
  grid: auto-flow dense 100px / 1fr 2fr;
  /*
    Same as:
    grid-auto-flow: row dense;
    grid-auto-rows: 100px;
    grid-template-columns: 1fr 2fr;
  */
}`}
            </Highlight>
            <div
              className={cx('grid mb-8')}
              style={{
                grid: 'auto-flow dense 100px / 1fr 2fr',
                gap: '15px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                #1
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #2
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                #3
              </GridChildExample>
              <GridChildExample color="purple" className="bg-opacity-75">
                #4
              </GridChildExample>
              <GridChildExample color="green" className="bg-opacity-75">
                #5
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                #6
              </GridChildExample>
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => null,
}

export default Level
