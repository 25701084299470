/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { usePopper } from 'react-popper'
import ReactDOM from 'react-dom'
import { useState } from 'react'
import cx from 'classnames'

import styles from './property.module.css'

export default function Property({
  title,
  popover,
  style: s = styles,
  isExpandable = true,
  children,
}: {
  title?: string
  popover?: () => JSX.Element
  style?: {
    property: string
    propertyPopoverContent: string
    propertyPopoverArrow: string
  }
  isExpandable?: boolean
  children?: JSX.Element | string
}): JSX.Element {
  const [referenceElement, setReferenceElement] = useState(null)
  const [isShown, setShownToggle] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  if (!isExpandable) {
    return (
      <span className={cx(s.property, 'rounded-md py-0')}>
        <span className="font-mono">{title || children}</span>
      </span>
    )
  }

  return (
    <>
      <button
        className={cx(s.property, 'rounded-md cursor-pointer py-0')}
        type="button"
        onClick={() => setShownToggle((isShown) => !isShown)}
        ref={setReferenceElement}
      >
        <span className="font-mono">{title || children}</span>
      </button>
      {isShown &&
        ReactDOM.createPortal(
          <div
            className={cx(s.propertyPopoverContent, 'rounded-md')}
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 1000 }}
            {...attributes.popper}
          >
            <div ref={setArrowElement} style={styles.arrow} className={s.propertyPopoverArrow} />
            {popover()}
          </div>,
          document.querySelector('#__next')
        )}
    </>
  )
}
