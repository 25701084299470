import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/shaman1.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [[`grid-template-columns: min-content 1fr;`, `grid-template-rows: 1fr 1fr;`]],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [[`grid-template-columns: min-content 1fr;`, `grid-template-rows: 1fr 1fr;`]],
})

const HTML = `<div id="field">
  <div class="redLand">
    <p>"Rey, your brother is..."</p>
  </div>
  <div class="greenLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Text = ({ color = 'white' }) => (
  <p className={`text-xl text-${color} p-4`}>"Rey, your brother is..."</p>
)

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 33,
  level: 33,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [
        [
          `/* change something bellow */`,
          `grid-template-columns: 1fr 1fr;`,
          `grid-template-rows: 1fr 1fr;`,
        ],
      ],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['min..', '1fr'],
                rows: ['1fr', '1fr'],
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer>
                    <Text color="black"></Text>
                  </EvilLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <HumanLandAnswer></HumanLandAnswer>
                  <EvilLandAnswer></EvilLandAnswer>
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand>
                    <Text></Text>
                  </EvilLand>
                  <HumanLand></HumanLand>
                  <HumanLand></HumanLand>
                  <EvilLand></EvilLand>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <div className={s.regularText}>
          What's this? The Valcorian seems to have left us some kind of message on the ground. Is he
          afraid to tell it to our faces? Alright, then. Let's use our CSS Grid weapon to read the
          first part of the message.
        </div>
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              And to do it, we will use a new value of <Property property="grid-template-columns" />{' '}
              and <Property property="grid-template-rows" /> properties called{' '}
              <Property code="min-content" />.
            </div>
            <div className={s.regularText}>
              When sizing rows and columns, you can use all the lengths you are used to, like{' '}
              <Property code="px" />, <Property code="rem" />, <Property code="%" />, etc, but you
              also have keywords like <Property code="min-content" />. It represents the intrinsic
              minimum width of the content. For text content this means that the content will take
              all soft-wrapping opportunities, becoming as small as the longest word. To simplify
              what it does, imagine a line of text like "The very long hotdog.", than{' '}
              <Property code="min-content" /> will likely be the width of the word "hotdog".
            </div>
            <div className={s.regularText}>Let's look at some examples:</div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  grid-template-rows: 100px;
}`}
            </Highlight>
            <div
              className="grid mb-8"
              style={{
                gridTemplateColumns: '1fr 1fr min-content',
                gridTemplateRows: '100px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                I'm very very
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                very very very
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                supervery long text.
              </GridChildExample>
            </div>
            <Highlight language="css" className="mb-8">
              {`.container {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  grid-template-rows: 200px;
}`}
            </Highlight>
            <div
              className="grid mb-8"
              style={{
                gridTemplateColumns: '1fr min-content min-content',
                gridTemplateRows: '200px',
              }}
            >
              <GridChildExample color="blue" className="bg-opacity-75">
                Hey hero,
              </GridChildExample>
              <GridChildExample color="pink" className="bg-opacity-75">
                do you want to play
              </GridChildExample>
              <GridChildExample color="red" className="bg-opacity-75">
                a game about regular expressions?
              </GridChildExample>
            </div>
            <div className={s.regularText}>
              Now your turn to show the monster how well you know <Property code="min-content" />!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => null,
}

export default Level
