import React from 'react'
import cx from 'classnames'

import s from '../game.module.css'
import Hero from './Hero'
import BadGuy from './BadGuy'
import Boss from './Boss'

export default function VS({ opponents, isFood = false }) {
  return (
    <div>
      <p className={cx(s.regularText)}>Goals:</p>
      {opponents.map(({ good, bad, boss, badGuySize = 1, bossSize = 4, bossClassName }, index) => (
        <div className="flex items-center" key={index}>
          <Hero img={good} additionalClassName={cx(s.w50, s.h50)} />
          <div className="mx-2">{isFood ? 'to' : 'vs.'}</div>
          {bad && (
            <BadGuy
              img={bad}
              badGuySize={badGuySize}
              className={isFood ? 'px-2' : ''}
              style={{
                width: isFood ? '50px' : '50px',
                height: isFood ? '100%' : '50px',
              }}
            />
          )}
          {boss && (
            <Boss
              img={boss}
              badGuySize={bossSize}
              className={bossClassName}
              imgStyle={{
                left: '30%',
                bottom: '15%',
              }}
              style={{
                width: '50px',
                height: '50px',
              }}
            />
          )}
        </div>
      ))}
    </div>
  )
}
