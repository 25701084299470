import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import s from '../../game.module.css'

const FieldCell = () => (
  <>
    <div className="w-1/5 h-1/5 border border-solid opacity-25"></div>
    <div className="w-1/5 h-1/5 border border-solid opacity-25"></div>
    <div className="w-1/5 h-1/5 border border-solid opacity-25"></div>
    <div className="w-1/5 h-1/5 border border-solid opacity-25"></div>
    <div className="w-1/5 h-1/5 border border-solid opacity-25"></div>
  </>
)

export const GameFieldSettingsContext = React.createContext({ isGridHidden: true })

export default function GameField({
  gameFieldBackStyle = '',
  gameFieldContainerClassName = '',
  gameFieldStyle = '',

  gameFieldClassName = '',

  gameField,
  gameFieldBack,
}: {
  gameFieldBackStyle?: string
  gameFieldContainerClassName: string
  gameFieldStyle?: string

  gameFieldClassName?: string

  gameField: () => JSX.Element
  gameFieldBack: () => JSX.Element
}) {
  const [isHeroesHidden, setHeroesHidden] = useState(false)
  const [isGridHidden, setGridHidden] = useState(true)

  const fieldRef = useRef(null)
  const fieldBackRef = useRef(null)

  useEffect(() => {
    if (gameFieldStyle) {
      fieldRef?.current?.setAttribute('style', gameFieldStyle)
    }
  }, [gameFieldStyle])

  useEffect(() => {
    if (gameFieldBackStyle) {
      fieldBackRef?.current?.setAttribute('style', gameFieldBackStyle)
    }
  }, [gameFieldBackStyle])

  return (
    <GameFieldSettingsContext.Provider value={{ isGridHidden }}>
      <div className="flex flex-col justify-center items-center p-4 lg:p-8">
        <div className={cx(`relative z-10`, s.fieldContainer, gameFieldContainerClassName)}>
          <div
            id="gameField"
            className={cx(
              `flex relative z-30  w-full h-full`,
              gameFieldClassName,
              isHeroesHidden ? 'invisible' : ''
            )}
            ref={fieldRef}
          >
            {gameField()}
          </div>
          <div
            id="gameFieldBack"
            className={`flex absolute z-20 top-0 left-0 w-full h-full`}
            ref={fieldBackRef}
          >
            {gameFieldBack()}
          </div>
          {!isGridHidden && (
            <div className="flex flex-wrap absolute z-10 top-0 left-0 w-full h-full">
              <FieldCell />
              <FieldCell />
              <FieldCell />
              <FieldCell />
              <FieldCell />
            </div>
          )}
          <div className={s.fieldBackground} />
        </div>
        <div className="flex items-center justify-start w-full mt-2 relative z-20">
          <input
            type="checkbox"
            id="hideHeroes"
            // defaultValue={isHeroesHidden ? 'checked' : ''}
            defaultChecked={isHeroesHidden}
            value={isHeroesHidden ? 'checked' : ''}
            onChange={(e) => {
              setHeroesHidden(e.target.checked)
            }}
          />
          <label htmlFor="hideHeroes" className={cx(s.mainTextColor, 'ml-2 mr-4')}>
            Hide heroes
          </label>
          <input
            type="checkbox"
            id="hideGrid"
            defaultChecked={isGridHidden}
            // defaultValue={isGridHidden ? 'checked' : ''}
            value={isGridHidden ? 'checked' : ''}
            onChange={(e) => {
              setGridHidden(e.target.checked)
            }}
          />
          <label htmlFor="hideGrid" className={cx(s.mainTextColor, 'ml-2')}>
            Hide grid
          </label>
        </div>
      </div>
    </GameFieldSettingsContext.Provider>
  )
}
