import React from 'react'
import cx from 'classnames'

import s from './Stats.module.css'

const boss1Img = '/gamesAssets/gridattack/boss1.png'
const boss2Img = '/gamesAssets/gridattack/boss2.png'
const boss3Img = '/gamesAssets/gridattack/boss3.png'
const boss4Img = '/gamesAssets/gridattack/boss4.png'
const boss5Img = '/gamesAssets/gridattack/boss5.png'

const BOSSES_INFO = [
  {
    name: 'Azazel',
    img: boss1Img,
    level: 18,
  },
  {
    name: 'Nightcall',
    img: boss2Img,
    level: 29,
  },
  {
    name: 'Evilins',
    img: boss3Img,
    level: 45,
  },
  {
    name: 'Zarax',
    img: boss4Img,
    level: 66,
  },
  {
    name: 'Valcorian',
    img: boss5Img,
    level: 78,
  },
]

const Boss = ({ img, name, isDead }) => (
  <div className="flex flex-col items-center justify-center">
    <img
      src={img}
      className={cx('w-10 sm:w-16', isDead && s.bossImgDead)}
      style={{ maxWidth: '4rem' }}
      alt=""
    />
    <div className="flex items-center">
      <p className="whitespace-nowrap font-normal text-xs sm:text-base">{name}</p>
    </div>
  </div>
)

export default function Stats({ level = 1, endLevel = 80 }) {
  return (
    <div className={cx('mb-6 mr-6 py-4 pb-8')}>
      <div className={`flex relative w-full`}>
        {Array(endLevel)
          .fill(0)
          .map((n, index) => {
            const boss = BOSSES_INFO.find(({ level }) => level === index + 1)

            return (
              <div
                className={cx(
                  s.point,
                  index + 1 < level ? s.isComplete : null,
                  index + 1 === level ? s.isActive : null,
                  boss ? s.isBoss : null
                )}
                key={index}
              >
                {level === index + 1 && <div className={s.here}></div>}
                {boss ? (
                  <>
                    <div>
                      <Boss img={boss.img} name={boss.name} isDead={level > index + 1} />
                    </div>
                    <div className="relative">
                      <div className={cx(s.level, 'font-normal text-sm')}>{boss.level}</div>
                    </div>
                  </>
                ) : null}
              </div>
            )
          })}
      </div>
    </div>
  )
}
