import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from '../components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Talk from '../components/Talk/Talk'
import Land from '../components/Land'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/evilins.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const evilinsImg = '/gamesAssets/gridattack/m-evilins.png'
const reyImg = '/gamesAssets/gridattack/rey.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[1].join(',')} {
  ${styles[1].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[2].join(',')} {
  ${styles[2].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}

${selectors[3].join(',')} {
  ${styles[3].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [
    ['#gameFieldBack', '#gameFieldBackCharacters'],
    ['#greenLandBack', '#greenLandChar'],
    ['#redLandBack', '#redLandChar'],
    ['#blueLandBack', '#blueLandChar'],
  ],
  styles: [
    [
      `gap: 15px;`,
      `grid-template: "redLand redLand ." 100px
      "blueLand greenLand greenLand" 200px
      "blueLand greenLand greenLand" 1fr / 1fr 1fr 1fr`,
    ],
    [`grid-area: greenLand;`],
    [`grid-area: redLand;`],
    [`grid-area: blueLand;`],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field'], ['#greenLand'], ['#redLand'], ['#blueLand']],
  styles: [
    [
      `gap: 15px;`,
      `grid-template: "redLand redLand ." 100px
      "blueLand greenLand greenLand" 200px
      "blueLand greenLand greenLand" 1fr / 1fr 1fr 1fr`,
    ],
    [`grid-area: greenLand;`],
    [`grid-area: redLand;`],
    [`grid-area: blueLand;`],
  ],
})

const HTML = `<div id="field">
  <div id="greenLand"></div>
  <div id="redLand"></div>
  <div id="blueLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 45,
  level: 45,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field'], ['#greenLand'], ['#redLand'], ['#blueLand']],
      styles: [[`/* type here */`], [`/* type here */`], [`/* type here */`], [`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: true,
                columns: ['1fr', '1fr', '1fr'],
                rows: ['100px', '200px', '1fr'],
                columnGap: '15px',
                rowGap: '15px',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <HumanLandAnswer id="greenLand" />
                  <EvilLandAnswer id="redLand" />
                  <Land type="userAnswer" color="blue" id="blueLand" />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <HumanLand id="greenLandBack" />
                  <EvilLand id="redLandBack" />
                  <Land type="rightAnswer" color="blue" id="blueLandBack" />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <LandCharacters charactersImgPaths={reyGifPath} id="greenLandChar" />
                  <LandCharacters charactersImgPaths={evilGuyPath} id="redLandChar" />
                  <LandCharacters charactersImgPaths={evilGuyPath} id="blueLandChar" />
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <Talk
          className="mb-2"
          whoImgSrc={evilinsImg}
          speech="My brother said you know some tricks. But I don't think you know enough to beat the two of us. You're too weak for that."
        />
        <Talk
          className="mb-2"
          whoImgSrc={reyImg}
          speech="At least I don't have such an ugly copy of me. Oh wait..."
        />
        <Talk className="mb-2" whoImgSrc={evilinsImg} speech="You will pay for what you said." />
        <Talk className="mb-2" whoImgSrc={reyImg} speech="Sorry, I forgot my wallet at home." />
        <Talk className="mb-6" whoImgSrc={evilinsImg} speech="Arrrgh!" />
        <div className={s.regularText}>
          Before the battle begins. I heard that one of the brothers is afraid of water (blue area).
          Try using it against him!
        </div>
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center">
        <Talk
          whoImgSrc={reyImg}
          speech={`They were funny! It's a pity they won't be more.`}
          className="mt-4"
        />
      </div>
    </div>
  ),
}

export default Level
