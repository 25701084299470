import CodeHighlight from 'components/games/CodeHighlight'
import React, { useState } from 'react'

type Props = {
  children: React.ReactNode
}

export default function ShowAnswer({ children }: Props) {
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsShown((bool) => !bool)}
        className="mb-4 w-max underline opacity-50"
      >
        Show Answer
      </button>
      {isShown && (
        <div>
          <CodeHighlight language="css">{children}</CodeHighlight>
        </div>
      )}
    </>
  )
}
