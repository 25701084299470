import React, { useEffect, useState, useCallback } from 'react'
import Image from 'next/image'
import { firebaseClient } from 'lib/firebaseClient'
import { useUser } from 'lib/hooks'
import GlobalLoading from 'components/GlobalLoading'
import cx from 'classnames'
import Button from 'components/Button'
import * as Sentry from '@sentry/browser'

import CheckSVG from 'public/images/svg/check.svg'
import { useLayoutEffect } from 'react'

import s from './Payment.module.css'

const Check = () => (
  <CheckSVG
    className="w-5 h-5 mr-2 relative top-1"
    style={{ fill: 'rgb(16, 185, 129)', minWidth: '1.25rem' }}
  />
)

const GAME_ATTACK_STRIPE_PRODUCT_ID_PROD = 'prod_Jy3MkMSfPlgcAF'
const GAME_ATTACK_STRIPE_PRODUCT_ID_DEV = 'prod_JxZ1O290G1KQaj'
const GAME_ATTACK_STRIPE_PRODUCT_ID_PROD_TEST = 'prod_Jy3Tp30fghJoch'

export let GAME_ATTACK_STRIPE_PRODUCT_ID =
  process.env.NODE_ENV === 'production'
    ? GAME_ATTACK_STRIPE_PRODUCT_ID_PROD
    : GAME_ATTACK_STRIPE_PRODUCT_ID_DEV

const paymentOptionsDev = [
  // {
  //   productId: 'prod_JxAVhVO2KucpPs',
  //   type: 'subscription',
  //   id: '3month',
  //   name: 'Quarterly',
  //   price: '$59',
  //   howLong: '3 month',
  //   priceId: 'price_1JJGAYEeqIqHTtJGmVCwKg0V',
  // },
  {
    productId: 'prod_JxAVOYuvCyUxcH',
    type: 'subscription',
    id: 'yearly',
    name: 'Annually',
    price: '$16',
    howLong: 'month',
    priceId: 'price_1JJGADEeqIqHTtJGKOeDxg9F',
  },
  {
    productId: GAME_ATTACK_STRIPE_PRODUCT_ID_DEV,
    type: 'one_time',
    id: 'gridattack',
    name: 'Lifetime',
    price: '$29',
    howLong: 'one time',
    priceId: 'price_1JJdtAEeqIqHTtJGb8gSm08k',
  },
]

const paymentOptionsProd = [
  // {
  //   productId: 'prod_Jy3KNU87IbUYmS',
  //   type: 'subscription',
  //   id: '3month',
  //   name: 'Quarterly',
  //   price: '$59',
  //   howLong: '3 month',
  //   priceId: 'price_1JK7DwEeqIqHTtJGuO4NcbhG',
  // },
  {
    productId: 'prod_Jy3JxC10tbeNNz',
    type: 'subscription',
    id: 'yearly',
    name: 'Annually',
    price: '$16',
    howLong: 'month',
    priceId: 'price_1JOU2ZEeqIqHTtJGRetWZXjU',
  },
  {
    productId: GAME_ATTACK_STRIPE_PRODUCT_ID_PROD,
    type: 'one_time',
    id: 'gridattack',
    name: 'Lifetime',
    price: '$29',
    howLong: 'one time',
    // priceId: 'price_1JK7FjEeqIqHTtJGxztQkj0s', // $49
    // priceId: 'price_1JPP2DEeqIqHTtJG3Bk0XEwQ', // $89
    priceId: 'price_1JQYs0EeqIqHTtJG167gTbCS', // $29
    // priceId: 'price_1JSKhOEeqIqHTtJGU9LudKZy', // $39
  },
]

const paymentOptionsProdTest = [
  // {
  //   productId: 'prod_Jy3QkZPKOpuHuo',
  //   type: 'subscription',
  //   id: '3month',
  //   name: 'Quarterly (t)',
  //   price: '$59',
  //   howLong: '3 month',
  //   priceId: 'price_1JK7JSEeqIqHTtJGX7kxb8O0',
  // },
  {
    productId: 'prod_Jy3QFAaugkDruG',
    type: 'subscription',
    id: 'yearly',
    name: 'Annually (t)',
    price: '$16',
    howLong: 'month',
    priceId: 'price_1JK7JrEeqIqHTtJG5fGIBmYt',
  },
  {
    productId: GAME_ATTACK_STRIPE_PRODUCT_ID_PROD_TEST,
    type: 'one_time',
    id: 'gridattack',
    name: 'Lifetime (t)',
    price: '$29',
    howLong: 'one time',
    priceId: 'price_1JK7MaEeqIqHTtJGNFtgtMTl',
  },
]

let paymentOptions = process.env.NODE_ENV === 'production' ? paymentOptionsProd : paymentOptionsDev

const GameImage = ({ src }) => (
  <div className="relative h-16 w-full">
    <Image src={src} layout="fill" quality={100} objectFit="contain" alt="" />
  </div>
)

export default function Payment({
  payOneTimeForGameId,
  redirectAfterSetupSuccessPageUrl,
  cancelPaymentUrl,
}: {
  payOneTimeForGameId?: string
  redirectAfterSetupSuccessPageUrl?: string
  cancelPaymentUrl?: string
}) {
  const { user, isLoaded: isUserLoaded } = useUser()

  const [paymentLoading, setPaymentLoading] = useState(false)
  const [secretPass, setSecretPass] = useState('')

  const [paymentOption, setPaymentOption] = useState('gridattack')

  useLayoutEffect(() => {
    // hack for IOS in modal
    setTimeout(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        try {
          document.getElementById('yearly')?.focus()
          document.getElementById('yearly')?.click()
        } catch (error) {}
      }
    }, 1000)
  }, [])

  const pay = async () => {
    const { priceId, type } = paymentOptions.find(({ id }) => id === paymentOption)!

    setPaymentLoading(true)

    const checkoutSession = {
      line_items: [{ price: priceId, quantity: 1 }],
      success_url: `${window.location.origin}/setup-success${
        redirectAfterSetupSuccessPageUrl
          ? `?redirectAfterTo=${redirectAfterSetupSuccessPageUrl}`
          : ''
      }`,
      cancel_url: cancelPaymentUrl
        ? `${window.location.origin}${cancelPaymentUrl}`
        : `${window.location.href}`,
      billing_address_collection: 'auto',
    }

    if (type === 'one_time') {
      checkoutSession.mode = 'payment'
      checkoutSession.payment_method_types = ['card']
    }

    const docRef = await firebaseClient
      .firestore()
      .collection('customers')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add(checkoutSession)
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data()

      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`)
        Sentry.captureException(error)
        setPaymentLoading(false)
      }

      if (url) {
        window.location.assign(url)
      }
    })
  }

  useEffect(() => {
    function listenToBackButton(event) {
      setSecretPass((pass) => pass + event.key)
    }

    document.addEventListener('keydown', listenToBackButton)

    return () => {
      document.removeEventListener('keydown', listenToBackButton)
    }
  }, [])

  useEffect(() => {
    if (secretPass === 'testing') {
      paymentOptions = paymentOptionsProdTest
      GAME_ATTACK_STRIPE_PRODUCT_ID = GAME_ATTACK_STRIPE_PRODUCT_ID_PROD_TEST
    }
  }, [secretPass])

  const onPaymentSubmit = (event) => {
    event.preventDefault()

    pay()
  }

  const PaymentOption = ({ id, name, price, howLong, className }) => (
    <button
      id={id}
      type="button"
      onClick={() => setPaymentOption(id)}
      style={{ maxWidth: '250px' }}
      className={cx(
        `flex items-center justify-between rounded-lg border-2 p-2 w-full mx-1 whitespace-nowrap focus:outline-none relative text-base`,
        className,
        paymentOption === id ? 'border-yellow-500' : 'border-gray-400 text-gray-400'
      )}
    >
      {id === 'yearly' && (
        <div
          className="absolute -top-4 bg-green-500 px-2 py-1 rounded-full text-xs text-black"
          style={{ transform: 'translateX(-50%)', left: '50%' }}
        >
          {/* SAVE $396 */}
          SAVE $156
        </div>
      )}
      <div className="flex items-center">
        <div
          className={cx(
            'flex items-center justify-center rounded-full mr-2',
            paymentOption === id ? 'bg-yellow-500 w-5 h-5' : 'border-2 border-gray-400 w-5 h-5'
          )}
        >
          <div
            className={cx(
              'rounded-full',
              paymentOption === id ? 'w-2 h-2 bg-white' : 'w-4 h-4 bg-transparent'
            )}
          ></div>
        </div>
        <p className={paymentOption !== id ? 'text-gray-400' : ''}>{name}</p>
      </div>
      <div className={paymentOption !== id ? 'text-gray-400' : ''}>
        {price}/{howLong}
      </div>
    </button>
  )

  return (
    <>
      <GlobalLoading loading={!isUserLoaded || paymentLoading} />
      {/* <div className="relative md:h-36 h-28">
        <Image
          src="/gamesAssets/flexbox/knight-top.png"
          layout="fill"
          quality={100}
          objectFit="contain"
          alt=""
        />
      </div> */}
      <h2 className="text-center lg:text-4xl text-2xl font-medium sm:mb-10 mb-6 mt-6">
        Continue with Grid Attack...
      </h2>
      <form className="flex flex-col items-center lg:text-lg text-base" onSubmit={onPaymentSubmit}>
        <div className="flex sm:flex-row flex-col justify-center w-full lg:mb-6 mb-2">
          {payOneTimeForGameId && (
            // <div className="flex flex-col justify-center sm:items-start items-center sm:pb-0 pb-8 sm:mb-0 mb-8 sm:border-r border-r-0 border-b sm:border-b-0 border-gray-300 px-4 flex-1">
            <div className="flex flex-col justify-center sm:items-start items-center px-4">
              <div className="flex flex-col">
                <div className="flex items-center justify-center w-full mb-4">
                  <div className="relative h-48 w-full">
                    <Image
                      src="/gamesAssets/gridattack/fun-to-play.png"
                      layout="fill"
                      quality={100}
                      objectFit="contain"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex flex-col mx-auto w-full max-w-sm sm:mb-0 mb-2">
                  <div className="flex items-start mb-2">
                    <Check />
                    <p>
                      <span className="font-semibold">Get lifetime access to Grid Attack game</span>{' '}
                      and completely learn CSS Grid in every detail once and for all
                    </p>
                  </div>
                  <div className="flex items-start mb-2">
                    <Check />
                    <p>
                      <span className="font-semibold">Unlock 80 interactive lessons</span> to help
                      you master css grid
                    </p>
                  </div>
                  <div className="flex items-start">
                    <Check />
                    <p>
                      <span className="font-semibold">
                        Learn how to create 38 real-project layouts
                      </span>{' '}
                      using a grid and start making web pages much faster
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="w-full max-w-lg flex flex-col justify-center items-center mx-auto relative">
                <div className="flex items-center justify-center sm:justify-start w-full">
                  {paymentOptions
                    .filter(({ id }) => id === payOneTimeForGameId)
                    .map((paymentOption, index) => (
                      <PaymentOption {...paymentOption} key={index} />
                    ))}
                </div>
                <p className="absolute left-1/2 -bottom-6 transform -translate-x-1/2 text-gray-400 text-sm whitespace-nowrap">
                  Pay once, own it forever
                </p>
              </div> */}
            </div>
          )}
          {/* <div className="px-4" style={{ flex: 2 }}>
            <div
              className={cx(
                'flex flex-col w-full max-w-md mb-6',
                // !payOneTimeForGameId && 'mx-auto'
                'mx-auto'
              )}
            >
              <div>
                <div className={cx(s.futureGames, 'grid gap-x-1 gap-y-2 justify-center mb-4')}>
                  <GameImage src="/images/gridattack-icon.png" />
                  <GameImage src="/images/flexbox-icon.png" />
                  <GameImage src="/images/regex-icon.png" />
                  <GameImage src="/images/css-icon.png" />
                  <GameImage src="/images/html-icon.png" />
                  <GameImage src="/images/js-icon.png" />
                  <GameImage src="/images/git-icon.png" />
                  <GameImage src="/images/sql-icon.png" />
                  <GameImage src="/images/python-icon.png" />
                  <GameImage src="/images/bash-icon.png" />
                  <GameImage src="/images/tailwind-icon.png" />
                  <GameImage src="/images/emmet-icon.png" />
                </div>
                <div className="flex items-start mb-2">
                  <Check />
                  <p>
                    <span className="font-medium">
                      Get access to Grid Attack, Elon Regex, Flex Box Adventure and one new coding
                      game every 2 months
                    </span>{' '}
                    <span className="italic">(12+ games by the end of 2022)</span>
                  </p>
                </div>
              </div>
              <div className="flex items-start  mb-2">
                <Check />
                <p>
                  <span className="font-medium">
                    Learn CSS Grid, Flex Box, Regular Expressions, CSS, HTML, JS, Git, SQL, Python,
                    Bash, Tailwind, Emmet
                  </span>{' '}
                  and dozen of other technologies
                </p>
              </div>
              <div className="flex items-start mb-2 relative">
                <Check />
                <div>
                  <p className="mr-2 inline">
                    <span className="font-medium">Get access to daily coding challenges</span> and
                    practice your coding skills <span className="italic">(coming soon)</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full max-w-lg flex flex-col justify-center items-center mx-auto relative">
              <div className="flex flex-col-reverse sm:flex-row justify-center items-center w-full">
                {paymentOptions
                  .filter(({ type }) => type === 'subscription')
                  .map((paymentOption, index) => (
                    <PaymentOption {...paymentOption} key={index} />
                  ))}
              </div>
              <p className="absolute left-1/2 -bottom-6 transform -translate-x-1/2 text-gray-400 text-sm">
                Billed annually
              </p>
            </div>
          </div> */}
        </div>
        <div className="flex flex-col items-center justify-center text-center py-4">
          {/* <span className="line-through opacity-75 inline-flex items-center font-display text-lg md:text-xl font-semibold text-grey-dark mr-2 sm:mr-3">
            <span className="text-xl">$</span>
            <span>89</span>
          </span> */}
          <span className="inline-flex items-center font-display text-4xl md:text-5xl font-bold text-black mr-2 sm:mr-3">
            <span className="text-xl md:text-2xl mb-2">
              One-time payment: <span className="line-through">$89</span> $29
            </span>
            {/* <span>29</span> */}
          </span>
          <span className="inline-flex justify-between flex-col mr-2 sm:mr-3">
            {/* <span className="inline-flex text-left font-display text-lg md:text-xl font-bold text-grey-dark">
              USD
            </span> */}
            <span className="-mt-0.5 font-sans text-xs md:text-sm text-gray-500 font-medium leading-none text-left">
              UNTIL SEPTEMBER 15, THEN $129
            </span>
          </span>
        </div>
        <Button
          className="max-w-xs col-span-2 justify-self-center mb-4"
          type="submit"
          btnColor="bg-yellow-600"
          btnHoverColor="bg-yellow-700"
          disabled={paymentLoading}
        >
          Buy Now & Continue Playing →
        </Button>
        <p className="italic text-gray-700 text-center text-sm sm:mt-0 max-w-sm">
          11,387+ people are playing
        </p>
      </form>
    </>
  )
}
