import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import axios from 'axios'
import useSWR, { mutate } from 'swr'
import cx from 'classnames'

import { GRID_ATTACK_GAME_LEVEL, GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'

import { firebaseClient } from 'lib/firebaseClient'

import LevelDropdown from 'games/gridattack/components/LevelDropdown'
import { GameContext } from 'games/gridattack/Game'
import Highlight from 'components/games/CodeHighlight'
import GameField from 'games/gridattack/components/GameField'
import s from 'games/gridattack/game.module.css'
import ShowAnswer from 'games/gridattack/components/ShowAnswer'
import EvilLand from 'games/gridattack/components/EvilLand'
import HumanLand from 'games/gridattack/components/HumanLand'
import EvilLandAnswer from 'games/gridattack/components/EvilLandAnswer'
import HumanLandAnswer from 'games/gridattack/components/HumanLandAnswer'
import LandCharacters from 'games/gridattack/components/LandCharacters'
import Stats from 'games/gridattack/components/Stats/Stats'
import Property from 'games/gridattack/components/PropertyCSS'
import Editor from 'games/gridattack/components/Editor'
import { User } from 'components/AuthProvider'
import { checkUserAnswer, GridChildExample, isUserAnswerCorrect, MONSTERS_COUNT } from './utils'
import { GRID_ATTACK_GAME } from '../game-config'
import Talk from '../components/Talk/Talk'

const reyGif = '/gamesAssets/gridattack/rey-50.gif'
const evilGuyGif = '/gamesAssets/gridattack/azazel.gif'

const greenLandImg = '/gamesAssets/gridattack/green-land.svg'
const redLandImg = '/gamesAssets/gridattack/red-land.svg'

const reyImg = '/gamesAssets/gridattack/rey.png'
const azazelImg = '/gamesAssets/gridattack/m-azazel.png'
const lukeImg = '/gamesAssets/gridattack/luke.png'
const valcorianImg = '/gamesAssets/gridattack/m-valcorian.png'
const reyGifPath = [{ path: reyGif }]
const evilGuyPath = [{ path: evilGuyGif }]

const levelAnswerStyleConstructor = ({
  withEmptyLineAtTheEnd = true,
  selectors,
  styles,
}: {
  withEmptyLineAtTheEnd?: boolean
  selectors: string[][]
  styles: string[][]
}) => `${selectors[0].join(',')} {
  display: grid;
  ${styles[0].join(`
  `)}${
  withEmptyLineAtTheEnd
    ? `
  `
    : ''
}
}`

const GAME_FIELD_STYLE = levelAnswerStyleConstructor({
  selectors: [['#gameFieldBack', '#gameFieldBackCharacters']],
  styles: [
    [
      `grid-template-columns: 1fr 100px auto;`,
      `grid-template-rows: 1fr 1fr 100px;`,
      `gap: 10% 20px;`,
    ],
  ],
})

const ANSWER = levelAnswerStyleConstructor({
  withEmptyLineAtTheEnd: false,
  selectors: [['#field']],
  styles: [
    [
      `grid-template-columns: 1fr 100px auto;`,
      `grid-template-rows: 1fr 1fr 100px;`,
      `gap: 10% 20px;`,
    ],
  ],
})

const Text = () => <p className="text-base sm:text-xl p-4 text-purple-400">"Awakozelo!"</p>

const HTML = `<div id="field">
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="greenLand">
    <p>"Awakozelo!"</p>
  </div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
  <div class="redLand"></div>
  <div class="greenLand"></div>
  <div class="redLand"></div>
</div>`

const Level: GRID_ATTACK_GAME_LEVEL = {
  id: 18,
  level: 18,
  editor: {
    nextGenCode: levelAnswerStyleConstructor({
      selectors: [['#field']],
      styles: [[`/* type here */`]],
    }),
  },
  checkUserAnswer: () => {
    return isUserAnswerCorrect({
      answer: document.getElementById('gameFieldBack').children,
      userAnswer: document.getElementById('field').children,
    })
  },
  GameLayout: () => {
    const { checkAnswer, answer, handleEditorCodeChange, mode } = useContext(GameContext)

    useLayoutEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    const { Description } = Level

    return (
      <div className={s.gameLayout} id="gridattackGame">
        <div className={s.gameLayoutInner}>
          {/* DESCRIPTION */}
          <div className={cx(s.leftSide)}>
            <div className="flex flex-col">
              <Description mode={mode} />
            </div>
          </div>

          <div className={s.rightSide}>
            {/* FIELD */}
            <GameField
              inspector={{
                withLines: false,
                columns: ['1fr', '100px', 'auto'],
                rows: ['1fr', '1fr', '100px'],
                columnGap: '20px',
                rowGap: '10%',
              }}
              gameFieldStyle={answer}
              gameFieldBackStyle2={GAME_FIELD_STYLE}
              gameField={() => (
                <>
                  <EvilLandAnswer />
                  <EvilLandAnswer />
                  <HumanLandAnswer>
                    <Text />
                  </HumanLandAnswer>
                  <EvilLandAnswer />
                  <HumanLandAnswer />
                  <EvilLandAnswer />
                  <EvilLandAnswer />
                  <HumanLandAnswer />
                  <EvilLandAnswer />
                </>
              )}
              gameFieldBack={() => (
                <>
                  <EvilLand />
                  <EvilLand />
                  <HumanLand>
                    <Text />
                  </HumanLand>
                  <HumanLand />
                  <HumanLand />
                  <EvilLand />
                  <HumanLand />
                  <HumanLand />
                  <EvilLand />
                </>
              )}
              gameFieldBackCharacters={() => (
                <>
                  <div></div>
                  <div></div>
                  <Text />
                  <div></div>
                  <div></div>
                  <LandCharacters charactersImgPaths={evilGuyPath} />
                  <div></div>
                  <LandCharacters charactersImgPaths={reyGifPath} />
                  <div></div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    )
  },
  Description: React.memo(({ mode }) => {
    return (
      <div>
        <LevelDropdown levels={GRID_ATTACK_GAME.levels} />
        <Stats level={Level.level} endLevel={GRID_ATTACK_GAME.levels.length} />
        <Talk className="mb-2" whoImgSrc={azazelImg} speech="So, you are Rey." />
        <Talk className="mb-2" whoImgSrc={reyImg} speech="You must be a dead man." />
        <Talk
          className="mb-2"
          whoImgSrc={azazelImg}
          speech="Ha ha, I like you but I’ll like you more when you’re dead."
        />
        <Talk
          className="mb-2"
          whoImgSrc={reyImg}
          speech="I like your eye, I think I’ll make it my trophy"
        />
        <Talk className="mb-2" whoImgSrc={azazelImg} speech="One more word and you’re dead!" />
        <Talk className="mb-2" whoImgSrc={reyImg} speech="Okay...cyclops!" />
        <Talk className="mb-6" whoImgSrc={azazelImg} speech="Arrrgh!" />
        {mode === 'hard' && (
          <div className={s.regularText}>
            There he is, Azazel. It took us a long time to get there, fighting our way through a
            tons of monsters. Now, it's time to use your knowledge about CSS Grid and help Rey cast
            the spell "Awakozelo!" to defeat Azazel!
          </div>
        )}
        {(mode === 'easy' || mode === 'medium') && (
          <>
            <div className={s.regularText}>
              There he is, Azazel. It took us a long time to get there, fighting our way through a
              tons of monsters. Now, it's time to use your knowledge about{' '}
              <Property property="grid-template-rows" />,{' '}
              <Property property="grid-template-columns" /> and <Property property="gap" /> and help
              Rey cast the spell <p className="text-purple-500 inline">"Awakozelo!"</p> to defeat
              Azazel!
            </div>
          </>
        )}
        {mode === 'easy' && <ShowAnswer>{ANSWER}</ShowAnswer>}
        {/* EDITOR */}
        <div className={s.editorWrapper}>
          <Editor gameColor={GRID_ATTACK_GAME.color} level={Level} html={HTML} />
        </div>
      </div>
    )
  }),
  CompleteLevelModalDescription: () => (
    <div className="flex items-center justify-center text-base text-gray-500">
      <div className="flex items-center">
        <Talk whoImgSrc={reyImg} speech={`This eye is pretty cool!`} className="mt-4" />
      </div>
    </div>
  ),
}
export default Level
