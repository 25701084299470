import React, { useContext, useRef, useState } from 'react'

import cx from 'classnames'
import Highlight from 'components/games/CodeHighlight'

import s from './Editor.module.css'
import Button from 'components/Button'
import { GameContext } from 'games/gridattack/Game'

const CODE_LINE_HEIGHT = 24

function Editor({ gameColor, level, html = 'qwee' }) {
  const { checkAnswer, answer, handleEditorCodeChange } = useContext(GameContext)

  const {
    editor: { nextGenCode },
  } = level

  const inputareaRef = useRef<HTMLTextAreaElement>(null)
  const highlightRef = useRef<HTMLElement>(null)
  const [activeTab, setActiveTab] = useState(0)

  if (answer === null || answer === undefined || !nextGenCode) return null

  const match = answer.match(/\r?\n/gm)?.length

  const lines = match ? match + 1 : 1
  const linesArray = new Array(lines).fill(1)

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        <button
          className={cx(s.tab, activeTab === 0 && s.tabActive)}
          onClick={() => {
            setActiveTab(0)
          }}
        >
          CSS
        </button>
        <button
          className={cx(s.tab, activeTab === 1 && s.tabActive)}
          onClick={() => {
            setActiveTab(1)
          }}
        >
          HTML
        </button>
      </div>
      <div className={cx(s.code, 'relative')}>
        <div className={cx('flex items-start', s.codeWrapper)}>
          <div className={s.codeLine}>
            {linesArray.map((_, index) => (
              <div className={s.codeLineNumber} key={index}>
                {index + 1}
              </div>
            ))}
          </div>
          <div className="flex flex-col mt-3 w-full relative overflow-hidden">
            {activeTab === 0 && (
              <textarea
                ref={inputareaRef}
                className={s.codeTextarea}
                autoCapitalize="none"
                autoComplete="off"
                spellCheck="false"
                value={answer}
                autoCorrect="off"
                data-gramm_editor="false"
                data-gramm="false"
                onChange={(e) => {
                  handleEditorCodeChange(e.target.value)
                }}
                onKeyPress={(event) => {
                  const key = event.key || event.keyCode

                  if (key === 13 || (key === 'Enter' && event.shiftKey)) {
                    event.preventDefault()
                    checkAnswer()
                  }
                }}
                onKeyDown={(event) => {
                  const key = event.key || event.keyCode

                  if (key === 9 || key === 'Tab') {
                    event.preventDefault()

                    let newCaretPosition

                    const textarea: HTMLTextAreaElement = inputareaRef.current!

                    if (textarea) {
                      newCaretPosition = textarea.selectionStart + '  '.length
                      textarea.value =
                        textarea.value.substring(0, textarea.selectionStart) +
                        '  ' +
                        textarea.value.substring(textarea.selectionStart, textarea.value.length)

                      handleEditorCodeChange(textarea.value)

                      textarea.selectionStart = newCaretPosition
                      textarea.selectionEnd = newCaretPosition
                      textarea.focus()
                    }
                  }

                  if (key === 'Enter') {
                    event.preventDefault()

                    let newCaretPosition

                    const textarea: HTMLTextAreaElement = inputareaRef.current!

                    if (textarea) {
                      newCaretPosition = textarea.selectionStart + '\n  '.length

                      textarea.value =
                        textarea.value.substring(0, textarea.selectionStart) +
                        '\n  ' +
                        textarea.value.substring(textarea.selectionStart, textarea.value.length)

                      handleEditorCodeChange(textarea.value)

                      textarea.selectionStart = newCaretPosition
                      textarea.selectionEnd = newCaretPosition
                      textarea.focus()
                    }
                  }
                }}
                onScroll={() => {
                  const leftPx = inputareaRef?.current?.scrollLeft

                  if (highlightRef.current) {
                    highlightRef.current.scrollLeft = leftPx
                  }
                }}
                style={{
                  height: `${CODE_LINE_HEIGHT * lines}px`,
                }}
              ></textarea>
            )}
            <Highlight
              style={{ height: `${CODE_LINE_HEIGHT * lines}px` }}
              language={activeTab === 0 ? 'css' : 'html'}
              usingForGameEditor
              className={s.codeHighlighter}
              codeRef={highlightRef}
            >
              {activeTab === 0 ? answer : html}
            </Highlight>
          </div>
        </div>
        <div className="flex justify-center items-center mt-4">
          <Button
            btnColor={`bg-${gameColor}-600`}
            btnHoverColor={`bg-${gameColor}-500`}
            onClick={checkAnswer}
          >
            Check Answer
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Editor
